import {
  getSessionItem,
  setSessionItem,
} from '@app-lib/storage';
import {Theme} from '@app-system/preferences/enums/Theme';
import {
  dark,
  light,
} from '@app-system/themes';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider} from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import {AbstractAppService} from '../../AbstractAppService';
import {FeatureWarning} from '../components/FeatureWarning';


const KEY = 'FeatureDetectionWarning';

export class FeatureDetectionService extends AbstractAppService {
  getShownWarning(): boolean {
    return getSessionItem(KEY, false);
  }

  setShownWarning(val: boolean) {
    setSessionItem(KEY, val);
  }

  showFeatureWarning() {
    this.renderWarning((cleanup) => (
      <FeatureWarning
        onClick={cleanup}
      />
    ));
  }

  renderWarning(children: (cleanup: () => void) => React.ReactNode) {
    const element = document.createElement('div');
    document.body.appendChild(element);

    const cleanup = () => {
      this.setShownWarning(true);
      ReactDOM.unmountComponentAtNode(element);
      document.body.removeChild(element);
    };

    const theme = this.services.preferencesService.getTheme();

    ReactDOM.render((
      <MuiThemeProvider theme={theme === Theme.Light ? light : dark}>
        <CssBaseline />
        {children(cleanup)}
      </MuiThemeProvider>
    ), element);
  }

  initOnClient() {
    if (this.getShownWarning()) {
      return;
    } else if (!this.services.fileUploadQueueService.isSupported()) {
      this.showFeatureWarning();
    }
  }
}
