import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';


export interface BlockFileNodeMemberResponse {
  code: string
  success: boolean
  message: string
  memberId: string
  fileNodeId?: string
  actorId?: string
}

export interface FileNodeBlockMemberDocumentNode {
  fileNodeBlockMember?: ClientMutationResponse
}

export interface FileNodeBlockMemberBatchDocumentNode {
  fileNodeBlockMemberBatch?: ClientMutationResponse & {
    responses?: Array<BlockFileNodeMemberResponse>
  }
}

export const fileNodeBlockMemberMutation = gql`
  mutation fileNodeBlockMember($input: FileNodeBlockMemberInput!) {
    fileNodeBlockMember(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const fileNodeBlockMemberBatchMutation = gql`
  mutation fileNodeBlockMemberBatch($input: FileNodeBlockMemberBatchInput!) {
    fileNodeBlockMemberBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        memberId
        fileNodeId
        actorId
      }
    }
  }
`;

export function getFileNodeBlockMemberResult(
  response: { data?: FileNodeBlockMemberDocumentNode | null },
): FileNodeBlockMemberDocumentNode["fileNodeBlockMember"] {
  return response?.data?.fileNodeBlockMember;
}

export function getFileNodeBlockMemberBatchResult(
  response: { data?: FileNodeBlockMemberBatchDocumentNode | null },
): FileNodeBlockMemberBatchDocumentNode["fileNodeBlockMemberBatch"] {
  return response?.data?.fileNodeBlockMemberBatch;
}
