import {gql} from '@apollo/client';
import {JobFragmentData} from '@app-features/jobs/types';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {JobBatchUpdateResponse} from '../utils';


export type JobStatusUpdate = Pick<JobFragmentData,
  | "__typename"
  | "id"
  | "status">

export interface UpdateJobStatusBatchDocumentNode {
  updateJobStatusBatch?: ClientMutationResponse & {
    responses?: Array<JobBatchUpdateResponse>
    jobs?: Array<JobStatusUpdate>
  }
}

export const updateJobStatusBatchMutation = gql`
  mutation updateJobStatusBatch($input: UpdateJobStatusBatchInput!) {
    updateJobStatusBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        jobId
      }
      jobs {
        __typename
        id
        status
      }
    }
  }
`;

export function getUpdateJobStatusBatchResult(
  response: { data?: UpdateJobStatusBatchDocumentNode | null },
): UpdateJobStatusBatchDocumentNode["updateJobStatusBatch"] {
  return get(response, 'data.updateJobStatusBatch', null);
}
