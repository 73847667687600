import {TemplateRepositoryFragmentData} from '@app-features/template-repositories/types';
import {createAction} from 'redux-starter-kit';


export interface UpdateTemplateRepositoryPayload {
  model: TemplateRepositoryFragmentData
}

export type UpdateTemplateActions =
  | ReturnType<typeof updateTemplateRepository>

export const updateTemplateRepository = createAction<UpdateTemplateRepositoryPayload, "templates/updateTemplateRepository">("templates/updateTemplateRepository");
