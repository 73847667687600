/* eslint-disable max-len */
import {
  FileContainer,
  Folder,
} from '@app-lib/apollo/apiTypes';
import {createAction} from 'redux-starter-kit';


export type FileTreeFormActions =
  | ReturnType<typeof createdFolder>
  | ReturnType<typeof createdFileContainer>

export const createdFolder = createAction<Pick<Folder, "__typename" | "id" | "name" | "parentId">, "files/createdFolder">("files/createdFolder");
export const createdFileContainer = createAction<Pick<FileContainer, "__typename" | "id" | "name" | "parentId">, "files/createdFileContainer">("files/createdFileContainer");
