import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {MilestoneBatchUpdateResponse} from '../utils';


export interface DeleteMilestoneDocumentNode {
  deleteMilestone?: ClientMutationResponse
}

export interface DeleteMilestoneBatchDocumentNode {
  deleteMilestoneBatch?: ClientMutationResponse & {
    responses?: Array<MilestoneBatchUpdateResponse>
  }
}

export const deleteMilestoneMutation = gql`
  mutation deleteMilestone($input: DeleteMilestoneInput!) {
    deleteMilestone(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const deleteMilestoneBatchMutation = gql`
  mutation deleteMilestoneBatch($input: DeleteMilestoneBatchInput!) {
    deleteMilestoneBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        milestoneId
      }
    }
  }
`;

export function getDeleteMilestoneResult(
  response: { data?: DeleteMilestoneDocumentNode | null },
): DeleteMilestoneDocumentNode["deleteMilestone"] {
  return get(response, 'data.deleteMilestone', null);
}

export function getDeleteMilestoneBatchResult(
  response: { data?: DeleteMilestoneBatchDocumentNode | null },
): DeleteMilestoneBatchDocumentNode["deleteMilestoneBatch"] {
  return get(response, 'data.deleteMilestoneBatch', null);
}
