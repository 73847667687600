import {
  BatchFailureData,
  OrgMemberData,
  UserData,
} from '@app-lib/apollo/localTypes';
import {buildErrorNotification} from '@app-system/notifications/components/ErrorNotification';
import {Group as GroupIcon} from '@material-ui/icons';
import {find} from 'lodash';


export interface UserBatchUpdateResponse {
  code: string
  success: boolean
  message: string
  userId: string
}

export function getOrgMemberBatchFailures(
  models: Array<OrgMemberData>,
  responses: Array<UserBatchUpdateResponse> | null | undefined,
): BatchFailureData<OrgMemberData> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.userId)
    ?.forEach(response => successes.add(response.userId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.user.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getUserBatchFailures(
  models: Array<UserData>,
  responses: Array<UserBatchUpdateResponse> | null | undefined,
): BatchFailureData<UserData> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.userId)
    ?.forEach(response => successes.add(response.userId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getOrgMemberErrorConfig(
  models: Array<OrgMemberData>,
  action:
    | "update"
    | "remove",
  message?: string | null,
) {
  const remain = models.length - 1;
  return {
    message: `Failed to ${action} User${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${models[0].id}`,
      persist: true,
      content: buildErrorNotification({
        icon: GroupIcon,
        error: message || `Something went wrong.`,
        name: models[0].user.name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}

export function getUserErrorConfig(
  models: Array<UserData>,
  action:
    | "update"
    | "remove",
  message?: string | null,
) {
  const remain = models.length - 1;
  return {
    message: `Failed to ${action} User${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${models[0].id}`,
      persist: true,
      content: buildErrorNotification({
        icon: GroupIcon,
        error: message || `Something went wrong.`,
        name: models[0].name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}
