import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  openPage,
  RouterActions,
} from './actions';


export const routerEpics = [
  onOpenPage,
];

export function onOpenPage(
  action$: Observable<RouterActions>,
  state$,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === openPage.type),
    mergeMap(async ({payload}: ReturnType<typeof openPage>) => {
      try {
        if (payload.newTab) {
          window.open(payload.url, '_blank');
          return;
        }
        if (dependencies.router) {
          await dependencies.router.push(payload.url, payload.as, payload.options);
          return;
        }
      } catch (e) {
        return enqueueNotification({
          message: 'Something went wrong opening page.',
          options: {
            key: openPage.type,
            variant: 'error',
            preventDuplicate: true,
          },
        });
      }
    }),
    filter(filterOnAction),
  );
}
