import {TypePolicy} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';


export const MilestonePolicy: TypePolicy = {
  fields: {
    files: relayStylePagination([
      'searchText',
      'filter',
    ]),
  },
};
