import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {FileNodeBatchUpdateResponse} from '../utils';


export interface DeleteFileNodeDocumentNode {
  deleteFileNode?: ClientMutationResponse;
}

export interface DeleteFileNodeBatchDocumentNode {
  deleteFileNodeBatch?: ClientMutationResponse & {
    responses?: Array<FileNodeBatchUpdateResponse>;
  }
}

export const deleteFileNodeMutation = gql`
  mutation deleteFileNode($input: DeleteFileNodeInput!) {
    deleteFileNode(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const deleteFileNodeBatchMutation = gql`
  mutation deleteFileNodeBatch($input: DeleteFileNodeBatchInput!) {
    deleteFileNodeBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        fileNodeId
      }
    }
  }
`;

export function getDeleteFileNodeResult(
  response: { data?: DeleteFileNodeDocumentNode | null },
): DeleteFileNodeDocumentNode["deleteFileNode"] {
  return response?.data?.deleteFileNode;
}

export function getDeleteFileNodeBatchResult(
  response: { data?: DeleteFileNodeBatchDocumentNode | null },
): DeleteFileNodeBatchDocumentNode["deleteFileNodeBatch"] {
  return response?.data?.deleteFileNodeBatch;
}
