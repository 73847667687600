import {ApolloClient} from '@apollo/client';
import {
  DateFormatInput,
  UpdateMilestoneStatusBatchInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {getDateFormatOptions} from '@app-system/preferences/redux/state';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  getActionNameForMilestoneStatus,
  getMilestoneBatchFailures,
  getMilestoneErrorConfig,
} from '../utils';
import {
  MilestoneStatusUpdateActions,
  updateMilestoneStatusBatch,
  UpdateMilestoneStatusBatchPayload,
} from './actions';
import {
  getUpdateMilestoneStatusBatchResult,
  UpdateMilestoneStatusBatchDocumentNode,
  updateMilestoneStatusBatchMutation,
} from './graphql';


export const epics = [
  onUpdateMilestoneStatusBatch,
];

export function onUpdateMilestoneStatusBatch(
  action$: Observable<MilestoneStatusUpdateActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === updateMilestoneStatusBatch.type),
    withLatestFrom(state$),
    mergeMap(async ([{payload}, rootState]: [ReturnType<typeof updateMilestoneStatusBatch>, RootAppState]) => {
      try {
        const prefs = getDateFormatOptions(rootState);
        const dateFormat = {
          formatString: prefs.dateTimeFormat,
          timezone: prefs.timezone,
        };
        return await performUpdateMilestoneStatusBatch(dependencies.apolloClient, payload, dateFormat);
      } catch (e) {
        const action = getActionNameForMilestoneStatus(payload.status);
        return enqueueNotification(getMilestoneErrorConfig(payload.models, action, DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performUpdateMilestoneStatusBatch(
  apolloClient: ApolloClient<any>,
  payload: UpdateMilestoneStatusBatchPayload,
  dateFormat: DateFormatInput,
) {
  const result = await apolloClient
    .mutate<UpdateMilestoneStatusBatchDocumentNode, { input: UpdateMilestoneStatusBatchInput, dateFormat: DateFormatInput }>({
      mutation: updateMilestoneStatusBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
            status: payload.status,
            skipApproved: payload.skipApproved,
            updateFileLocks: payload.updateFileLocks,
          })),
        },
        dateFormat,
      },
    });

  const {responses} = getUpdateMilestoneStatusBatchResult(result) || {};

  const failureData = getMilestoneBatchFailures(payload.models, responses);

  if (failureData) {
    const action = getActionNameForMilestoneStatus(payload.status);
    return enqueueNotification(getMilestoneErrorConfig(failureData.models, action, failureData.message));
  }

  return null;
}
