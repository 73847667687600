// This file is for things that are annoying to deal with using strong types.

export function addLegacyRequestHandler(req, resolve, reject) {
  // based on RxJS AjaxObservable resolution/error handling
  req.addEventListener('load', e => {
    const _this = e.currentTarget;

    if (_this && _this.readyState === 4) {
      // normalize IE9 bug (http://bugs.jquery.com/ticket/1450)
      let status = _this.status === 1223 ? 204 : _this.status;
      const response = (_this.responseType === 'text' ? (
        _this.response || _this.responseText) : _this.response);

      // fix status code when it is 0 (0 status is undocumented).
      // Occurs when accessing file resources or on Android 4.1 stock browser
      // while retrieving files from application cache.
      if (status === 0) {
        status = response ? 200 : 0;
      }

      // 4xx and 5xx should error (https://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html)
      if (status < 400) {
        resolve();
      } else {
        reject(e);
      }
    }
  });
}
