import {JobFragmentData} from '@app-features/jobs/types';
import {createAction} from 'redux-starter-kit';


export interface ArchiveJobPayload {
  model: Pick<JobFragmentData, "__typename" | "id" | "name">
}
export interface UnarchiveJobPayload {
  model: Pick<JobFragmentData, "__typename" | "id" | "name">
}
export interface ArchiveJobBatchPayload {
  models: Array<Pick<JobFragmentData, "__typename" | "id" | "name">>
}
export interface UnarchiveJobBatchPayload {
  models: Array<Pick<JobFragmentData, "__typename" | "id" | "name">>
}

export type JobArchivalActions =
  | ReturnType<typeof archiveJob>
  | ReturnType<typeof unarchiveJob>
  | ReturnType<typeof archiveJobBatch>
  | ReturnType<typeof unarchiveJobBatch>

export const archiveJob = createAction<ArchiveJobPayload, "jobs/archiveJob">("jobs/archiveJob");
export const unarchiveJob = createAction<UnarchiveJobPayload, "jobs/unarchiveJob">("jobs/unarchiveJob");
export const archiveJobBatch = createAction<ArchiveJobBatchPayload, "jobs/archiveJobBatch">("jobs/archiveJobBatch");
export const unarchiveJobBatch = createAction<UnarchiveJobBatchPayload, "jobs/unarchiveJobBatch">("jobs/unarchiveJobBatch");
