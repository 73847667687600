import {ApolloClient} from '@apollo/client';
import {
  MutationAccessedFileArgs,
  MutationAccessedFolderArgs,
  MutationAccessedJobArgs,
  MutationAccessedProjectArgs,
  MutationAccessedTenantArgs,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {Observable} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  accessedFile,
  accessedFolder,
  accessedJob,
  accessedProject,
  accessedTenant,
  hasAccessedTenant,
  hasAccessedProject,
  hasAccessedJob,
  hasAccessedFolder,
  hasAccessedFile,
  RecentAccessActions,
  RecentlyAccessedPayload,
} from './actions';
import {
  accessedFileMutation,
  AccessedFileMutationDocumentNode,
  accessedFolderMutation,
  AccessedFolderMutationDocumentNode,
  accessedJobMutation,
  AccessedJobMutationDocumentNode,
  accessedProjectMutation,
  AccessedProjectMutationDocumentNode,
  accessedTenantMutation,
  AccessedTenantMutationDocumentNode,
} from './graphql';


export const epics = [
  onAccessedTenant,
  onAccessedProject,
  onAccessedJob,
  onAccessedFolder,
  onAccessedFile,
];

export function onAccessedTenant(
  action$: Observable<RecentAccessActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === accessedTenant.type),
    distinctUntilChanged(idsAreEqual),
    mergeMap(async ({payload}: ReturnType<typeof accessedTenant>) => {
      await swallowError(() => performAccessedTenant(dependencies.apolloClient, payload));
      return hasAccessedTenant(payload);
    }),
    filter(filterOnAction),
  );
}

async function performAccessedTenant(
  apolloClient: ApolloClient<any>,
  payload: RecentlyAccessedPayload,
) {
  const result = await apolloClient
    .mutate<AccessedTenantMutationDocumentNode, MutationAccessedTenantArgs>({
      mutation: accessedTenantMutation,
      variables: {
        id: payload.id,
      },
    });
}

export function onAccessedProject(
  action$: Observable<RecentAccessActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === accessedProject.type),
    distinctUntilChanged(idsAreEqual),
    mergeMap(async ({payload}: ReturnType<typeof accessedProject>) => {
      await swallowError(() => performAccessedProject(dependencies.apolloClient, payload));
      return hasAccessedProject(payload);
    }),
    filter(filterOnAction),
  );
}

async function performAccessedProject(
  apolloClient: ApolloClient<any>,
  payload: RecentlyAccessedPayload,
) {
  const result = await apolloClient
    .mutate<AccessedProjectMutationDocumentNode, MutationAccessedProjectArgs>({
      mutation: accessedProjectMutation,
      variables: {
        id: payload.id,
      },
    });
}

export function onAccessedJob(
  action$: Observable<RecentAccessActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === accessedJob.type),
    distinctUntilChanged(idsAreEqual),
    mergeMap(async ({payload}: ReturnType<typeof accessedJob>) => {
      await swallowError(() => performAccessedJob(dependencies.apolloClient, payload));
      return hasAccessedJob(payload);
    }),
    filter(filterOnAction),
  );
}

async function performAccessedJob(
  apolloClient: ApolloClient<any>,
  payload: RecentlyAccessedPayload,
) {
  const result = await apolloClient
    .mutate<AccessedJobMutationDocumentNode, MutationAccessedJobArgs>({
      mutation: accessedJobMutation,
      variables: {
        id: payload.id,
      },
    });
}

export function onAccessedFolder(
  action$: Observable<RecentAccessActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === accessedFolder.type),
    distinctUntilChanged(idsAreEqual),
    mergeMap(async ({payload}: ReturnType<typeof accessedFolder>) => {
      await swallowError(() => performAccessedFolder(dependencies.apolloClient, payload));
      return hasAccessedFolder(payload);
    }),
    filter(filterOnAction),
  );
}

async function performAccessedFolder(
  apolloClient: ApolloClient<any>,
  payload: RecentlyAccessedPayload,
) {
  const result = await apolloClient
    .mutate<AccessedFolderMutationDocumentNode, MutationAccessedFolderArgs>({
      mutation: accessedFolderMutation,
      variables: {
        id: payload.id,
      },
    });
}

export function onAccessedFile(
  action$: Observable<RecentAccessActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === accessedFile.type),
    distinctUntilChanged(idsAreEqual),
    mergeMap(async ({payload}: ReturnType<typeof accessedFile>) => {
      await swallowError(() => performAccessedFile(dependencies.apolloClient, payload));
      return hasAccessedFile(payload);
    }),
    filter(filterOnAction),
  );
}

async function performAccessedFile(
  apolloClient: ApolloClient<any>,
  payload: RecentlyAccessedPayload,
) {
  const result = await apolloClient
    .mutate<AccessedFileMutationDocumentNode, MutationAccessedFileArgs>({
      mutation: accessedFileMutation,
      variables: {
        id: payload.id,
      },
    });
}

function idsAreEqual(a: { payload: RecentlyAccessedPayload }, b: { payload: RecentlyAccessedPayload }) {
  return a.payload.id === b.payload.id;
}

async function swallowError(fn) {
  try {
    return await fn();
  } catch (e) {
    console.error(e);
  }
}
