/* eslint-disable max-len */
import {createAction} from 'redux-starter-kit';
import {
  ProjectFragmentData,
  ProjectTemplateFragmentData,
} from '../types';


export type ProjectFormActions =
  | ReturnType<typeof createdProject>
  | ReturnType<typeof createdProjectTemplate>

export const createdProject = createAction<ProjectFragmentData, "projects/createdProject">("projects/createdProject");
export const createdProjectTemplate = createAction<ProjectTemplateFragmentData, "projects/createdProjectTemplate">("projects/createdProjectTemplate");
