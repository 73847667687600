import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {noop} from 'lodash';
import {Observable} from 'rxjs';
import {
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  PreferencesActions,
  receivePreferencesUpdate,
  ReceivePreferencesUpdatePayload,
  setCameraOptions,
  setDateOptions,
  setTheme,
  setTimezone,
  toggleTheme,
} from './actions';
import {
  getDateFormatOptions,
  getTheme,
  getUserCameraOptions,
} from './state';


export const epics = [
  onSetCameraOptions,
  onSetDateOptions,
  onSetTheme,
];

export function onSetCameraOptions(
  action$: Observable<PreferencesActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === setCameraOptions.type),
    withLatestFrom(state$),
    tap(async ([_, rootState]) => {
      const {preferencesService} = dependencies;
      const nextOptions = getUserCameraOptions(rootState);
      try {
        dispatchUpdateMessage(nextOptions, dependencies);
        await preferencesService.setCameraOptions(nextOptions);
      } catch (e) {
        console.error(e);
      }
    }),
    map(noop),
    filter(filterOnAction),
  );
}

export function onSetDateOptions(
  action$: Observable<PreferencesActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === setDateOptions.type || action.type === setTimezone.type),
    withLatestFrom(state$),
    tap(async ([_, rootState]) => {
      const {preferencesService} = dependencies;
      const nextOptions = getDateFormatOptions(rootState);
      try {
        dispatchUpdateMessage(nextOptions, dependencies);
        await preferencesService.setDateFormat(nextOptions);
      } catch (e) {
        console.error(e);
      }
    }),
    map(noop),
    filter(filterOnAction),
  );
}

export function onSetTheme(
  action$: Observable<PreferencesActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === setTheme.type || action.type === toggleTheme.type),
    withLatestFrom(state$),
    tap(async ([_, rootState]) => {
      const {preferencesService} = dependencies;
      const nextTheme = getTheme(rootState);
      try {
        dispatchUpdateMessage({theme: nextTheme}, dependencies);
        await preferencesService.setTheme(nextTheme);
      } catch (e) {
        console.error(e);
      }
    }),
    map(noop),
    filter(filterOnAction),
  );
}

function dispatchUpdateMessage(payload: ReceivePreferencesUpdatePayload, dependencies: AppServices) {
  if (dependencies.messengers) {
    // inform other tabs so they can update and remove the moved files from their cache.
    dependencies.messengers.reduxChannel.postMessage(JSON.stringify({
      type: receivePreferencesUpdate.type,
      payload,
    }));
  }
}
