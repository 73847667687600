import {ApolloClient} from '@apollo/client';
import {getActionNameForProjectStatus} from '@app-features/projects/redux/utils';
import {UpdateJobStatusBatchInput} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getJobBatchFailures,
  getJobErrorConfig,
} from '../utils';
import {
  JobStatusUpdateActions,
  updateJobStatusBatch,
  UpdateJobStatusBatchPayload,
} from './actions';
import {
  getUpdateJobStatusBatchResult,
  UpdateJobStatusBatchDocumentNode,
  updateJobStatusBatchMutation,
} from './graphql';


export const epics = [
  onUpdateJobStatusBatch,
];

export function onUpdateJobStatusBatch(
  action$: Observable<JobStatusUpdateActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === updateJobStatusBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof updateJobStatusBatch>) => {
      try {
        return await performUpdateJobStatusBatch(dependencies.apolloClient, payload);
      } catch (e) {
        const action = getActionNameForProjectStatus(payload.status);
        return enqueueNotification(getJobErrorConfig(payload.models, action, DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performUpdateJobStatusBatch(
  apolloClient: ApolloClient<any>,
  payload: UpdateJobStatusBatchPayload,
) {
  const result = await apolloClient
    .mutate<UpdateJobStatusBatchDocumentNode, { input: UpdateJobStatusBatchInput }>({
      mutation: updateJobStatusBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
            status: payload.status,
            skipIfDone: payload.skipIfDone,
            skipIfCancelled: payload.skipIfCancelled,
          })),
        },
      },
    });

  const {responses} = getUpdateJobStatusBatchResult(result) || {};

  const failureData = getJobBatchFailures(payload.models, responses);

  if (failureData) {
    const action = getActionNameForProjectStatus(payload.status);
    return enqueueNotification(getJobErrorConfig(failureData.models, action, failureData.message));
  }

  return null;
}
