import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';


export interface AccessedTenantMutationDocumentNode {
  accessedTenant?: ClientMutationResponse;
}

export interface AccessedProjectMutationDocumentNode {
  accessedProject?: ClientMutationResponse;
}

export interface AccessedJobMutationDocumentNode {
  accessedJob?: ClientMutationResponse;
}

export interface AccessedFolderMutationDocumentNode {
  accessedFolder?: ClientMutationResponse;
}

export interface AccessedFileMutationDocumentNode {
  accessedFile?: ClientMutationResponse;
}

export const accessedTenantMutation = gql`
  mutation accessedTenant($id: ID!) {
    accessedTenant(id: $id) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const accessedProjectMutation = gql`
  mutation accessedProject($id: ID!) {
    accessedProject(id: $id) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const accessedJobMutation = gql`
  mutation accessedJob($id: ID!) {
    accessedJob(id: $id) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const accessedFolderMutation = gql`
  mutation accessedFolder($id: ID!) {
    accessedFolder(id: $id) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const accessedFileMutation = gql`
  mutation accessedFile($id: ID!) {
    accessedFile(id: $id) {
      clientMutationId
      code
      success
      message
    }
  }
`;
