import {ApolloClient} from '@apollo/client';
import {UpdateProjectStatusBatchInput} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getActionNameForProjectStatus,
  getProjectBatchFailures,
  getProjectErrorConfig,
} from '../utils';
import {
  ProjectStatusUpdateActions,
  updateProjectStatusBatch,
  UpdateProjectStatusBatchPayload,
} from './actions';
import {
  getUpdateProjectStatusBatchResult,
  UpdateProjectStatusBatchDocumentNode,
  updateProjectStatusBatchMutation,
} from './graphql';


export const epics = [
  onUpdateProjectStatusBatch,
];

export function onUpdateProjectStatusBatch(
  action$: Observable<ProjectStatusUpdateActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === updateProjectStatusBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof updateProjectStatusBatch>) => {
      try {
        return await performUpdateProjectStatusBatch(dependencies.apolloClient, payload);
      } catch (e) {
        const action = getActionNameForProjectStatus(payload.status);
        return enqueueNotification(getProjectErrorConfig(payload.models, action, DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performUpdateProjectStatusBatch(
  apolloClient: ApolloClient<any>,
  payload: UpdateProjectStatusBatchPayload,
) {
  const result = await apolloClient
    .mutate<UpdateProjectStatusBatchDocumentNode, { input: UpdateProjectStatusBatchInput }>({
      mutation: updateProjectStatusBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
            status: payload.status,
            skipIfDone: payload.skipIfDone,
            skipIfCancelled: payload.skipIfCancelled,
          })),
        },
      },
    });

  const {responses} = getUpdateProjectStatusBatchResult(result) || {};

  const failureData = getProjectBatchFailures(payload.models, responses);

  if (failureData) {
    const action = getActionNameForProjectStatus(payload.status);
    return enqueueNotification(getProjectErrorConfig(failureData.models, action, failureData.message));
  }

  return null;
}
