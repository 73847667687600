import {ApolloClient} from '@apollo/client';
import {
  downloadBlob,
  downloadUrl,
  FileDownload,
  saveFilesAdvanced,
  SaveFileTransformEvent,
} from '@app-features/file-tree/services/file-downloads';
import {FileFragmentData} from '@app-features/file-tree/types';
import {GetBatchFileDownloadRequestInput} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import imageBlobReduce from '@forks/image-blob-reduce';
import fileExtension from 'file-extension';
import {toLower} from 'lodash';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import uuidv4 from 'uuid/v4';
import {getFileNodeErrorConfig} from '../utils';
import {
  downloadFile,
  downloadFileList,
  DownloadFileListPayload,
  downloadFileListTransform,
  DownloadFileListTransformPayload,
  DownloadFilePayload,
  downloadLocalFile,
  FileNodeDownloadActions,
} from './actions';
import {
  FileByIdDocumentNode,
  fileByIdQuery,
  GetFilesBatchDocumentNode,
  getFilesBatchQuery,
} from './graphql';


export const epics = [
  onDownloadFile,
  onDownloadFileList,
  onDownloadFileListTransform,
  onDownloadLocalFile,
];

export function onDownloadFile(
  action$: Observable<FileNodeDownloadActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === downloadFile.type),
    mergeMap(async ({payload}: ReturnType<typeof downloadFile>) => {
      try {
        await fetchAndDownloadFile(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeErrorConfig([payload.file], 'download', DefaultNetworkErrorMessage));
      }
    }),
    filter(() => false),
  );
}

export function onDownloadFileList(
  action$: Observable<FileNodeDownloadActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === downloadFileList.type),
    mergeMap(async ({payload}: ReturnType<typeof downloadFileList>) => {
      try {
        return await downloadFilesLocally(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeErrorConfig(payload.files, 'download', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDownloadFileListTransform(
  action$: Observable<FileNodeDownloadActions>,
  state$,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === downloadFileListTransform.type),
    mergeMap(async ({payload}: ReturnType<typeof downloadFileListTransform>) => {
      try {
        return await downloadFilesLocallyWithTransform(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeErrorConfig(payload.files, 'download', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

function getFilesInput(payload: DownloadFileListPayload): GetBatchFileDownloadRequestInput {
  return {
    clientMutationId: payload.mutationId || uuidv4(),
    files: payload.files.map(file => file.id),
    relativeTo: payload.relativeTo,
    excludePath: !!payload.excludePath,
  };
}

async function fetchAndDownloadFile(
  apolloClient: ApolloClient<any>,
  {file}: DownloadFilePayload,
) {
  const result = await apolloClient
    .query<FileByIdDocumentNode, { id: string }>({
      query: fileByIdQuery,
      variables: {
        id: file.id,
      },
    });

  const {url, name} = result?.data?.fileById || {};

  if (url && name) {
    await downloadUrl(url, name);
    return null;
  } else {
    return enqueueNotification(getFileNodeErrorConfig([file], 'download', 'Something went wrong.'));
  }
}

// TODO: determine if we should include queued uploads in selected folder.
//   current behavior excludes them and it could be considered confusing/misleading
async function downloadFilesLocally(
  apolloClient: ApolloClient<any>,
  payload: DownloadFileListPayload,
) {
  let files;
  if (payload.files.length) {
    const result = await apolloClient
      .query<GetFilesBatchDocumentNode, { input: GetBatchFileDownloadRequestInput }>({
        query: getFilesBatchQuery,
        variables: {
          input: getFilesInput(payload),
        },
      });

    files = result?.data?.filesBatch?.files;
  }

  const fileDownloads: Array<FileDownload> = [
    ...(files || []).map(payload => ({
      type: 'RemoteFileDownload' as const,
      payload,
    })),
    ...payload.uploads.map(upload => ({
      type: 'LocalFileDownload' as const,
      payload: {file: upload.file},
    })),
  ];

  // TODO: verify that this is the desired behavior.
  //   we may want to just consistently download a zip file no matter what.
  if (fileDownloads.length === 1) {
    const model = fileDownloads[0];
    switch (model.type) {
      case 'LocalFileDownload':
        await downloadBlob(model.payload.file, model.payload.file.name);
        return null;
      case 'RemoteFileDownload':
        await downloadUrl(model.payload.url, model.payload.name);
        return null;
    }
  } else if (fileDownloads.length > 0) {
    try {
      await saveFilesAdvanced(fileDownloads, {
        filename: getArchiveName(payload),
      });
    } catch (e) {
      console.error(e);
      return enqueueNotification({
        message: 'Failed to download files.',
        options: {
          variant: 'error',
        },
      });
    }
    return null;
  } else {
    return enqueueNotification({
      message: 'No files to download.',
      options: {
        variant: 'info',
      },
    });
  }
}

async function downloadFilesLocallyWithTransform(
  apolloClient: ApolloClient<any>,
  payload: DownloadFileListTransformPayload,
) {
  let files;
  if (payload.files.length) {
    const result = await apolloClient
      .query<GetFilesBatchDocumentNode, { input: GetBatchFileDownloadRequestInput }>({
        query: getFilesBatchQuery,
        variables: {
          input: getFilesInput(payload),
        },
      });

    files = result?.data?.filesBatch?.files;
  }

  const fileDownloads: Array<FileDownload> = [
    ...(files || []).map(payload => ({
      type: 'RemoteFileDownload' as const,
      payload,
    })),
    ...payload.uploads.map(upload => ({
      type: 'LocalFileDownload' as const,
      payload: {file: upload.file},
    })),
  ];

  if (fileDownloads.length > 0) {
    try {
      let transform;
      if (payload.useResize) {
        transform = applyResizeTransform(payload);
      }
      await saveFilesAdvanced(fileDownloads, {
        filename: getArchiveName(payload),
        transform,
      });
    } catch (e) {
      console.error('error with applyResizeTransform', e);
      return enqueueNotification({
        message: 'Failed to resize files.',
        options: {
          variant: 'error',
        },
      });
    }
    return null;
  } else {
    return enqueueNotification({
      message: 'No files to download.',
      options: {
        variant: 'info',
      },
    });
  }
}

function getArchiveName(payload: DownloadFileListPayload): string {
  if (payload.files.length === 1 && !payload.uploads.length) {
    const model = payload.files[0];
    if (model.__typename === 'Folder') {
      return `${model.name}.zip`;
    }
  }

  return `file-selection-${Date.now()}.zip`;
}

function applyResizeTransform(payload: DownloadFileListTransformPayload) {
  return async (event: SaveFileTransformEvent) => {
    switch (event.type) {
      case 'RemoteFileDownload': {
        const {isImage, options} = getOptions(event.payload.name);

        if (isImage) {
          const srcBlob = await event.response.blob();
          const rszBlob = await imageBlobReduce()
            .toBlob(srcBlob, options);
          return rszBlob.stream();
        } else {
          return event.response.body as ReadableStream<any>;
        }
      }
      case 'LocalFileDownload': {
        const {isImage, options} = getOptions(event.payload.file.name);

        if (isImage) {
          const rszBlob = await imageBlobReduce()
            .toBlob(event.payload.file, {max: payload.resizeMaxSize});
          return rszBlob.stream();
        } else {
          return event.payload.file.stream();
        }
      }
    }
  };

  function getOptions(name: string) {
    const ext = toLower(fileExtension(name));
    let isImage = false;
    const options: any = {max: payload.resizeMaxSize};
    switch (ext) {
      case 'png':
        options.alpha = true;
      /* fall-through */
      case 'jpg':
      case 'jpeg':
        isImage = true;
    }
    return {
      isImage,
      options,
    };
  }
}

export function onDownloadLocalFile(action$: Observable<FileNodeDownloadActions>) {
  return action$.pipe(
    filter(action => action.type === downloadLocalFile.type),
    mergeMap(async ({payload}: ReturnType<typeof downloadLocalFile>) => {
      try {
        await downloadBlob(payload.file, payload.file.name);
      } catch (e) {
        const model: Pick<FileFragmentData, "__typename" | "id" | "name"> = {
          __typename: 'File',
          id: uuidv4(),
          name: payload.file.name,
        };
        return enqueueNotification(getFileNodeErrorConfig([model], 'download', 'Failed to save file.'));
      }
    }),
    filter(() => false),
  );
}
