import {
  TeamData,
  UserData,
} from '@app-lib/apollo/localTypes';
import {createAction} from 'redux-starter-kit';


export interface RemoveUserFromTeamPayload {
  user: UserData
  team: TeamData
}

export interface RemoveUserFromTeamsPayload {
  user: UserData
  teams: Array<TeamData>
}

export interface RemoveUsersFromTeamPayload {
  users: Array<UserData>
  team: TeamData
}

export type RemoveTeamMembersActions =
  | ReturnType<typeof removeUserFromTeam>
  | ReturnType<typeof removedUserFromTeam>
  | ReturnType<typeof removeUsersFromTeam>
  | ReturnType<typeof removedUsersFromTeam>
  | ReturnType<typeof removeUserFromTeams>
  | ReturnType<typeof removedUserFromTeams>

export const removeUserFromTeam = createAction<RemoveUserFromTeamPayload, "teams/removeUserFromTeam">("teams/removeUserFromTeam");
export const removedUserFromTeam = createAction<RemoveUserFromTeamPayload, "teams/removedUserFromTeam">("teams/removedUserFromTeam");
export const removeUsersFromTeam = createAction<RemoveUsersFromTeamPayload, "teams/removeUsersFromTeam">("teams/removeUsersFromTeam");
export const removedUsersFromTeam = createAction<RemoveUsersFromTeamPayload, "teams/removedUsersFromTeam">("teams/removedUsersFromTeam");
export const removeUserFromTeams = createAction<RemoveUserFromTeamsPayload, "teams/removeUserFromTeams">("teams/removeUserFromTeams");
export const removedUserFromTeams = createAction<RemoveUserFromTeamsPayload, "teams/removedUserFromTeams">("teams/removedUserFromTeams");
