import jsCookie from 'js-cookie';
import querystring from 'querystring';


export interface CookieOptions {
  encoding?: "QUERY" | "JSON",
  expires?: number | Date,
}

export function getCookie(name, {encoding = 'QUERY'}: CookieOptions = {}) {
  const val = jsCookie.get(name);
  if (encoding === 'JSON') {
    return val ? JSON.parse(val) : null;
  } else {
    return querystring.decode(val || '');
  }
}

export function setCookie(name, next, options: CookieOptions = {}) {
  const {encoding = 'QUERY'} = options;
  if (encoding === 'JSON') {
    if (!next) {
      jsCookie.remove(name);
    } else {
      setCookieWithOptions(name, JSON.stringify(next), options);
    }
    return;
  }
  const keys = Object.keys(next || {});
  switch (keys.length) {
    case 0:
      jsCookie.remove(name);
      break;
    case 1:
      setCookieWithOptions(name, querystring.encode(next), options);
      break;
    default:
      // normalize the cookie by ordering the keys
      const result = {};
      keys.sort();
      keys.forEach(key => result[key] = next[key]);
      setCookieWithOptions(name, querystring.encode(result), options);
  }
}

function setCookieWithOptions(name: string, encodedCookie: string, options: CookieOptions) {
  if (options.expires) {
    jsCookie.set(name, encodedCookie, {expires: options.expires});
  } else {
    jsCookie.set(name, encodedCookie);
  }
}
