import {
  getCookie,
  setCookie,
} from '@app-lib/cookies';
import {AppServices} from '@app-lib/services';
import {
  dateFormatVar,
  dateTimeFormatVar,
} from '@app-system/time';
import cookie from 'cookie';
import {
  isNil,
  omit,
} from 'lodash';
import querystring from 'querystring';
import {Theme} from '../enums/Theme';
import {
  DateFormatOptions,
  initialState,
  PreferencesState,
  Resolutions,
  UserCameraOptions,
} from '../redux/reducer';


const COOKIE_NAME = 'PREF';

const DateFormatFromPrefCookie = (cookie): DateFormatOptions => {
  return {
    dateFormat: cookie.df,
    dateTimeFormat: cookie.dtf,
    timezone: cookie.tz,
    lockTimezone: cookie.tzl === 't',
  };
};

const CameraOptionsFromPrefCookie = (cookie): UserCameraOptions => {
  if (isNil(cookie.ca)) {
    return {
      useCameraAccept: initialState.useCameraAccept,
      cameraResolution: initialState.cameraResolution,
    };
  } else {
    const cookieResWidth = cookie.ca_res;
    const res = Resolutions.filter(({width}) => width >= cookieResWidth)[0];
    return {
      useCameraAccept: cookie.ca,
      cameraResolution: isNil(cookieResWidth)
        ? initialState.cameraResolution
        : res || Resolutions[Resolutions.length - 1],
    };
  }
};

export class PreferencesService {
  constructor(private services: AppServices) {
  }

  private getCookie() {
    return getCookie(COOKIE_NAME);
  }

  private setCookie(next: any = {}) {
    setCookie(COOKIE_NAME, next);
  }

  clearSettings() {
    this.setCookie(null);
  }

  async setTheme(value) {
    const prevCookie = this.getCookie();

    this.setCookie({...prevCookie, ...Theme.toPrefCookie(value)});
  }

  getTheme() {
    return Theme.fromPrefCookie(this.getCookie());
  }

  async setDateFormat(value: DateFormatOptions | null) {
    const prevCookie = this.getCookie();

    if (value) {
      if (typeof window !== 'undefined') {
        dateFormatVar({
          formatString: value.dateFormat,
          timezone: value.timezone,
        });
        dateTimeFormatVar({
          formatString: value.dateTimeFormat,
          timezone: value.timezone,
        });
      }
      const cookieValue: any = {};
      if ("dateFormat" in value) {
        cookieValue.df = value.dateFormat;
      }
      if ("dateTimeFormat" in value) {
        cookieValue.dtf = value.dateTimeFormat;
      }
      if ("timezone" in value) {
        cookieValue.tz = value.timezone;
      }
      if ("lockTimezone" in value) {
        cookieValue.tzl = value.lockTimezone ? 't' : 'f';
      }
      this.setCookie({...prevCookie, ...cookieValue});
    } else if (prevCookie.df || prevCookie.tz) {
      if (typeof window !== 'undefined') {
        dateFormatVar(null);
        dateTimeFormatVar(null);
      }
      this.setCookie(omit(prevCookie, ['df', 'dtf', 'tz', 'tzl']));
    }
  }

  getDateFormat(): DateFormatOptions | undefined {
    return DateFormatFromPrefCookie(this.getCookie());
  }

  async setCameraOptions(value: UserCameraOptions | null) {
    const prevCookie = this.getCookie();

    if (value) {
      const cookieValue: any = {};
      if ("useCameraAccept" in value) {
        cookieValue.ca = value.useCameraAccept;
      }
      if ("cameraResolution" in value) {
        cookieValue.ca_res = value.cameraResolution.width;
      }
      this.setCookie({...prevCookie, ...cookieValue});
    } else if ("ca" in prevCookie || "ca_res" in prevCookie) {
      this.setCookie(omit(prevCookie, ['ca', 'ca_res']));
    }
  }

  getCameraOptions(): UserCameraOptions | undefined {
    return CameraOptionsFromPrefCookie(this.getCookie());
  }

  static getStateFromCookies(val = ''): PreferencesState {
    const cookies = cookie.parse(val);
    const cookieValue = querystring.decode(cookies[COOKIE_NAME] || '');

    return {
      theme: Theme.fromPrefCookie(cookieValue),
      ...DateFormatFromPrefCookie(cookieValue),
      ...CameraOptionsFromPrefCookie(cookieValue),
    };
  }

  static getStateFromCookiesClient(): PreferencesState {
    const cookieValue = getCookie(COOKIE_NAME);

    return {
      theme: Theme.fromPrefCookie(cookieValue),
      ...DateFormatFromPrefCookie(cookieValue),
      ...CameraOptionsFromPrefCookie(cookieValue),
    };
  }
}
