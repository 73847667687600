import {JobFragmentData} from '@app-features/jobs/types';
import {ProjectStatus} from '@app-lib/apollo/apiTypes';
import {createAction} from 'redux-starter-kit';


export interface UpdateJobStatusBatchPayload {
  models: Array<Pick<JobFragmentData, "__typename" | "id" | "name">>
  status: ProjectStatus
  skipIfDone?: boolean
  skipIfCancelled?: boolean
}

export type JobStatusUpdateActions =
  | ReturnType<typeof updateJobStatusBatch>

export const updateJobStatusBatch = createAction<UpdateJobStatusBatchPayload, "jobs/updateJobStatusBatch">("jobs/updateJobStatusBatch");
