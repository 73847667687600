/* eslint-disable max-len */
import {
  ProjectFragmentData,
  ProjectTemplateFragmentData,
} from '@app-features/projects/types';
import {createAction} from 'redux-starter-kit';


export interface DeleteProjectPayload {
  model: ProjectFragmentData;
  recycleFolder: boolean;
}

export interface DeleteProjectBatchPayload {
  models: Array<ProjectFragmentData>;
  recycleFolders: boolean;
}

export interface DeleteProjectTemplatePayload {
  model: ProjectTemplateFragmentData;
}

export interface DeleteProjectTemplateBatchPayload {
  models: Array<ProjectTemplateFragmentData>;
}

export type DeleteProjectActions =
  | ReturnType<typeof deleteProject>
  | ReturnType<typeof deletedProject>
  | ReturnType<typeof deleteProjectBatch>
  | ReturnType<typeof deletedProjectBatch>
  | ReturnType<typeof deleteProjectTemplate>
  | ReturnType<typeof deletedProjectTemplate>
  | ReturnType<typeof deleteProjectTemplateBatch>
  | ReturnType<typeof deletedProjectTemplateBatch>

export const deleteProject = createAction<DeleteProjectPayload, "projects/deleteProject">("projects/deleteProject");
export const deletedProject = createAction<DeleteProjectPayload, "projects/deletedProject">("projects/deletedProject");
export const deleteProjectBatch = createAction<DeleteProjectBatchPayload, "projects/deleteProjectBatch">("projects/deleteProjectBatch");
export const deletedProjectBatch = createAction<DeleteProjectBatchPayload, "projects/deletedProjectBatch">("projects/deletedProjectBatch");
export const deleteProjectTemplate = createAction<DeleteProjectTemplatePayload, "projects/deleteProjectTemplate">("projects/deleteProjectTemplate");
export const deletedProjectTemplate = createAction<DeleteProjectTemplatePayload, "projects/deletedProjectTemplate">("projects/deletedProjectTemplate");
export const deleteProjectTemplateBatch = createAction<DeleteProjectTemplateBatchPayload, "projects/deleteProjectTemplateBatch">("projects/deleteProjectTemplateBatch");
export const deletedProjectTemplateBatch = createAction<DeleteProjectTemplateBatchPayload, "projects/deletedProjectTemplateBatch">("projects/deletedProjectTemplateBatch");
