import {MuiThemeProvider} from '@material-ui/core/styles';
import {
  dark,
  light,
} from '@app-system/themes';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import {useSelector} from 'react-redux';
import {Theme} from '../enums/Theme';
import {getTheme} from '../redux/state';


export function ThemeProvider({children}) {
  const theme = useSelector(getTheme);
  return (
    <MuiThemeProvider theme={theme === Theme.Light ? light : dark}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
}
