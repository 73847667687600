import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {UserBatchUpdateResponse} from '../utils';


export interface RemoveUserFromOrganizationResponse {
  code: string
  success: boolean
  message: string
  userId: string
}

export interface RemoveUserFromOrganizationDocumentNode {
  removeUserFromOrganization?: ClientMutationResponse
}

export interface RemoveUserFromOrganizationBatchDocumentNode {
  removeUserFromOrganizationBatch?: ClientMutationResponse & {
    responses?: Array<UserBatchUpdateResponse>
  }
}

export const removeUserFromOrganizationMutation = gql`
  mutation removeUserFromOrganization($input: RemoveUserFromOrganizationInput!) {
    removeUserFromOrganization(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const removeUserFromOrganizationBatchMutation = gql`
  mutation removeUserFromOrganizationBatch($input: RemoveUserFromOrganizationBatchInput!) {
    removeUserFromOrganizationBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        userId
      }
    }
  }
`;

export function getRemoveUserFromOrganizationResult(
  response: { data?: RemoveUserFromOrganizationDocumentNode | null },
): RemoveUserFromOrganizationDocumentNode["removeUserFromOrganization"] {
  return get(response, 'data.removeUserFromOrganization', null);
}

export function getRemoveUserFromOrganizationBatchResult(
  response: { data?: RemoveUserFromOrganizationBatchDocumentNode | null },
): RemoveUserFromOrganizationBatchDocumentNode["removeUserFromOrganizationBatch"] {
  return get(response, 'data.removeUserFromOrganizationBatch', null);
}
