export function getSessionItem(key, defaultVal?: any) {
  try {
    if (window.sessionStorage) {
      const val = window.sessionStorage.getItem(key);
      return val ? JSON.parse(val) : defaultVal;
    }
  } catch (e) {
  }
  return window[key] || defaultVal;
}

export function setSessionItem(key: string, val: any) {
  try {
    if (window.sessionStorage) {
      window.sessionStorage.setItem(key, JSON.stringify(val));
      return;
    }
  } catch (e) {
  }
  window[key] = val;
}
