import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {MilestoneFragmentData} from '@app-features/milestones/types';
import {get} from 'lodash';
import {MilestoneBatchUpdateResponse} from '../utils';


export type MilestoneStatusUpdate = Pick<MilestoneFragmentData,
  | "__typename"
  | "id"
  | "status">

export interface UpdateMilestoneStatusBatchDocumentNode {
  updateMilestoneStatusBatch?: ClientMutationResponse & {
    responses?: Array<MilestoneBatchUpdateResponse>
    milestones?: Array<MilestoneStatusUpdate>
  }
}

export const updateMilestoneStatusBatchMutation = gql`
  mutation updateMilestoneStatusBatch(
    $input: UpdateMilestoneStatusBatchInput!
    $dateFormat: DateFormatInput
  ) {
    updateMilestoneStatusBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        milestoneId
      }
      milestones {
        __typename
        id
        status

        activeReview {
          __typename
          id
          notes
          startedAt(dateFormat: $dateFormat)
          active
          startedBy {
            __typename
            id
            name

            ... on User {
              email
              avatar
            }
          }
        }
      }
    }
  }
`;

export function getUpdateMilestoneStatusBatchResult(
  response: { data?: UpdateMilestoneStatusBatchDocumentNode | null },
): UpdateMilestoneStatusBatchDocumentNode["updateMilestoneStatusBatch"] {
  return get(response, 'data.updateMilestoneStatusBatch', null);
}
