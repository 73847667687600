import {IRecycledFileNodeFragmentData} from '@app-features/file-recycle-bin/types';
import {createAction} from 'redux-starter-kit';


export interface RecoverFileNodePayload {
  model: Pick<IRecycledFileNodeFragmentData, "__typename" | "id" | "name">
}

export interface RecoverFileNodeBatchPayload {
  models: Array<IRecycledFileNodeFragmentData>;
}

export type RecoverFileNodeActions =
  | ReturnType<typeof recoverFileNode>
  | ReturnType<typeof recoveredFileNode>
  | ReturnType<typeof recoverFileNodeBatch>
  | ReturnType<typeof recoveredFileNodeBatch>

export const recoverFileNode = createAction<RecoverFileNodePayload, "recycle-bin/recoverFileNode">("recycle-bin/recoverFileNode");
export const recoveredFileNode = createAction<RecoverFileNodePayload, "recycle-bin/recoveredFileNode">("recycle-bin/recoveredFileNode");
export const recoverFileNodeBatch = createAction<RecoverFileNodeBatchPayload, "recycle-bin/recoverFileNodeBatch">("recycle-bin/recoverFileNodeBatch");
export const recoveredFileNodeBatch = createAction<RecoverFileNodeBatchPayload, "recycle-bin/recoveredFileNodeBatch">("recycle-bin/recoveredFileNodeBatch");
