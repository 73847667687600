import {JobIcon} from '@app-components/icons/JobIcon';
import {ActionNamesForProjectStatus} from '@app-features/projects/redux/utils';
import {BatchFailureData} from '@app-lib/apollo/localTypes';
import {buildErrorNotification} from '@app-system/notifications/components/ErrorNotification';
import {find} from 'lodash';
import {JobFragmentData} from '../types';


export interface JobBatchUpdateResponse {
  code: string
  success: boolean
  message: string
  jobId: string
}

export function getJobBatchFailures(
  models: Array<Pick<JobFragmentData, "__typename" | "id" | "name">>,
  responses: Array<JobBatchUpdateResponse> | null | undefined,
): BatchFailureData<Pick<JobFragmentData, "__typename" | "id" | "name">> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.jobId)
    ?.forEach(response => successes.add(response.jobId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getJobErrorConfig(
  models: Array<Pick<JobFragmentData, "__typename" | "id" | "name">>,
  action:
    | "archive"
    | "restore"
    | ActionNamesForProjectStatus
    | "update"
    | "delete",
  message?: string | null,
) {
  const remain = models.length - 1;
  return {
    message: `Failed to ${action} Job${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${models[0].id}`,
      persist: true,
      content: buildErrorNotification({
        icon: JobIcon,
        error: message || `Something went wrong.`,
        name: models[0].name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}
