import {ApolloClient} from '@apollo/client';
import {
  DeleteTemplateCommitBatchInput,
  DeleteTemplateCommitInput,
  DeleteTemplateRepositoryBatchInput,
  DeleteTemplateRepositoryInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getTemplateCommitBatchFailures,
  getTemplateCommitErrorConfig,
  getTemplateRepositoryBatchFailures,
  getTemplateRepositoryErrorConfig,
} from '../utils';
import {
  deletedTemplateCommit,
  deletedTemplateCommitBatch,
  deletedTemplateRepository,
  deletedTemplateRepositoryBatch,
  DeleteTemplateActions,
  deleteTemplateCommit,
  deleteTemplateCommitBatch,
  DeleteTemplateCommitBatchPayload,
  DeleteTemplateCommitPayload,
  deleteTemplateRepository,
  deleteTemplateRepositoryBatch,
  DeleteTemplateRepositoryBatchPayload,
  DeleteTemplateRepositoryPayload,
} from './actions';
import {
  DeleteTemplateCommitBatchDocumentNode,
  deleteTemplateCommitBatchMutation,
  DeleteTemplateCommitDocumentNode,
  deleteTemplateCommitMutation,
  DeleteTemplateRepositoryBatchDocumentNode,
  deleteTemplateRepositoryBatchMutation,
  DeleteTemplateRepositoryDocumentNode,
  deleteTemplateRepositoryMutation,
  getDeleteTemplateCommitBatchResult,
  getDeleteTemplateCommitResult,
  getDeleteTemplateRepositoryBatchResult,
  getDeleteTemplateRepositoryResult,
} from './graphql';


export const epics = [
  onDeleteTemplateCommit,
  onDeleteTemplateCommitBatch,
  onDeleteTemplateRepository,
  onDeleteTemplateRepositoryBatch,
];

export function onDeleteTemplateCommit(
  action$: Observable<DeleteTemplateActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteTemplateCommit.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteTemplateCommit>) => {
      try {
        return await performDeleteTemplateCommit(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTemplateCommitErrorConfig([payload.model], 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteTemplateCommitBatch(
  action$: Observable<DeleteTemplateActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteTemplateCommitBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteTemplateCommitBatch>) => {
      try {
        return await performDeleteTemplateCommitBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTemplateCommitErrorConfig(payload.models, 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteTemplateRepository(
  action$: Observable<DeleteTemplateActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteTemplateRepository.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteTemplateRepository>) => {
      try {
        return await performDeleteTemplateRepository(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTemplateRepositoryErrorConfig([payload.model], 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteTemplateRepositoryBatch(
  action$: Observable<DeleteTemplateActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteTemplateRepositoryBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteTemplateRepositoryBatch>) => {
      try {
        return await performDeleteTemplateRepositoryBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTemplateRepositoryErrorConfig(payload.models, 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performDeleteTemplateCommit(
  apolloClient: ApolloClient<any>,
  payload: DeleteTemplateCommitPayload,
) {
  const result = await apolloClient
    .mutate<DeleteTemplateCommitDocumentNode, { input: DeleteTemplateCommitInput }>({
      mutation: deleteTemplateCommitMutation,
      variables: {
        input: {
          id: payload.model.id,
        },
      },
    });

  const {success, message} = getDeleteTemplateCommitResult(result) || {};

  if (!success) {
    return enqueueNotification(getTemplateCommitErrorConfig([payload.model], 'delete', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return deletedTemplateCommit(payload);
  }
}

async function performDeleteTemplateCommitBatch(
  apolloClient: ApolloClient<any>,
  payload: DeleteTemplateCommitBatchPayload,
) {
  const result = await apolloClient
    .mutate<DeleteTemplateCommitBatchDocumentNode, { input: DeleteTemplateCommitBatchInput }>({
      mutation: deleteTemplateCommitBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
          })),
        },
      },
    });

  const {responses} = getDeleteTemplateCommitBatchResult(result) || {};

  const failureData = getTemplateCommitBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getTemplateCommitErrorConfig(failureData.models, 'delete', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return deletedTemplateCommitBatch(payload);
  }
}


async function performDeleteTemplateRepository(
  apolloClient: ApolloClient<any>,
  payload: DeleteTemplateRepositoryPayload,
) {
  const result = await apolloClient
    .mutate<DeleteTemplateRepositoryDocumentNode, { input: DeleteTemplateRepositoryInput }>({
      mutation: deleteTemplateRepositoryMutation,
      variables: {
        input: {
          id: payload.model.id,
        },
      },
    });

  const {success, message} = getDeleteTemplateRepositoryResult(result) || {};

  if (!success) {
    return enqueueNotification(getTemplateRepositoryErrorConfig([payload.model], 'delete', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return deletedTemplateRepository(payload);
  }
}

async function performDeleteTemplateRepositoryBatch(
  apolloClient: ApolloClient<any>,
  payload: DeleteTemplateRepositoryBatchPayload,
) {
  const result = await apolloClient
    .mutate<DeleteTemplateRepositoryBatchDocumentNode, { input: DeleteTemplateRepositoryBatchInput }>({
      mutation: deleteTemplateRepositoryBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
          })),
        },
      },
    });

  const {responses} = getDeleteTemplateRepositoryBatchResult(result) || {};

  const failureData = getTemplateRepositoryBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getTemplateRepositoryErrorConfig(failureData.models, 'delete', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return deletedTemplateRepositoryBatch(payload);
  }
}
