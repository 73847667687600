import {
  BatchFailureData,
  TeamData,
} from '@app-lib/apollo/localTypes';
import {buildErrorNotification} from '@app-system/notifications/components/ErrorNotification';
import {Group as GroupIcon} from '@material-ui/icons';
import {find} from 'lodash';
import {DeleteTeamResponse} from './deletion/graphql';


export function getTeamBatchFailures(
  models: Array<Pick<TeamData, "__typename" | "id" | "name">>,
  responses: Array<DeleteTeamResponse> | null | undefined,
): BatchFailureData<Pick<TeamData, "__typename" | "id" | "name">> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.teamId)
    ?.forEach(response => successes.add(response.teamId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getTeamErrorConfig(
  models: Array<Pick<TeamData, "__typename" | "id" | "name">>,
  action:
    | "remove from"
    | "delete",
  message?: string | null,
  notificationMessage?: string,
) {
  const remain = models.length - 1;
  return {
    message: notificationMessage ? notificationMessage : `Failed to ${action} Team${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${models[0].id}`,
      persist: true,
      content: buildErrorNotification({
        icon: GroupIcon,
        error: message || `Something went wrong.`,
        name: models[0].name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}
