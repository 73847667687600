import {ApolloClient} from '@apollo/client';
import {
  FileNodeBlockMemberBatchInput,
  FileNodeBlockMemberInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getActorBatchFailures,
  getActorErrorConfig,
} from '../utils';
import {
  blockActorFromFileNode,
  BlockActorFromFileNodePayload,
  blockActorsFromFileNodeBatch,
  BlockActorsFromFileNodeBatchPayload,
  blockedActorFromFileNode,
  blockedActorsFromFileNodeBatch,
  BlockFileNodeMembershipActions,
} from './actions';
import {
  FileNodeBlockMemberBatchDocumentNode,
  fileNodeBlockMemberBatchMutation,
  FileNodeBlockMemberDocumentNode,
  fileNodeBlockMemberMutation,
  getFileNodeBlockMemberBatchResult,
  getFileNodeBlockMemberResult,
} from './graphql';


export const epics = [
  onBlockActorFromFileNode,
  onBlockActorsFromFileNodeBatch,
];

export function onBlockActorFromFileNode(
  action$: Observable<BlockFileNodeMembershipActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === blockActorFromFileNode.type),
    mergeMap(async ({payload}: ReturnType<typeof blockActorFromFileNode>) => {
      try {
        return await performBlockActorFromFileNode(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getActorErrorConfig([payload.actor], 'block', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onBlockActorsFromFileNodeBatch(
  action$: Observable<BlockFileNodeMembershipActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === blockActorsFromFileNodeBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof blockActorsFromFileNodeBatch>) => {
      try {
        return await performBlockActorsFromFileNodeBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getActorErrorConfig(payload.actors, 'block', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performBlockActorFromFileNode(
  apolloClient: ApolloClient<any>,
  payload: BlockActorFromFileNodePayload,
) {
  const result = await apolloClient
    .mutate<FileNodeBlockMemberDocumentNode, { input: FileNodeBlockMemberInput }>({
      mutation: fileNodeBlockMemberMutation,
      variables: {
        input: {
          fileNodeId: payload.fileNode.id,
          targetActorId: payload.actor.id,
        },
      },
    });

  const {success, message} = getFileNodeBlockMemberResult(result) || {};

  if (!success) {
    return enqueueNotification(getActorErrorConfig([payload.actor], 'block', message));
  } else {
    return blockedActorFromFileNode(payload);
  }
}

async function performBlockActorsFromFileNodeBatch(
  apolloClient: ApolloClient<any>,
  payload: BlockActorsFromFileNodeBatchPayload,
) {
  const result = await apolloClient
    .mutate<FileNodeBlockMemberBatchDocumentNode, { input: FileNodeBlockMemberBatchInput }>({
      mutation: fileNodeBlockMemberBatchMutation,
      variables: {
        input: {
          targets: payload.actors.map(actor => ({
            fileNodeId: payload.fileNode.id,
            targetActorId: actor.id,
          })),
        },
      },
    });

  const {responses} = getFileNodeBlockMemberBatchResult(result) || {};

  const failureData = getActorBatchFailures(payload.actors, responses);

  if (failureData) {
    return enqueueNotification(getActorErrorConfig(failureData.models, 'block', failureData.message));
  } else {
    return blockedActorsFromFileNodeBatch(payload);
  }
}
