import {ThemeProvider} from '@app-system/preferences/components/ThemeProvider';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import {DndProvider} from 'react-dnd-cjs';


export interface FeatureWarningProps {
  onClick: () => void;
}

export function FeatureWarning({
  onClick,
}: FeatureWarningProps) {
  return (
    <Dialog
      maxWidth="sm"
      open
      disableBackdropClick
      disableEscapeKeyDown
      disableEnforceFocus
    >
      <DialogTitle>
        File Uploader Not Fully Supported
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your browser does not support features required for this app to properly manage uploads.
          <br />
          <br />
          If you continue to use the app in this browser,
          any uploads that have not completed when you refresh, leave, or close the page will be lost and cannot be recovered.
          <br />
          <br />
          We recommend using Google Chrome, Firefox, or any modern browser based off Chromium such as Brave Browser.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
        >
          I Acknowledge This Warning
        </Button>
      </DialogActions>
    </Dialog>
  );
}
