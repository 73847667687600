import {reducer as uploadManagerReducer} from '@app-features/file-tree/redux/upload-manager/reducer';
import {reducer as cameraReducer} from '@app-system/camera/redux/reducer';
import {reducer as preferencesReducer} from '@app-system/preferences/redux/reducer';
import {reducer as tenantReducer} from '@app-system/tenant/redux/reducer';
import {combineReducers} from 'redux';


export const rootReducer = combineReducers({
  camera: cameraReducer,
  uploadManager: uploadManagerReducer,
  preferences: preferencesReducer,
  tenant: tenantReducer,
});

export type RootAppState = ReturnType<typeof rootReducer>
