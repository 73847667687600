import {DropCardEvent} from '@app-features/file-tree/lib/dnd';
import {IFileNodeFragmentData} from '@app-features/file-tree/types';
import {
  FilePlaceholder,
  FileUpload,
} from '@app-lib/apollo/localTypes';
import {createAction} from 'redux-starter-kit';


export type MoveFileNodePayload = DropCardEvent<IFileNodeFragmentData | FilePlaceholder | FileUpload>

export interface MovedFileNodesPayload {
  models: Array<string>
}

export type FileNodeMovementActions =
  | ReturnType<typeof movedFileNodes>
  | ReturnType<typeof moveFileNode>

export const movedFileNodes = createAction<MovedFileNodesPayload, "files/movedFileNodes">("files/movedFileNodes");
export const moveFileNode = createAction<MoveFileNodePayload, "files/moveFileNode">("files/moveFileNode");
