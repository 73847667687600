import {ApolloClient} from '@apollo/client';
import {
  LockFileNodeBatchInput,
  LockFileNodeInput,
  UnlockFileNodeBatchInput,
  UnlockFileNodeInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getFileNodeBatchFailures,
  getFileNodeErrorConfig,
} from '../utils';
import {
  FileNodeLocksActions,
  lockFileNode,
  lockFileNodeBatch,
  LockFileNodeBatchPayload,
  LockFileNodePayload,
  unlockFileNode,
  unlockFileNodeBatch,
  UnlockFileNodeBatchPayload,
  UnlockFileNodePayload,
} from './actions';
import {
  getLockFileNodeBatchResult,
  getLockFileNodeResult,
  getUnlockFileNodeBatchResult,
  getUnlockFileNodeResult,
  LockFileNodeBatchDocumentNode,
  lockFileNodeBatchMutation,
  LockFileNodeDocumentNode,
  lockFileNodeMutation,
  UnlockFileNodeBatchDocumentNode,
  unlockFileNodeBatchMutation,
  UnlockFileNodeDocumentNode,
  unlockFileNodeMutation,
} from './graphql';


export const epics = [
  onLockFileNode,
  onUnlockFileNode,
  onLockFileNodeBatch,
  onUnlockFileNodeBatch,
];

export function onLockFileNode(
  action$: Observable<FileNodeLocksActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === lockFileNode.type),
    mergeMap(async ({payload}: ReturnType<typeof lockFileNode>) => {
      try {
        return await performLockFileNode(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeErrorConfig([payload.model], 'lock', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onUnlockFileNode(
  action$: Observable<FileNodeLocksActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === unlockFileNode.type),
    mergeMap(async ({payload}: ReturnType<typeof unlockFileNode>) => {
      try {
        return await performUnlockFileNode(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeErrorConfig([payload.model], 'unlock', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onLockFileNodeBatch(
  action$: Observable<FileNodeLocksActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === lockFileNodeBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof lockFileNodeBatch>) => {
      try {
        return await performLockFileNodeBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeErrorConfig(payload.models, 'lock', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onUnlockFileNodeBatch(
  action$: Observable<FileNodeLocksActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === unlockFileNodeBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof unlockFileNodeBatch>) => {
      try {
        return await performUnlockFileNodeBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeErrorConfig(payload.models, 'unlock', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performLockFileNode(
  apolloClient: ApolloClient<any>,
  payload: LockFileNodePayload,
) {
  const result = await apolloClient
    .mutate<LockFileNodeDocumentNode, { input: LockFileNodeInput }>({
      mutation: lockFileNodeMutation,
      variables: {
        input: {id: payload.model.id},
      },
    });

  const {success, message} = getLockFileNodeResult(result) || {};

  if (!success) {
    return enqueueNotification(getFileNodeErrorConfig([payload.model], 'lock', message));
  }

  return null;
}

async function performUnlockFileNode(
  apolloClient: ApolloClient<any>,
  payload: UnlockFileNodePayload,
) {
  const result = await apolloClient
    .mutate<UnlockFileNodeDocumentNode, { input: UnlockFileNodeInput }>({
      mutation: unlockFileNodeMutation,
      variables: {
        input: {id: payload.model.id},
      },
    });

  const {success, message} = getUnlockFileNodeResult(result) || {};

  if (!success) {
    return enqueueNotification(getFileNodeErrorConfig([payload.model], 'unlock', message));
  }

  return null;
}

async function performLockFileNodeBatch(
  apolloClient: ApolloClient<any>,
  payload: LockFileNodeBatchPayload,
) {
  const result = await apolloClient
    .mutate<LockFileNodeBatchDocumentNode, { input: LockFileNodeBatchInput }>({
      mutation: lockFileNodeBatchMutation,
      variables: {
        input: {targets: payload.models.map(it => it.id)},
      },
    });

  const {message, responses} = getLockFileNodeBatchResult(result) || {};

  const failureData = getFileNodeBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getFileNodeErrorConfig(failureData.models, 'lock', failureData.message));
  }

  return null;
}

async function performUnlockFileNodeBatch(
  apolloClient: ApolloClient<any>,
  payload: UnlockFileNodeBatchPayload,
) {
  const result = await apolloClient
    .mutate<UnlockFileNodeBatchDocumentNode, { input: UnlockFileNodeBatchInput }>({
      mutation: unlockFileNodeBatchMutation,
      variables: {
        input: {targets: payload.models.map(it => it.id)},
      },
    });

  const {responses} = getUnlockFileNodeBatchResult(result) || {};

  const failureData = getFileNodeBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getFileNodeErrorConfig(failureData.models, 'unlock', failureData.message));
  }

  return null;
}
