import {ProjectFragmentData} from '@app-features/projects/types';
import {createAction} from 'redux-starter-kit';


export interface ArchiveProjectPayload {
  model: Pick<ProjectFragmentData, "__typename" | "id" | "name">
}
export interface UnarchiveProjectPayload {
  model: Pick<ProjectFragmentData, "__typename" | "id" | "name">
}
export interface ArchiveProjectBatchPayload {
  models: Array<Pick<ProjectFragmentData, "__typename" | "id" | "name">>
}
export interface UnarchiveProjectBatchPayload {
  models: Array<Pick<ProjectFragmentData, "__typename" | "id" | "name">>
}

export type ProjectArchivalActions =
  | ReturnType<typeof archiveProject>
  | ReturnType<typeof unarchiveProject>
  | ReturnType<typeof archiveProjectBatch>
  | ReturnType<typeof unarchiveProjectBatch>

export const archiveProject = createAction<ArchiveProjectPayload, "projects/archiveProject">("projects/archiveProject");
export const unarchiveProject = createAction<UnarchiveProjectPayload, "projects/unarchiveProject">("projects/unarchiveProject");
export const archiveProjectBatch = createAction<ArchiveProjectBatchPayload, "projects/archiveProjectBatch">("projects/archiveProjectBatch");
export const unarchiveProjectBatch = createAction<UnarchiveProjectBatchPayload, "projects/unarchiveProjectBatch">("projects/unarchiveProjectBatch");
