import {ApolloProvider} from '@apollo/client';
import {AppServicesContext} from '@app-lib/AppServicesProvider';
import {withApolloAndRedux} from '@app-lib/withApolloAndRedux';
import {
  Notifier,
  SnackBarOptions,
} from '@app-system/notifications/components/Notifier';
import {ThemeProvider} from '@app-system/preferences/components/ThemeProvider';
import MomentUtils from '@date-io/moment';
import HTML5Backend from '@forks/react-dnd-html5-backend';
import {LocalizationProvider} from '@material-ui/pickers';
import 'blob-polyfill';
import moment from 'moment';
import App from 'next/app';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import {DndProvider} from 'react-dnd-cjs';
import {Provider} from 'react-redux';
import 'web-streams-polyfill';


declare global {
  interface window {
    ReadableStream;
    ReadableStreamDefaultController;
    ReadableByteStreamController;
    ReadableStreamBYOBRequest;
    ReadableStreamDefaultReader;
    ReadableStreamBYOBReader;
    WritableStream;
    WritableStreamDefaultController;
    WritableStreamDefaultWriter;
    ByteLengthQueuingStrategy;
    CountQueuingStrategy;
    TransformStream;
    TransformStreamDefaultController;
  }
}

class MyApp extends App {
  static async getInitialProps({Component, router, ctx}) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return {pageProps};
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  render() {
    const {
      Component,
      pageProps,
      apolloClient,
      store,
      services,
    } = this.props as any;
    return (
      <AppServicesContext.Provider value={services}>
        <ApolloProvider client={apolloClient}>
          <Provider store={store}>
            <DndProvider backend={HTML5Backend}>
              <ThemeProvider>
                <LocalizationProvider dateAdapter={MomentUtils} dateLibInstance={moment}>
                  <SnackbarProvider anchorOrigin={SnackBarOptions.anchorOrigin}>
                    <Notifier services={services} />
                    <Component {...pageProps} />
                  </SnackbarProvider>
                </LocalizationProvider>
              </ThemeProvider>
            </DndProvider>
          </Provider>
        </ApolloProvider>
      </AppServicesContext.Provider>
    );
  }
}

export default withApolloAndRedux(MyApp);
