/* eslint-disable max-len */
import {createAction} from 'redux-starter-kit';
import {MilestoneFragmentData} from '../types';


export type MilestoneFormActions =
  | ReturnType<typeof createdMilestone>
  | ReturnType<typeof updatedMilestone>

export const createdMilestone = createAction<MilestoneFragmentData, "milestones/createdMilestone">("milestones/createdMilestone");
export const updatedMilestone = createAction<MilestoneFragmentData, "milestones/updatedMilestone">("milestones/updatedMilestone");
