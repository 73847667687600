import {
  FileContainerUploadManagerStartUploadPayload,
  FolderUploadManagerStartUploadPayload,
  UploadManagerStartUploadPayload,
} from '@app-features/file-tree/redux/upload-manager/actions';
import {
  FileFragmentData,
  FileSubmissionFragmentData,
} from '@app-features/file-tree/types';
import {
  FileUploadRequestField,
  GetFileSubmissionUploadRequestInput,
  GetFileUploadRequestInput,
  UpdateBatchUploadProgressMutation,
  UpdateUploadProgressMutation,
  ValidationError,
} from '@app-lib/apollo/apiTypes';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';


export interface BatchFileUploadRequestData {
  isFileContainer: false
  requests: Array<GetFileUploadRequestInput>
  fileList: Array<FolderUploadManagerStartUploadPayload>
}

export interface BatchFileSubmissionUploadRequestData {
  isFileContainer: true
  requests: Array<GetFileSubmissionUploadRequestInput>
  fileList: Array<FileContainerUploadManagerStartUploadPayload>
}

export interface FileUploadRequestData {
  isFileContainer: false
  input: GetFileUploadRequestInput
  fileData: FolderUploadManagerStartUploadPayload
}

export interface FileSubmissionUploadRequestData {
  isFileContainer: true
  input: GetFileSubmissionUploadRequestInput
  fileData: FileContainerUploadManagerStartUploadPayload
}

export type IFileUploadRequestData =
  | FileUploadRequestData
  | FileSubmissionUploadRequestData

export interface ErrorIFileUploadRequest {
  clientMutationId?: string | null
  success: false
  code: string
  message: string
  validationErrors?: Array<ValidationError> | null
  signedRequestUrl: null
  fields: null
  fileId: null
  file: null
}

export interface SuccessIFileUploadRequest {
  clientMutationId?: string | null
  success: true
  code: string
  message: string
  validationErrors?: Array<ValidationError> | null
  signedRequestUrl: string
  fields: Array<FileUploadRequestField>
  fileId: string
  file: FileFragmentData | FileSubmissionFragmentData
}

export interface GetBatchIFileUploadsMutation extends ClientMutationResponse {
  requests?: Array<ErrorIFileUploadRequest | SuccessIFileUploadRequest | null> | null
}

export function getFileUploadRequestResult(response): ErrorIFileUploadRequest | SuccessIFileUploadRequest | null {
  return response?.data?.getFileUploadRequest ?? null;
}

export function getFileSubmissionUploadRequestResult(response): ErrorIFileUploadRequest | SuccessIFileUploadRequest | null {
  return response?.data?.getFileSubmissionUploadRequest ?? null;
}

export function getBatchFileUploadsResult(response): GetBatchIFileUploadsMutation | null {
  return response?.data?.getBatchFileUploadsRequest ?? null;
}

export function getBatchFileSubmissionUploadsResult(response): GetBatchIFileUploadsMutation | null {
  return response?.data?.getBatchFileSubmissionUploadsRequest ?? null;
}

export function getUpdateBatchUploadProgressResult(response): UpdateBatchUploadProgressMutation | null {
  return response?.data?.updateBatchUploadProgress ?? null;
}

export function getUpdateUploadProgressResult(response): UpdateUploadProgressMutation | null {
  return response?.data?.updateUploadProgress ?? null;
}

export function buildFileUploadInput(fileData: UploadManagerStartUploadPayload): IFileUploadRequestData {
  const {
    mutationId,
    file,
  } = fileData;

  if (fileData.parentType === 'FileContainer') {
    return {
      isFileContainer: true,
      input: {
        clientMutationId: mutationId,
        fileName: file.name,
        fileContainerId: fileData.fileContainerId,
      },
      fileData,
    };
  } else {
    return {
      isFileContainer: false,
      input: {
        clientMutationId: mutationId,
        fileName: file.name,
        parentId: fileData.parentId,
      },
      fileData,
    };
  }
}
