import {ApolloClient} from '@apollo/client';
import {
  FileNodeRemoveMemberBatchInput,
  FileNodeRemoveMemberInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getFileNodeMemberBatchFailures,
  getFileNodeMemberErrorConfig,
} from '../utils';
import {
  removedFileNodeMember,
  removedFileNodeMemberBatch,
  removeFileNodeMember,
  removeFileNodeMemberBatch,
  RemoveFileNodeMemberBatchPayload,
  RemoveFileNodeMemberPayload,
  RemoveFileNodeMembersActions,
} from './actions';
import {
  FileNodeRemoveMemberBatchDocumentNode,
  fileNodeRemoveMemberBatchMutation,
  FileNodeRemoveMemberDocumentNode,
  fileNodeRemoveMemberMutation,
  getFileNodeRemoveMemberBatchResult,
  getFileNodeRemoveMemberResult,
} from './graphql';


export const epics = [
  onRemoveFileNodeMember,
  onRemoveFileNodeMemberBatch,
];

export function onRemoveFileNodeMember(
  action$: Observable<RemoveFileNodeMembersActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === removeFileNodeMember.type),
    mergeMap(async ({payload}: ReturnType<typeof removeFileNodeMember>) => {
      try {
        return await performRemoveFileNodeMember(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeMemberErrorConfig([payload.model], 'remove', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onRemoveFileNodeMemberBatch(
  action$: Observable<RemoveFileNodeMembersActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === removeFileNodeMemberBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof removeFileNodeMemberBatch>) => {
      try {
        return await performRemoveFileNodeMemberBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeMemberErrorConfig(payload.models, 'remove', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performRemoveFileNodeMember(
  apolloClient: ApolloClient<any>,
  payload: RemoveFileNodeMemberPayload,
) {
  const result = await apolloClient
    .mutate<FileNodeRemoveMemberDocumentNode, { input: FileNodeRemoveMemberInput }>({
      mutation: fileNodeRemoveMemberMutation,
      variables: {
        input: {
          id: payload.model.id,
        },
      },
    });

  const {success, message} = getFileNodeRemoveMemberResult(result) || {};

  if (!success) {
    return enqueueNotification(getFileNodeMemberErrorConfig([payload.model], 'remove', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return removedFileNodeMember(payload);
  }
}

async function performRemoveFileNodeMemberBatch(
  apolloClient: ApolloClient<any>,
  payload: RemoveFileNodeMemberBatchPayload,
) {
  const result = await apolloClient
    .mutate<FileNodeRemoveMemberBatchDocumentNode, { input: FileNodeRemoveMemberBatchInput }>({
      mutation: fileNodeRemoveMemberBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => it.id),
        },
      },
    });

  const {responses} = getFileNodeRemoveMemberBatchResult(result) || {};

  const failureData = getFileNodeMemberBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getFileNodeMemberErrorConfig(failureData.models, 'remove', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return removedFileNodeMemberBatch(payload);
  }
}
