import {RootAppState} from '@app-lib/redux/reducers';
import {
  buildClientInitialState as preferencesInitClient,
  buildServerInitialState as preferencesInit,
} from '@app-system/preferences/redux/initServerState';


export function buildServerInitialState(ctx): Pick<RootAppState, "preferences"> {
  return {
    preferences: preferencesInit(ctx),
  };
}

export function buildClientInitialState(): Pick<RootAppState, "preferences"> {
  return {
    preferences: preferencesInitClient(),
  };
}
