/* eslint-disable max-len */
import {TeamData} from '@app-lib/apollo/localTypes';
import {createAction} from 'redux-starter-kit';


export type TeamFormActions =
  | ReturnType<typeof createdTeam>
  | ReturnType<typeof updatedTeam>

export const createdTeam = createAction<TeamData, "teams/createdTeam">("teams/createdTeam");
export const updatedTeam = createAction<TeamData, "teams/updatedTeam">("teams/updatedTeam");
