/* eslint-disable max-len */
import {createAction} from 'redux-starter-kit';


export interface UpdatedFileMembersPayload {
  fileNodeIds: Array<string>;
  actorIds: Array<string>;
}

export type FileAccessFormActions =
  | ReturnType<typeof updatedFileMembers>

export const updatedFileMembers = createAction<UpdatedFileMembersPayload, "files/updatedFileMembers">("files/updatedFileMembers");
