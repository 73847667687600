import {gql} from '@apollo/client';
import {ProjectFragmentData} from '@app-features/projects/types';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {ProjectBatchUpdateResponse} from '../utils';


export type ProjectStatusUpdate = Pick<ProjectFragmentData,
  | "__typename"
  | "id"
  | "status">

export interface UpdateProjectStatusBatchDocumentNode {
  updateProjectStatusBatch?: ClientMutationResponse & {
    responses?: Array<ProjectBatchUpdateResponse>
    projects?: Array<ProjectStatusUpdate>
  }
}

export const updateProjectStatusBatchMutation = gql`
  mutation updateProjectStatusBatch($input: UpdateProjectStatusBatchInput!) {
    updateProjectStatusBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        projectId
      }
      projects {
        __typename
        id
        status
      }
    }
  }
`;

export function getUpdateProjectStatusBatchResult(
  response: { data?: UpdateProjectStatusBatchDocumentNode | null },
): UpdateProjectStatusBatchDocumentNode["updateProjectStatusBatch"] {
  return get(response, 'data.updateProjectStatusBatch', null);
}
