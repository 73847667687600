import {gql} from '@apollo/client';
import {Connection} from '@app-lib/apollo/apiTypeGenerics';
import {
  FileContainer,
  FileNodeLock,
  FileNodeStatus,
  FileSubmission,
  PermissionMap,
} from '@app-lib/apollo/apiTypes';
import {
  ApplicationData,
  FilePlaceholder,
  FileUpload,
  UserData,
} from '@app-lib/apollo/localTypes';


export type IFileNodeFragmentData =
  | FolderFragmentData
  | FileFragmentData
  | FileContainerFragmentData
  | FileSubmissionFragmentData

export type IFileFragmentData =
  | FileFragmentData
  | FileSubmissionFragmentData

export type ISharableFileNodeFragmentData =
  | FolderFragmentData
  | FileFragmentData
  | FileContainerFragmentData

export type FileCardModel =
  | FileContainerFragmentData
  | FileFragmentData
  | FileSubmissionFragmentData
  | FilePlaceholder
  | FileUpload

export type FileContainerNodeModel =
  | FileContainerFragmentData
  | FileSubmissionFragmentData
  | FilePlaceholder
  | FileUpload

export type FileContainerContentsModel =
  | FileSubmissionFragmentData
  | FilePlaceholder
  | FileUpload

interface IFileNodeCommonData {
  __typename:
    | "Folder"
    | "FileContainer"
    | "FileSubmission"
    | "File"
  id: string
  mutationId?: string | null
  name: string
  notes?: string | null
  locked: FileNodeLock
  lockedBy?: {
    __typename:
      | "Folder"
      | "FileContainer"
      | "FileSubmission"
      | "File"
    id
    name
    parentId
    path
  }
  parentId: string | null
  fullPath: string
  path: string
  pathIds: Array<string>
  hasPermissions?: PermissionMap | null
  createdAt: string
  createdBy: UserData | ApplicationData
}

export interface FilePreviewData {
  __typename?: "FilePreview"
  url?: string | null
}

export interface FileFragmentData extends Omit<IFileNodeCommonData, "__typename"> {
  __typename: "File"
  contentType: string
  uploading: boolean
  size?: number
  preview?: FilePreviewData | null
}

export interface FileContainerFragmentData extends Omit<IFileNodeCommonData, "__typename"> {
  __typename: "FileContainer"
  status: FileNodeStatus
  completedSubmissions: number
  renameFile: boolean
  minSuggestion: number | null
  maxSuggestion: number | null
  minRequired: number | null
  maxAllowed: number | null

  maxFileSize: number
  whitelistExtensions: Array<string>
  customWhitelistExtensions: Array<string>
  inheritedFileType?: string | null
}

export interface FileSubmissionFragmentData extends Omit<IFileNodeCommonData, "__typename"> {
  __typename: "FileSubmission"
  originalName: string
  contentType: string
  uploading: boolean
  size?: number
  status: FileNodeStatus
  fileContainerId: string
  fileContainer: {
    __typename: "FileContainer"
    id: string
    name: string
    status: FileNodeStatus
  }
  preview?: FilePreviewData | null
}

export interface FolderFragmentData extends Omit<IFileNodeCommonData, "__typename"> {
  __typename: "Folder"
  allowFiles?: boolean | null
  maxAllowed?: number | null
  maxFileSize: number
}

export interface FolderWithFilesFragmentData extends FolderFragmentData {
  children: Connection<IFileNodeFragmentData>
}

export const IFileNodeFragment = gql`
  fragment IFileNodeFragment on IFileNode {
    __typename
    id
    mutationId
    name
    notes
    locked
    lockedBy {
      __typename
      id
      name
      parentId
      path
    }
    parentId
    fullPath
    path
    pathIds
    createdAt(dateFormat: $dateFormat)
    hasPermissions {
      file_data_create
      file_data_read
      file_data_update
      file_data_recycle
      file_data_recycleown
      file_data_delete
      file_lock_file
      file_lock_fileContainer
      file_lock_folder
      file_status_submit
      file_status_na
      file_status_reject
      file_status_approve
      file_members_read
      file_members_manage
    }

    createdBy {
      __typename
      id
      name

      ... on User {
        email
        avatar
      }
    }
  }
`;

export const FileFragment = gql`
  fragment FileFragment on File {
    contentType
    uploading
    size
    preview(options: {width: $thumbnailSize, height: $thumbnailSize}) {
      url
    }
  }
`;

export const FileContainerFragment = gql`
  fragment FileContainerFragment on FileContainer {
    status
    completedSubmissions
    renameFile
    minSuggestion
    maxSuggestion
    minRequired
    maxAllowed

    maxFileSize
    whitelistExtensions
    customWhitelistExtensions
    inheritedFileType
  }
`;

export const FileSubmissionFragment = gql`
  fragment FileSubmissionFragment on FileSubmission {
    originalName
    contentType
    uploading
    size
    status
    fileContainerId
    fileContainer {
      __typename
      id
      name
      status
    }
    preview(options: {width: $thumbnailSize, height: $thumbnailSize}) {
      url
    }
  }
`;

export const FolderFragment = gql`
  fragment FolderFragment on Folder {
    allowFiles
    maxAllowed
    maxFileSize
  }
`;
