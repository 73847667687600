import {gql} from '@apollo/client';
import {JobFragmentData} from '@app-features/jobs/types';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {JobBatchUpdateResponse} from '../utils';


export type JobArchivalUpdate = Pick<JobFragmentData,
  | "__typename"
  | "id"
  | "archived">

export interface JobArchivalUpdateResponse {
  code: string
  success: boolean
  message: string
  jobId: string
}

export interface ArchiveJobDocumentNode {
  archiveJob?: ClientMutationResponse & {
    job?: JobArchivalUpdate
  }
}

export interface UnarchiveJobDocumentNode {
  unarchiveJob?: ClientMutationResponse & {
    job?: JobArchivalUpdate
  }
}

export interface ArchiveJobBatchDocumentNode {
  archiveJobBatch?: ClientMutationResponse & {
    responses?: Array<JobBatchUpdateResponse>
    jobs?: Array<JobArchivalUpdate>
  }
}

export interface UnarchiveJobBatchDocumentNode {
  unarchiveJobBatch?: ClientMutationResponse & {
    responses?: Array<JobBatchUpdateResponse>
    jobs?: Array<JobArchivalUpdate>
  }
}

export const archiveJobMutation = gql`
  mutation archiveJob($input: ArchiveJobInput!) {
    archiveJob(input: $input) {
      clientMutationId
      code
      success
      message
      job {
        __typename
        id
        archived
      }
    }
  }
`;

export const unarchiveJobMutation = gql`
  mutation unarchiveJob($input: UnarchiveJobInput!) {
    unarchiveJob(input: $input) {
      clientMutationId
      code
      success
      message
      job {
        __typename
        id
        archived
      }
    }
  }
`;

export const archiveJobBatchMutation = gql`
  mutation archiveJobBatch($input: ArchiveJobBatchInput!) {
    archiveJobBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        jobId
      }
      jobs {
        __typename
        id
        archived
      }
    }
  }
`;

export const unarchiveJobBatchMutation = gql`
  mutation unarchiveJobBatch($input: UnarchiveJobBatchInput!) {
    unarchiveJobBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        jobId
      }
      jobs {
        __typename
        id
        archived
      }
    }
  }
`;

export function getArchiveJobResult(
  response: { data?: ArchiveJobDocumentNode | null },
): ArchiveJobDocumentNode["archiveJob"] {
  return get(response, 'data.archiveJob', null);
}

export function getUnarchiveJobResult(
  response: { data?: UnarchiveJobDocumentNode | null },
): UnarchiveJobDocumentNode["unarchiveJob"] {
  return get(response, 'data.unarchiveJob', null);
}

export function getArchiveJobBatchResult(
  response: { data?: ArchiveJobBatchDocumentNode | null },
): ArchiveJobBatchDocumentNode["archiveJobBatch"] {
  return get(response, 'data.archiveJobBatch', null);
}

export function getUnarchiveJobBatchResult(
  response: { data?: UnarchiveJobBatchDocumentNode | null },
): UnarchiveJobBatchDocumentNode["unarchiveJobBatch"] {
  return get(response, 'data.unarchiveJobBatch', null);
}
