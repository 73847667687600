import {ApolloClient} from '@apollo/client';
import {
  downloadUrl,
  FileDownload,
  saveFilesAdvanced,
} from '@app-features/file-tree/services/file-downloads';
import {GetBatchFileDownloadRequestInput} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import uuidv4 from 'uuid/v4';
import {getRecycledFileNodeErrorConfig} from '../utils';
import {
  downloadRecycledFile,
  downloadRecycledFileList,
  DownloadRecycledFileListPayload,
  RecycleBinDownloadActions,
  RecycledDownloadFilePayload,
} from './actions';
import {
  RecycledFileByIdDocumentNode,
  recycledFileByIdQuery,
  RecycledFilesBatchDocumentNode,
  recycledFilesBatchQuery,
} from './graphql';


export const epics = [
  onDownloadRecycledFile,
  onDownloadRecycledFileList,
];

export function onDownloadRecycledFile(
  action$: Observable<RecycleBinDownloadActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === downloadRecycledFile.type),
    mergeMap(async ({payload}: ReturnType<typeof downloadRecycledFile>) => {
      try {
        await fetchAndDownloadFile(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getRecycledFileNodeErrorConfig([payload.file], 'download', DefaultNetworkErrorMessage));
      }
    }),
    filter(() => false),
  );
}

export function onDownloadRecycledFileList(
  action$: Observable<RecycleBinDownloadActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === downloadRecycledFileList.type),
    mergeMap(async ({payload}: ReturnType<typeof downloadRecycledFileList>) => {
      try {
        return await downloadFilesLocally(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getRecycledFileNodeErrorConfig(payload.files, 'download', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

function getFilesInput(payload: DownloadRecycledFileListPayload): GetBatchFileDownloadRequestInput {
  return {
    clientMutationId: payload.mutationId || uuidv4(),
    files: payload.files.map(file => file.id),
    relativeTo: payload.relativeTo,
    excludePath: !!payload.excludePath,
  };
}

async function fetchAndDownloadFile(
  apolloClient: ApolloClient<any>,
  {file}: RecycledDownloadFilePayload,
) {
  const {data} = await apolloClient
    .query<RecycledFileByIdDocumentNode, { id: string }>({
      query: recycledFileByIdQuery,
      variables: {
        id: file.id,
      },
    });

  const {url, name} = data?.recycledFileById || {};

  if (url && name) {
    await downloadUrl(url, name);
    return null;
  } else {
    return enqueueNotification(getRecycledFileNodeErrorConfig([file], 'download', 'Something went wrong.'));
  }
}

async function downloadFilesLocally(
  apolloClient: ApolloClient<any>,
  payload: DownloadRecycledFileListPayload,
) {
  const {data} = await apolloClient
    .query<RecycledFilesBatchDocumentNode, { input: GetBatchFileDownloadRequestInput }>({
      query: recycledFilesBatchQuery,
      variables: {
        input: getFilesInput(payload),
      },
    });

  const files = data?.recycledFilesBatch?.files;

  const fileDownloads: Array<FileDownload> = [
    ...(files || []).map(payload => ({
      type: 'RemoteFileDownload' as const,
      // TODO: apply relative path here or remove it maybe?
      payload,
    })),
  ];

  // TODO: verify that this is the desired behavior.
  //   we may want to just consistently download a zip file no matter what.
  if (fileDownloads.length === 1) {
    const model = fileDownloads[0];
    switch (model.type) {
      case 'RemoteFileDownload':
        await downloadUrl(model.payload.url, model.payload.name);
        return null;
    }
  } else if (fileDownloads.length > 0) {
    try {
      await saveFilesAdvanced(fileDownloads, {
        filename: getArchiveName(payload),
      });
    } catch (e) {
      console.error(e);
      // TODO: notify the user
    }
    return null;
  } else {
    return enqueueNotification({
      message: 'No files to download.',
      options: {
        variant: 'info',
      },
    });
  }
}

function getArchiveName(payload: DownloadRecycledFileListPayload): string {
  if (payload.files.length === 1) {
    const model = payload.files[0];
    if (model.__typename === 'RecycledFolder') {
      return `${model.name}.zip`;
    }
  }

  return `file-selection-${Date.now()}.zip`;
}
