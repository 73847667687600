import { createMuiTheme } from '@material-ui/core/styles';
import {
  blue,
  grey,
  red,
} from '@material-ui/core/colors';


export const light = createMuiTheme({
  palette: {
    primary: {
      main: '#1a53c2',
    },
    secondary: {
      main: '#4886ff',
    },
    error: {
      main: red.A400,
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: 36,
      },
    },
  },
});

export const dark = createMuiTheme({
  palette: {
    primary: {
      light: grey[500],
      main: grey[900],
      dark: grey[900],
      contrastText: blue[300],
    },
    secondary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    type: 'dark',
  },
});
