import {IFileNodeFragmentData} from '@app-features/file-tree/types';
import {createAction} from 'redux-starter-kit';


export interface RecycleFileNodeBatchPayload {
  models: Array<Pick<IFileNodeFragmentData, "__typename" | "id" | "name">>
}

export type RecycleFileNodesActions =
  | ReturnType<typeof recycleFileNodeBatch>
  | ReturnType<typeof recycledFileNodeBatch>

export const recycleFileNodeBatch = createAction<RecycleFileNodeBatchPayload, "files/recycleFileNodeBatch">("files/recycleFileNodeBatch");
export const recycledFileNodeBatch = createAction<RecycleFileNodeBatchPayload, "files/recycledFileNodeBatch">("files/recycledFileNodeBatch");
