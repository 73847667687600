import {ApolloClient} from '@apollo/client';
import {
  RecoverFileNodeBatchInput,
  RecoverFileNodeInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getRecycledFileNodeBatchFailures,
  getRecycledFileNodeErrorConfig,
} from '../utils';
import {
  recoveredFileNode,
  recoveredFileNodeBatch,
  recoverFileNode,
  RecoverFileNodeActions,
  recoverFileNodeBatch,
  RecoverFileNodeBatchPayload,
  RecoverFileNodePayload,
} from './actions';
import {
  getRecoverFileNodeBatchResult,
  getRecoverFileNodeResult,
  RecoverFileNodeBatchDocumentNode,
  recoverFileNodeBatchMutation,
  RecoverFileNodeDocumentNode,
  recoverFileNodeMutation,
} from './graphql';


export const epics = [
  onRecoverFileNode,
  onRecoverFileNodeBatch,
];

export function onRecoverFileNode(
  action$: Observable<RecoverFileNodeActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === recoverFileNode.type),
    mergeMap(async ({payload}: ReturnType<typeof recoverFileNode>) => {
      try {
        return await performRecoverFileNode(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getRecycledFileNodeErrorConfig([payload.model], 'recover', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onRecoverFileNodeBatch(
  action$: Observable<RecoverFileNodeActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === recoverFileNodeBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof recoverFileNodeBatch>) => {
      try {
        return await performRecoverFileNodeBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getRecycledFileNodeErrorConfig(payload.models, 'recover', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performRecoverFileNode(
  apolloClient: ApolloClient<any>,
  payload: RecoverFileNodePayload,
) {
  const result = await apolloClient
    .mutate<RecoverFileNodeDocumentNode, { input: RecoverFileNodeInput }>({
      mutation: recoverFileNodeMutation,
      variables: {
        input: {
          id: payload.model.id,
        },
      },
    });

  const {success, message} = getRecoverFileNodeResult(result) || {};

  if (!success) {
    return enqueueNotification(getRecycledFileNodeErrorConfig([payload.model], 'recover', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return recoveredFileNode(payload);
  }
}

async function performRecoverFileNodeBatch(
  apolloClient: ApolloClient<any>,
  payload: RecoverFileNodeBatchPayload,
) {
  const result = await apolloClient
    .mutate<RecoverFileNodeBatchDocumentNode, { input: RecoverFileNodeBatchInput }>({
      mutation: recoverFileNodeBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => it.id),
        },
      },
    });

  const {responses} = getRecoverFileNodeBatchResult(result) || {};

  const failureData = getRecycledFileNodeBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getRecycledFileNodeErrorConfig(failureData.models, 'recover', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return recoveredFileNodeBatch(payload);
  }
}
