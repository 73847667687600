import {createReducer} from 'redux-starter-kit';
import {
  loadedTenant,
  loadingTenant,
} from './actions';


export interface TenantState {
  loading: boolean;
}

export const initialState: TenantState = {
  loading: false,
};

export const reducer = createReducer(initialState, {
  [loadingTenant.type]: handleLoadingTenant,
  [loadedTenant.type]: handleLoadedTenant,
});

function handleLoadingTenant(draft, action: ReturnType<typeof loadingTenant>) {
  draft.loading = true;

  return draft;
}

function handleLoadedTenant(draft, action: ReturnType<typeof loadedTenant>) {
  draft.loading = false;

  return draft;
}
