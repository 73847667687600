import {ApolloClient} from '@apollo/client';
import {
  DeleteMilestoneBatchInput,
  DeleteMilestoneInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getMilestoneBatchFailures,
  getMilestoneErrorConfig,
} from '../utils';
import {
  deletedMilestone,
  deletedMilestoneBatch,
  deleteMilestone,
  DeleteMilestoneActions,
  deleteMilestoneBatch,
  DeleteMilestoneBatchPayload,
  DeleteMilestonePayload,
} from './actions';
import {
  DeleteMilestoneBatchDocumentNode,
  deleteMilestoneBatchMutation,
  DeleteMilestoneDocumentNode,
  deleteMilestoneMutation,
  getDeleteMilestoneBatchResult,
  getDeleteMilestoneResult,
} from './graphql';


export const epics = [
  onDeleteMilestone,
  onDeleteMilestoneBatch,
];

export function onDeleteMilestone(
  action$: Observable<DeleteMilestoneActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteMilestone.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteMilestone>) => {
      try {
        return await performDeleteMilestone(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getMilestoneErrorConfig([payload.model], 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteMilestoneBatch(
  action$: Observable<DeleteMilestoneActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteMilestoneBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteMilestoneBatch>) => {
      try {
        return await performDeleteMilestoneBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getMilestoneErrorConfig(payload.models, 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performDeleteMilestone(
  apolloClient: ApolloClient<any>,
  payload: DeleteMilestonePayload,
) {
  const result = await apolloClient
    .mutate<DeleteMilestoneDocumentNode, { input: DeleteMilestoneInput }>({
      mutation: deleteMilestoneMutation,
      variables: {
        input: {id: payload.model.id},
      },
    });

  const {success, message} = getDeleteMilestoneResult(result) || {};

  if (!success) {
    return enqueueNotification(getMilestoneErrorConfig([payload.model], 'delete', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return deletedMilestone(payload);
  }
}

async function performDeleteMilestoneBatch(
  apolloClient: ApolloClient<any>,
  payload: DeleteMilestoneBatchPayload,
) {
  const result = await apolloClient
    .mutate<DeleteMilestoneBatchDocumentNode, { input: DeleteMilestoneBatchInput }>({
      mutation: deleteMilestoneBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
          })),
        },
      },
    });

  const {responses} = getDeleteMilestoneBatchResult(result) || {};

  const failureData = getMilestoneBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getMilestoneErrorConfig(failureData.models, 'delete', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return deletedMilestoneBatch(payload);
  }
}
