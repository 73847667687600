import {
  Card,
  CardActions,
  Collapse,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import classNames from 'clsx';
import {
  SnackbarContent,
  SnackbarKey,
  useSnackbar,
} from 'notistack';
import React, {
  forwardRef,
  useCallback,
  useState,
} from 'react';


export interface ErrorNotificationProps {
  id?: SnackbarKey
  message: string
  error: string
  icon?: React.ComponentType<{ className: string }>
  name: string
}

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    width: '100%',
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: 16,
  },
  button: {
    padding: 0,
  },
  icon: {
    fontSize: 20,
    paddingRight: 4,
    minWidth: 'initial',
  },
}));

export function buildErrorNotification({
  error,
  icon,
  name,
}: Omit<ErrorNotificationProps, "id" | "message">) {
  return (key, message) => (
    <ErrorNotification
      key={key}
      id={key}
      message={message}
      error={error}
      icon={icon}
      name={name}
    />
  );
}

export const ErrorNotification = forwardRef(({
  id,
  error,
  message,
  icon: Icon,
  name,
}: ErrorNotificationProps, ref) => {
  const classes = useStyles();
  const {closeSnackbar} = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => setExpanded(expanded => !expanded), []);

  const handleDismiss = useCallback(() => closeSnackbar(id), [id]);

  return (
    // @ts-ignore
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{root: classes.actionRoot}}>
          <Typography variant="subtitle2" className={classes.typography}>
            {message}
          </Typography>
          <div style={{marginLeft: 'auto'}}>
            <IconButton
              aria-label="Show more"
              className={classNames(classes.expand, {[classes.expandOpen]: expanded})}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton className={classes.expand} onClick={handleDismiss}>
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.collapse}>
            <Typography gutterBottom>
              {error}
            </Typography>
            <ListItem className={classes.button}>
              {Icon ? (
                <ListItemIcon className={classes.icon}>
                  <Icon className={classes.icon} />
                </ListItemIcon>
              ) : null}
              <ListItemText
                primary={<Typography noWrap>{name}</Typography>}
              />
            </ListItem>
          </Paper>
        </Collapse>
      </Card>
      {/*
       // @ts-ignore */}
    </SnackbarContent>
  );
});
