import {ApolloClient} from '@apollo/client';
import {DateFormatInput} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {getDateFormatOptions} from '@app-system/preferences/redux/state';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import {getTemplateRepositoryErrorConfig} from '../utils';
import {
  UpdateTemplateActions,
  updateTemplateRepository,
  UpdateTemplateRepositoryPayload,
} from './actions';
import {
  getUpdateTemplateRepositoryResult,
  UpdateTemplateRepositoryDocumentInput,
  UpdateTemplateRepositoryDocumentNode,
  updateTemplateRepositoryMutation,
} from './graphql';


export const epics = [
  onUpdateTemplateRepository,
];

export function onUpdateTemplateRepository(
  action$: Observable<UpdateTemplateActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === updateTemplateRepository.type),
    withLatestFrom(state$),
    mergeMap(async ([{payload}, rootState]: [ReturnType<typeof updateTemplateRepository>, any]) => {
      try {
        const prefs = getDateFormatOptions(rootState);
        const dateFormat = {
          formatString: prefs.dateTimeFormat,
          timezone: prefs.timezone,
        };
        return await performUpdateTemplateRepository(dependencies.apolloClient, payload, dateFormat);
      } catch (e) {
        return enqueueNotification(getTemplateRepositoryErrorConfig([payload.model], 'update', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performUpdateTemplateRepository(
  apolloClient: ApolloClient<any>,
  payload: UpdateTemplateRepositoryPayload,
  dateFormat: DateFormatInput,
) {
  const result = await apolloClient
    .mutate<UpdateTemplateRepositoryDocumentNode, UpdateTemplateRepositoryDocumentInput>({
      mutation: updateTemplateRepositoryMutation,
      variables: {
        input: {
          id: payload.model.id,
          draft: payload.model.draft,
        },
        dateFormat,
      },
    });

  const {success, message} = getUpdateTemplateRepositoryResult(result) || {};

  if (!success) {
    return enqueueNotification(getTemplateRepositoryErrorConfig([payload.model], 'update', message));
  }

  return null;
}
