import {TypePolicies} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';
import {
  FileContainerPolicy,
  FilePolicy,
  FilePreviewPolicy,
  FolderPolicy,
} from './files';
import {JobPolicy} from './jobs';
import {MilestonePolicy} from './milestones';
import {
  OrganizationMembershipPolicy,
  OrganizationPolicy,
} from './organizations';
import {ProjectPolicy} from './projects';
import {
  TeamAvatarPolicy,
  TeamPolicy,
} from './teams';
import {UserPolicy} from './users';


export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      organizations: relayStylePagination(['searchText']),
      projects: relayStylePagination([
        'searchText',
        'filter',
      ]),
      teams: relayStylePagination(['searchText']),
      recycledFileNodes: relayStylePagination([
        'searchText',
        'filter',
      ]),
    },
  },
  FileContainer: FileContainerPolicy,
  File: FilePolicy,
  FilePreview: FilePreviewPolicy,
  Folder: FolderPolicy,
  Milestone: MilestonePolicy,
  Organization: OrganizationPolicy,
  OrganizationMembership: OrganizationMembershipPolicy,
  Project: ProjectPolicy,
  Job: JobPolicy,
  TeamAvatar: TeamAvatarPolicy,
  Team: TeamPolicy,
  User: UserPolicy,
};
