import {gql} from '@apollo/client';
import {
  TemplateRepositoryFragment,
  TemplateRepositoryFragmentData,
} from '@app-features/template-repositories/types';
import {
  DateFormatInput,
  UpdateTemplateRepositoryInput,
} from '@app-lib/apollo/apiTypes';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';


export interface UpdateTemplateRepositoryDocumentNode {
  updateTemplateRepository?: ClientMutationResponse & {
    repository?: TemplateRepositoryFragmentData;
  };
}

export interface UpdateTemplateRepositoryDocumentInput {
  input: UpdateTemplateRepositoryInput;
  dateFormat: DateFormatInput;
}

export const updateTemplateRepositoryMutation = gql`
  mutation updateTemplateRepository(
    $input: UpdateTemplateRepositoryInput!
    $dateFormat: DateFormatInput
  ) {
    updateTemplateRepository(input: $input) {
      clientMutationId
      code
      success
      message
      validationErrors {
        field
        message
      }
      repository {
        ... TemplateRepositoryFragment
      }
    }
  }
  ${TemplateRepositoryFragment}
`;


export function getUpdateTemplateRepositoryResult(
  response: { data?: UpdateTemplateRepositoryDocumentNode | null },
): UpdateTemplateRepositoryDocumentNode["updateTemplateRepository"] {
  return get(response, 'data.updateTemplateRepository', null);
}
