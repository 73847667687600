import {ApolloClient} from '@apollo/client';
import {
  getTemplateBatchFailures,
  getTemplateErrorConfig,
} from '@app-features/template-repositories/redux/utils';
import {
  DeleteProjectBatchInput,
  DeleteProjectInput,
  DeleteProjectTemplateBatchInput,
  DeleteProjectTemplateInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getProjectBatchFailures,
  getProjectErrorConfig,
} from '../utils';
import {
  deletedProject,
  deletedProjectBatch,
  deletedProjectTemplate,
  deletedProjectTemplateBatch,
  deleteProject,
  DeleteProjectActions,
  deleteProjectBatch,
  DeleteProjectBatchPayload,
  DeleteProjectPayload,
  deleteProjectTemplate,
  deleteProjectTemplateBatch,
  DeleteProjectTemplateBatchPayload,
  DeleteProjectTemplatePayload,
} from './actions';
import {
  DeleteProjectBatchDocumentNode,
  deleteProjectBatchMutation,
  DeleteProjectDocumentNode,
  deleteProjectMutation,
  DeleteProjectTemplateBatchDocumentNode,
  deleteProjectTemplateBatchMutation,
  DeleteProjectTemplateDocumentNode,
  deleteProjectTemplateMutation,
  getDeleteProjectBatchResult,
  getDeleteProjectResult,
  getDeleteProjectTemplateBatchResult,
  getDeleteProjectTemplateResult,
} from './graphql';


export const epics = [
  onDeleteProject,
  onDeleteProjectBatch,
  onDeleteProjectTemplate,
  onDeleteProjectTemplateBatch,
];

export function onDeleteProject(
  action$: Observable<DeleteProjectActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteProject.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteProject>) => {
      try {
        return await performDeleteProject(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getProjectErrorConfig([payload.model], 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteProjectBatch(
  action$: Observable<DeleteProjectActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteProjectBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteProjectBatch>) => {
      try {
        return await performDeleteProjectBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getProjectErrorConfig(payload.models, 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteProjectTemplate(
  action$: Observable<DeleteProjectActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteProjectTemplate.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteProjectTemplate>) => {
      try {
        return await performDeleteProjectTemplate(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTemplateErrorConfig([payload.model], 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteProjectTemplateBatch(
  action$: Observable<DeleteProjectActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteProjectTemplateBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteProjectTemplateBatch>) => {
      try {
        return await performDeleteProjectTemplateBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTemplateErrorConfig(payload.models, 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performDeleteProject(
  apolloClient: ApolloClient<any>,
  payload: DeleteProjectPayload,
) {
  const result = await apolloClient
    .mutate<DeleteProjectDocumentNode, { input: DeleteProjectInput }>({
      mutation: deleteProjectMutation,
      variables: {
        input: {
          id: payload.model.id,
          recycleFolder: payload.recycleFolder,
        },
      },
    });

  const {success, message} = getDeleteProjectResult(result) || {};

  if (!success) {
    return enqueueNotification(getProjectErrorConfig([payload.model], 'delete', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return deletedProject(payload);
  }
}

async function performDeleteProjectBatch(
  apolloClient: ApolloClient<any>,
  payload: DeleteProjectBatchPayload,
) {
  const result = await apolloClient
    .mutate<DeleteProjectBatchDocumentNode, { input: DeleteProjectBatchInput }>({
      mutation: deleteProjectBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
            recycleFolder: payload.recycleFolders,
          })),
        },
      },
    });

  const {responses} = getDeleteProjectBatchResult(result) || {};

  const failureData = getProjectBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getProjectErrorConfig(failureData.models, 'delete', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return deletedProjectBatch(payload);
  }
}

async function performDeleteProjectTemplate(
  apolloClient: ApolloClient<any>,
  payload: DeleteProjectTemplatePayload,
) {
  const result = await apolloClient
    .mutate<DeleteProjectTemplateDocumentNode, { input: DeleteProjectTemplateInput }>({
      mutation: deleteProjectTemplateMutation,
      variables: {
        input: {
          id: payload.model.id,
        },
      },
    });

  const {success, message} = getDeleteProjectTemplateResult(result) || {};

  if (!success) {
    return enqueueNotification(getTemplateErrorConfig([payload.model], 'delete', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return deletedProjectTemplate(payload);
  }
}

async function performDeleteProjectTemplateBatch(
  apolloClient: ApolloClient<any>,
  payload: DeleteProjectTemplateBatchPayload,
) {
  const result = await apolloClient
    .mutate<DeleteProjectTemplateBatchDocumentNode, { input: DeleteProjectTemplateBatchInput }>({
      mutation: deleteProjectTemplateBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
          })),
        },
      },
    });

  const {responses} = getDeleteProjectTemplateBatchResult(result) || {};

  const failureData = getTemplateBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getTemplateErrorConfig(failureData.models, 'delete', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return deletedProjectTemplateBatch(payload);
  }
}
