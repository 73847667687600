import {IRecycledFileNodeFragmentData} from '@app-features/file-recycle-bin/types';
import {IFileNodeFragmentData} from '@app-features/file-tree/types';
import {createAction} from 'redux-starter-kit';


export interface DeleteFileNodePayload {
  model:
    | Pick<IFileNodeFragmentData, "__typename" | "id" | "name">
    | Pick<IRecycledFileNodeFragmentData, "__typename" | "id" | "name">
}

export interface DeleteFileNodeBatchPayload {
  models: Array<IFileNodeFragmentData | IRecycledFileNodeFragmentData>;
}

export type DeleteFileNodeActions =
  | ReturnType<typeof deleteFileNode>
  | ReturnType<typeof deletedFileNode>
  | ReturnType<typeof deleteFileNodeBatch>
  | ReturnType<typeof deletedFileNodeBatch>

export const deleteFileNode = createAction<DeleteFileNodePayload, "deletion/deleteFileNode">("deletion/deleteFileNode");
export const deletedFileNode = createAction<DeleteFileNodePayload, "deletion/deletedFileNode">("deletion/deletedFileNode");
export const deleteFileNodeBatch = createAction<DeleteFileNodeBatchPayload, "deletion/deleteFileNodeBatch">("deletion/deleteFileNodeBatch");
export const deletedFileNodeBatch = createAction<DeleteFileNodeBatchPayload, "deletion/deletedFileNodeBatch">("deletion/deletedFileNodeBatch");
