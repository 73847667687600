import {
  getCookie,
  setCookie,
} from '@app-lib/cookies';
import {AppServices} from '@app-lib/services';
import {getPageVisibility} from '@app-lib/utils/browser';
import cookie from 'cookie';
import {
  get,
  isString,
  isUndefined,
} from 'lodash';
import querystring from 'querystring';
import {AbstractAppService} from '../../AbstractAppService';


/** SessionStorage key name for the currently selected tenant in the tab. */
const KEY = 'TENANT_ID';
/** Temporary cookie to communicate the current tenant to the next page load. */
const COOKIE_NAME = 'TENANT';
/** Permanent cookie to store the most recently accessed tenant the user has accessed as a fallback for new tabs. */
const LAST_COOKIE_NAME = 'LAST_TENANT';

export class TenantService extends AbstractAppService {
  private tenantId: string | null;

  constructor(services: AppServices, initialProps) {
    super(services);
    this.tenantId = null;

    if (this.isServer) {
      this.initOnServer();
    } else {
      this.initOnClient(initialProps);
    }
  }

  getTenantId(): string | null {
    return this.tenantId;
  }

  setTenantId(tenantId: string | null) {
    this.tenantId = tenantId;

    if (!this.isServer) {
      if (window.sessionStorage) {
        if (!tenantId) {
          window.sessionStorage.removeItem(KEY);
          setCookie(LAST_COOKIE_NAME, null);
        } else {
          window.sessionStorage.setItem(KEY, tenantId);
          setCookie(LAST_COOKIE_NAME, {id: this.tenantId, time: Date.now()});
        }
      }
    }
  }

  clearTenant() {
    this.setTenantId(null);
    setCookie(COOKIE_NAME, null);
    setCookie(LAST_COOKIE_NAME, null);
    console.debug(`clearTenant -> Tenant=${this.tenantId ?? '(none)'}`);
  }

  private initOnServer() {
    // use ctx to read cookie, if it exists.
    const id = TenantService.getTenantFromHeaders(get(this.services.ctx, 'req.headers'));
    if (id) {
      this.tenantId = id;
    }
  }

  static getTenantFromHeaders(headers) {
    const cookies = cookie.parse(get(headers, 'cookie') || '');
    const cookieValue = querystring.decode(cookies[COOKIE_NAME] || '');
    if (cookieValue.id && isString(cookieValue.id)) {
      console.log(`Reading on-page-refresh tenant cookie = ${cookieValue.id}`);
      return cookieValue.id;
    } else {
      // fallback to the last accessed tenant cookie, if it exists
      const cookieValue = querystring.decode(cookies[LAST_COOKIE_NAME] || '');
      if (cookieValue.id && isString(cookieValue.id)) {
        console.log(`Reading last-accessed tenant cookie = ${cookieValue.id}`);
        return cookieValue.id;
      }
    }
  }

  private initOnClient(initialProps) {
    this.initClientValue(initialProps);
    this.initLastAccessedUpdates();
    this.initPageReloadCookie();
    console.debug(`Tenant=${this.tenantId ?? '(none)'}`);
  }

  private initClientValue(initialProps) {
    if (initialProps && initialProps.tenantId) {
      console.log(`Setting the tenant from page initialProps`);
      this.setTenantId(initialProps.tenantId);
    } else if (window.sessionStorage) {
      console.log(`Reading the tenant from session storage`);
      this.tenantId = window.sessionStorage.getItem(KEY);
    } else {
      const lastAccessedCookie = getCookie(LAST_COOKIE_NAME);
      if (lastAccessedCookie && lastAccessedCookie.id) {
        console.log(`Reading the tenant from last-accessed tenant cookie`);
        this.tenantId = lastAccessedCookie.id;
      }
    }
  }

  private initLastAccessedUpdates() {
    if (this.tenantId) {
      setCookie(LAST_COOKIE_NAME, {id: this.tenantId, time: Date.now()});
    }

    const {hidden, visibilityChange} = getPageVisibility();

    if (!isUndefined(document.addEventListener) && hidden) {
      document.addEventListener(visibilityChange, () => {
        if (!document[hidden] && this.tenantId) {
          setCookie(LAST_COOKIE_NAME, {id: this.tenantId, time: Date.now()});
        }
      }, false);
    }
  }

  private initPageReloadCookie() {
    if (!isUndefined(window.addEventListener)) {
      window.addEventListener('beforeunload', (e) => {
        if (this.tenantId) {
          // expire the cookie after 5 seconds.
          setCookie(COOKIE_NAME, {id: this.tenantId}, {expires: new Date(Date.now() + 5000)});
        }
      });
    }
  }
}
