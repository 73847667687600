import {ApolloClient} from '@apollo/client';
import {
  getProjectBatchFailures,
  getProjectErrorConfig,
} from '@app-features/projects/redux/utils';
import {
  ArchiveProjectBatchInput,
  ArchiveProjectInput,
  UnarchiveProjectBatchInput,
  UnarchiveProjectInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  archiveProject,
  archiveProjectBatch,
  ArchiveProjectBatchPayload,
  ArchiveProjectPayload,
  ProjectArchivalActions,
  unarchiveProject,
  unarchiveProjectBatch,
  UnarchiveProjectBatchPayload,
  UnarchiveProjectPayload,
} from './actions';
import {
  ArchiveProjectBatchDocumentNode,
  archiveProjectBatchMutation,
  ArchiveProjectDocumentNode,
  archiveProjectMutation,
  getArchiveProjectBatchResult,
  getArchiveProjectResult,
  getUnarchiveProjectBatchResult,
  getUnarchiveProjectResult,
  UnarchiveProjectBatchDocumentNode,
  unarchiveProjectBatchMutation,
  UnarchiveProjectDocumentNode,
  unarchiveProjectMutation,
} from './graphql';


export const epics = [
  onArchiveProject,
  onUnarchiveProject,
  onArchiveProjectBatch,
  onUnarchiveProjectBatch,
];

export function onArchiveProject(
  action$: Observable<ProjectArchivalActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === archiveProject.type),
    mergeMap(async ({payload}: ReturnType<typeof archiveProject>) => {
      try {
        return await performArchiveProject(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getProjectErrorConfig([payload.model], 'archive', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onUnarchiveProject(
  action$: Observable<ProjectArchivalActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === unarchiveProject.type),
    mergeMap(async ({payload}: ReturnType<typeof unarchiveProject>) => {
      try {
        return await performUnarchiveProject(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getProjectErrorConfig([payload.model], 'restore', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onArchiveProjectBatch(
  action$: Observable<ProjectArchivalActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === archiveProjectBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof archiveProjectBatch>) => {
      try {
        return await performArchiveProjectBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getProjectErrorConfig(payload.models, 'archive', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onUnarchiveProjectBatch(
  action$: Observable<ProjectArchivalActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === unarchiveProjectBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof unarchiveProjectBatch>) => {
      try {
        return await performUnarchiveProjectBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getProjectErrorConfig(payload.models, 'restore', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performArchiveProject(
  apolloClient: ApolloClient<any>,
  payload: ArchiveProjectPayload,
) {
  const result = await apolloClient
    .mutate<ArchiveProjectDocumentNode, { input: ArchiveProjectInput }>({
      mutation: archiveProjectMutation,
      variables: {
        input: {id: payload.model.id},
      },
    });

  const {success, message} = getArchiveProjectResult(result) || {};

  if (!success) {
    return enqueueNotification(getProjectErrorConfig([payload.model], 'archive', message));
  }

  return null;
}

async function performUnarchiveProject(
  apolloClient: ApolloClient<any>,
  payload: UnarchiveProjectPayload,
) {
  const result = await apolloClient
    .mutate<UnarchiveProjectDocumentNode, { input: UnarchiveProjectInput }>({
      mutation: unarchiveProjectMutation,
      variables: {
        input: {id: payload.model.id},
      },
    });

  const {success, message} = getUnarchiveProjectResult(result) || {};

  if (!success) {
    return enqueueNotification(getProjectErrorConfig([payload.model], 'restore', message));
  }

  return null;
}

async function performArchiveProjectBatch(
  apolloClient: ApolloClient<any>,
  payload: ArchiveProjectBatchPayload,
) {
  const result = await apolloClient
    .mutate<ArchiveProjectBatchDocumentNode, { input: ArchiveProjectBatchInput }>({
      mutation: archiveProjectBatchMutation,
      variables: {
        input: {targets: payload.models.map(it => it.id)},
      },
    });

  const {responses} = getArchiveProjectBatchResult(result) || {};

  const failureData = getProjectBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getProjectErrorConfig(failureData.models, 'archive', failureData.message));
  }

  return null;
}

async function performUnarchiveProjectBatch(
  apolloClient: ApolloClient<any>,
  payload: UnarchiveProjectBatchPayload,
) {
  const result = await apolloClient
    .mutate<UnarchiveProjectBatchDocumentNode, { input: UnarchiveProjectBatchInput }>({
      mutation: unarchiveProjectBatchMutation,
      variables: {
        input: {targets: payload.models.map(it => it.id)},
      },
    });

  const {responses} = getUnarchiveProjectBatchResult(result) || {};

  const failureData = getProjectBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getProjectErrorConfig(failureData.models, 'restore', failureData.message));
  }

  return null;
}
