import {IRecycledFileNodeFragmentData} from '@app-features/file-recycle-bin/types';
import {UploadManagerStartUploadPayload} from '@app-features/file-tree/redux/upload-manager/actions';
import {JobFragmentData} from '@app-features/jobs/types';
import {MilestoneFragmentData} from '@app-features/milestones/types';
import {FileNodeStatus} from '@app-lib/apollo/apiTypes';
import {
  BatchFailureData,
  FilePlaceholder,
  FileUpload,
} from '@app-lib/apollo/localTypes';
import {getListId} from '@app-lib/collections/utils';
import {
  buildErrorNotification,
  ErrorNotificationProps,
} from '@app-system/notifications/components/ErrorNotification';
import {
  Collections as CollectionsIcon,
  Folder as FolderIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from '@material-ui/icons';
import {find} from 'lodash';
import uuidv4 from 'uuid/v4';
import {IFileNodeFragmentData} from '../types';


export interface FileNodeBatchUpdateResponse {
  code: string
  success: boolean
  message: string
  fileNodeId: string
}

export type ActionNamesForFileNodeStatus =
  | "cancel"
  | "reset to to do"
  | "submit"
  | "approve"
  | "reject"

export type UFileNodeProps =
  | Pick<IFileNodeFragmentData, "__typename" | "id" | "name">
  | Pick<IRecycledFileNodeFragmentData, "__typename" | "id" | "name">

export function getActionNameForFileNodeStatus(status: FileNodeStatus): ActionNamesForFileNodeStatus {
  switch (status) {
    case FileNodeStatus.Na:
      return 'cancel';
    case FileNodeStatus.Todo:
      return 'reset to to do';
    case FileNodeStatus.Done:
      return 'submit';
    case FileNodeStatus.Approved:
      return 'approve';
    case FileNodeStatus.Rejected:
      return 'reject';
  }
}

export function getFileNodeBatchFailures(
  models: Array<UFileNodeProps>,
  responses: Array<FileNodeBatchUpdateResponse> | null | undefined,
): BatchFailureData<UFileNodeProps> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.fileNodeId)
    ?.forEach(response => successes.add(response.fileNodeId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getFileNodeErrorConfig(
  models: Array<Pick<MilestoneFragmentData, "__typename" | "id" | "name">
    | Pick<JobFragmentData, "__typename" | "id" | "name" | "folderId">
    | UFileNodeProps | FilePlaceholder | FileUpload>,
  action:
    | "download"
    | "lock"
    | "unlock"
    | ActionNamesForFileNodeStatus
    | "move"
    | "update"
    | "remove"
    | "recycle"
    | "delete",
  message?: string | null,
) {
  const {type, icon} = getErrorConfig(models[0]);
  const remain = models.length - 1;
  return {
    message: `Failed to ${action} ${type}${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${getListId(models[0])}`,
      persist: true,
      content: buildErrorNotification({
        icon,
        error: message || `Something went wrong.`,
        name: models[0].name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}

export function getFileUploadErrorConfig(
  models: Array<FileUpload | UploadManagerStartUploadPayload>,
  action:
    | "upload",
  message?: string | null,
) {
  const remain = models.length - 1;
  const model = models[0] || {
    mutationId: uuidv4(),
    name: 'Error',
  };
  return {
    message: `Failed to ${action} File${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${model.mutationId}`,
      persist: true,
      // TODO: build a custom component for upload errors with retry capability build in
      //  refactor upload manager item to work in both places
      content: buildErrorNotification({
        icon: InsertDriveFileIcon,
        error: message || `Something went wrong.`,
        name: model.name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}

function getErrorConfig(
  model: Pick<MilestoneFragmentData, "__typename" | "id" | "name">
    | Pick<JobFragmentData, "__typename" | "id" | "name" | "folderId">
    | UFileNodeProps | FilePlaceholder | FileUpload,
): { type: string, icon: ErrorNotificationProps["icon"] } {
  let type;
  let icon;
  switch (model.__typename) {
    case 'Job':
    case 'Milestone':
    case 'RecycledFolder':
    case 'Folder':
      type = 'Folder';
      icon = FolderIcon;
      break;
    case 'RecycledFileContainer':
    case 'FileContainer':
      type = 'File Container';
      icon = CollectionsIcon;
      break;
    case 'FilePlaceholder':
      type = 'Placeholder';
      icon = CollectionsIcon;
      break;
    case 'RecycledFileSubmission':
    case 'RecycledFile':
    case 'FileSubmission':
    case 'File':
    case 'FileUpload':
      type = 'File';
      icon = InsertDriveFileIcon;
      break;
  }
  return {type, icon};
}
