import {LocalBroadcastChannel} from '@app-system/messengers/LocalBroadcastChannel';
import {
  Action,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from 'redux';


export interface BroadcastChannelMiddleware<S, A extends Action> extends Middleware<{}, S> {
}

export function createBroadcastChannelMiddleware<S, A extends Action>(
  channel: LocalBroadcastChannel<A>,
  isWhitelisted: (action: A) => boolean,
): BroadcastChannelMiddleware<S, A> {
  const middleWare: BroadcastChannelMiddleware<S, A> = (store: MiddlewareAPI<Dispatch, S>) => (next) => (action: A) => {
    const result = next(action);
    // @ts-ignore - action meta not supported by types yet
    const meta = action.meta || {};

    if (isWhitelisted(action)) {
      const local = {
        ...action,
        meta: {
          ...meta,
          local: true,
        },
      };
      // @ts-ignore
      channel.emitter.emit('message', local);
      channel.channel.postMessage({
        ...action,
        meta: {
          ...meta,
          local: false,
        },
      });
    }

    return result;
  };

  return middleWare;
}
