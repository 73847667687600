import {ApolloClient} from '@apollo/client';
import {getDataFromTree} from '@apollo/client/react/ssr';
import {AppActions} from '@app-lib/redux/actions';
import {RootAppState} from '@app-lib/redux/reducers';
import {initLogging} from '@app-system/logging';
import Head from 'next/head';
import React, {Component} from 'react';
import {Store} from 'redux';
import {createApolloClient} from './apollo/apolloClient';
import {initRedux} from './initRedux';
import {initStore} from './redux';
import {
  buildClientInitialState,
  buildServerInitialState,
} from './redux/initServerState';
import {
  AppServices,
  buildServices,
  initServices,
  postInitOnClient,
} from './services';


const defaultConfig = {
  debug: false,
  serializeState: state => state,
  deserializeState: state => state,
};

let apolloClient: ApolloClient<any> | null = null;

function initApolloClient(initialState, services) {
  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState, null, services);
  }

  return apolloClient;
}

interface Props {
  initialProps;
  initialState;
  [key: string]: any;
}

// Integrate withApollo and withRedux
// withApollo based on example.
// withRedux based on https://github.com/kirill-konshin/next-redux-wrapper v3.0.0-alpha.0
export const withApolloAndRedux = App => {
  const isServer = typeof window === 'undefined';
  return class Apollo extends Component<Props> {
    /* istanbul ignore next */
    static displayName = `withApolloAndRedux(${App.displayName || App.name || 'App'})`;

    static async getInitialProps(appCtx) {
      /* istanbul ignore next */
      if (!appCtx) throw new Error('No app context');
      /* istanbul ignore next */
      if (!appCtx.ctx) throw new Error('No page context');

      const {Component, router} = appCtx;

      initLogging();

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const services = isServer ? buildServices(appCtx.ctx) : initServices(null);
      const apollo = createApolloClient(null, appCtx.ctx, services);
      apolloClient = apollo;
      const store = initRedux({
        initialState: defaultConfig.serializeState(buildServerInitialState(appCtx.ctx)),
        ctx: appCtx.ctx,
        makeStore: initStore,
        config: {
          ...defaultConfig,
          dependencies: services,
        },
      });
      services.apolloClient = apollo;
      services.store = store;

      let appProps = {};
      if (App.getInitialProps) {
        appCtx.ctx.services = services;
        appProps = await App.getInitialProps(appCtx);
      }

      let initialState;

      if (isServer) {
        try {
          // Run all GraphQL queries
          await getDataFromTree(
            <App
              {...appProps}
              Component={Component}
              router={router}
              apolloClient={apollo}
              store={store}
            />
          );

          // get updated state. only serialize it on the server.
          initialState = defaultConfig.serializeState(store.getState());
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          console.error('Error while running `getDataFromTree`', error);
        }

        // getDataFromTree does not call componentWillUnmount
        // head side effect therefore need to be cleared manually
        Head.rewind();
      } else {
        initialState = store.getState();
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract();
      const tenantId = services.tenantService.getTenantId();

      return {
        ...appProps,
        initialState,
        apolloState,
        tenantId,
      };
    }

    public apolloClient: ApolloClient<any>;
    public store: Store<RootAppState, AppActions>;
    public services: AppServices;

    constructor(props) {
      super(props);
      const {initialState} = props;

      initLogging();

      const services = initServices(props);
      this.apolloClient = initApolloClient(props.apolloState, services);
      this.store = initRedux({
        initialState: initialState ? initialState : buildClientInitialState(),
        makeStore: initStore,
        config: {
          ...defaultConfig,
          // Ensure referential integrity of the services object
          dependencies: services,
        },
      });
      services.apolloClient = this.apolloClient;
      services.store = this.store;
      this.services = services;

      if (typeof window !== 'undefined') {
        postInitOnClient(services);
      }
    }

    render() {
      const {initialProps, initialState, ...props} = this.props;
      return (
        <App
          {...props}
          {...initialProps}
          apolloClient={this.apolloClient}
          store={this.store}
          services={this.services}
        />
      );
    }
  };
};
