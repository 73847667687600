import {BroadcastChannel} from 'broadcast-channel';
import EventEmitter from 'eventemitter3';


export class LocalBroadcastChannel<E extends {}> {
  readonly channel: BroadcastChannel<E>;
  readonly emitter: EventEmitter<{ message: E }>

  constructor(name: string, {webWorkerSupport}: { webWorkerSupport?: boolean } = {}) {
    this.channel = new BroadcastChannel(name, {
      webWorkerSupport: !!webWorkerSupport,
    });

    this.emitter = new EventEmitter();
  }

  postMessage(action: E) {
    // @ts-ignore
    this.emitter.emit('message', action);
    this.channel.postMessage(action);
  }

  addEventListener(type: 'message', listener: (action: E) => void) {
    // @ts-ignore
    this.emitter.on('message', listener);
    this.channel.addEventListener('message', listener);
  }

  removeEventListener(type: 'message', listener: (action: E) => void) {
    // @ts-ignore
    this.emitter.off('message', listener);
    this.channel.removeEventListener('message', listener);
  }
}
