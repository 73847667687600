import {gql} from '@apollo/client';
import {ITemplate} from '@app-lib/apollo/apiTypes';
import {
  ApplicationData,
  UserData,
} from '@app-lib/apollo/localTypes';
import {SourceDirectory} from '@jcl/core';


export interface TemplatePermissions {
  template_data_create?: boolean | null;
  template_data_read?: boolean | null;
  template_data_update?: boolean | null;
  template_data_delete?: boolean | null;
}

export interface TemplateRepositoryFragmentData {
  __typename: "TemplateRepository";
  id: string;
  organizationId: string;
  displayName: string;
  moduleName: string;
  description?: string | null;
  source?: string | null;
  public: boolean;
  latest?: {
    __typename: "TemplateCommit";
    id: string;
    version: string | null;
    checksum: string;
  }
  draft?: SourceDirectory | null;
  createdAt: string;
  createdBy: UserData | ApplicationData;

  commits: {
    total: number;
  }
}

export interface TemplateRepositoryWithLatestFragmentData extends TemplateRepositoryFragmentData {
  latest?: TemplateCommitFragmentData;
}

export interface TemplateCommitFragmentData {
  __typename: "TemplateCommit";
  id: string;
  repositoryId: string;
  version: string | null;
  checksum: string;
  message: string;
  fs: SourceDirectory;
  createdAt: string;
  createdBy: UserData | ApplicationData;
}

export interface ITemplateFragmentData {
  __typename: "ProjectTemplate" | "JobTemplate";
  id: string;
  name: string;
  source: string;
  version: string | null;
  checksum: string;
  description: string | null;
  fs: ITemplate["fs"];
}

// Special notes:
//  the commits connection total must always be fetched manually.
//    not included here so that fetching more of the connection is not made more complicated.
//  the latest data must also be included manually so that it doesn't interfere with fetching additional data.
export const TemplateRepositoryFragment = gql`
  fragment TemplateRepositoryFragment on TemplateRepository {
    __typename
    id
    organizationId
    displayName
    moduleName
    description
    source
    public
    draft
    createdAt(dateFormat: $dateFormat)

    createdBy {
      __typename
      id
      name

      ... on User {
        email
        avatar
      }
    }
  }
`;

export const TemplateCommitFragment = gql`
  fragment TemplateCommitFragment on TemplateCommit {
    __typename
    id
    repositoryId
    version
    checksum
    message
    fs
    createdAt(dateFormat: $dateFormat)

    createdBy {
      __typename
      id
      name

      ... on User {
        email
        avatar
      }
    }
  }
`;
