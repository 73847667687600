import {ProjectIcon} from '@app-components/icons/ProjectIcon';
import {ProjectStatus} from '@app-lib/apollo/apiTypes';
import {BatchFailureData} from '@app-lib/apollo/localTypes';
import {buildErrorNotification} from '@app-system/notifications/components/ErrorNotification';
import {find} from 'lodash';
import {ProjectFragmentData} from '../types';


export interface ProjectBatchUpdateResponse {
  code: string
  success: boolean
  message: string
  projectId: string
}

export type ActionNamesForProjectStatus =
  | "reset to planned"
  | "complete"
  | "cancel"
  | "start"

export function getActionNameForProjectStatus(status: ProjectStatus): ActionNamesForProjectStatus {
  switch (status) {
    case ProjectStatus.Planned:
      return 'reset to planned';
    case ProjectStatus.Complete:
      return 'complete';
    case ProjectStatus.Cancelled:
      return 'cancel';
    case ProjectStatus.Active:
      return 'start';
  }
}

export function getProjectBatchFailures(
  models: Array<Pick<ProjectFragmentData, "__typename" | "id" | "name">>,
  responses: Array<ProjectBatchUpdateResponse> | null | undefined,
): BatchFailureData<Pick<ProjectFragmentData, "__typename" | "id" | "name">> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.projectId)
    ?.forEach(response => successes.add(response.projectId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getProjectErrorConfig(
  models: Array<Pick<ProjectFragmentData, "__typename" | "id" | "name">>,
  action:
    | "archive"
    | "restore"
    | ActionNamesForProjectStatus
    | "update"
    | "delete",
  message?: string | null,
) {
  const remain = models.length - 1;
  return {
    message: `Failed to ${action} Project${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${models[0].id}`,
      persist: true,
      content: buildErrorNotification({
        icon: ProjectIcon,
        error: message || `Something went wrong.`,
        name: models[0].name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}
