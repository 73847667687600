import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {noop} from 'lodash';
import LogRocket from 'logrocket';
import {Observable} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  tap,
} from 'rxjs/operators';
import {
  AuthActions,
  logout,
  logoutCleanup,
  storeUser,
} from './actions';


export const epics = [
  onLogout,
  onLogoutCleanup,
  onStoreUser,
];

export function onLogout(
  action$: Observable<AuthActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === logout.type),
    tap(async (action: ReturnType<typeof logout>) => {
      if (dependencies.messengers) {
        dependencies.messengers.reduxChannel.postMessage(JSON.stringify(logoutCleanup({local: false})));
      }
      await swallowError(() => window.location.assign('/api/logout'));
    }),
    map(() => logoutCleanup({local: true})),
    filter(filterOnAction),
  );
}

export function onLogoutCleanup(
  action$: Observable<AuthActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === logoutCleanup.type),
    tap(async ({payload}: ReturnType<typeof logoutCleanup>) => {
      await swallowError(() => dependencies.tenantService.clearTenant());
      await swallowError(() => dependencies.preferencesService.clearSettings());
      await swallowError(() => dependencies.apolloClient.clearStore());
      if (!payload.local) {
        await swallowError(() => window.location.assign('/goodbye'));
      }
    }),
    map(noop),
    filter(filterOnAction),
  );
}

export function onStoreUser(action$: Observable<AuthActions>) {
  return action$.pipe(
    filter(action => action.type === storeUser.type),
    distinctUntilChanged((a: ReturnType<typeof storeUser>, b: ReturnType<typeof storeUser>) =>
      a.payload.id === b.payload.id
    ),
    tap(({payload}: ReturnType<typeof storeUser>) => {
      try {
        LogRocket.identify(payload.id, {
          name: payload.name,
          email: payload.email,
        });
      } catch (e) {
      }
    }),
    map(noop),
    filter(filterOnAction),
  );
}

async function swallowError(fn) {
  try {
    return await fn();
  } catch (e) {
    console.error(e);
  }
}
