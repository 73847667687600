import {gql} from '@apollo/client';
import {TemplateBatchUpdateResponse} from '@app-features/template-repositories/redux/utils';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {ProjectBatchUpdateResponse} from '../utils';


export interface DeleteProjectDocumentNode {
  deleteProject?: ClientMutationResponse
}

export interface DeleteProjectBatchDocumentNode {
  deleteProjectBatch?: ClientMutationResponse & {
    responses?: Array<ProjectBatchUpdateResponse>
  }
}

export interface DeleteProjectTemplateDocumentNode {
  deleteProjectTemplate?: ClientMutationResponse
}

export interface DeleteProjectTemplateBatchDocumentNode {
  deleteProjectTemplateBatch?: ClientMutationResponse & {
    responses?: Array<TemplateBatchUpdateResponse>
  }
}

export const deleteProjectMutation = gql`
  mutation deleteProject($input: DeleteProjectInput!) {
    deleteProject(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const deleteProjectBatchMutation = gql`
  mutation deleteProjectBatch($input: DeleteProjectBatchInput!) {
    deleteProjectBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        projectId
      }
    }
  }
`;

export const deleteProjectTemplateMutation = gql`
  mutation deleteProjectTemplate($input: DeleteProjectTemplateInput!) {
    deleteProjectTemplate(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const deleteProjectTemplateBatchMutation = gql`
  mutation deleteProjectTemplateBatch($input: DeleteProjectTemplateBatchInput!) {
    deleteProjectTemplateBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        templateId
      }
    }
  }
`;

export function getDeleteProjectResult(
  response: { data?: DeleteProjectDocumentNode | null },
): DeleteProjectDocumentNode["deleteProject"] {
  return get(response, 'data.deleteProject', null);
}

export function getDeleteProjectBatchResult(
  response: { data?: DeleteProjectBatchDocumentNode | null },
): DeleteProjectBatchDocumentNode["deleteProjectBatch"] {
  return get(response, 'data.deleteProjectBatch', null);
}

export function getDeleteProjectTemplateResult(
  response: { data?: DeleteProjectTemplateDocumentNode | null },
): DeleteProjectTemplateDocumentNode["deleteProjectTemplate"] {
  return get(response, 'data.deleteProjectTemplate', null);
}

export function getDeleteProjectTemplateBatchResult(
  response: { data?: DeleteProjectTemplateBatchDocumentNode | null },
): DeleteProjectTemplateBatchDocumentNode["deleteProjectTemplateBatch"] {
  return get(response, 'data.deleteProjectTemplateBatch', null);
}
