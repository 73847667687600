import {gql} from '@apollo/client';
import {IFileNodeFragmentData} from '@app-features/file-tree/types';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {FileNodeBatchUpdateResponse} from '../utils';


export type IFileNodeLockUpdate = Pick<IFileNodeFragmentData,
  | "__typename"
  | "id"
  | "locked"
  | "lockedBy">

export interface LockFileNodeDocumentNode {
  lockFileNode?: ClientMutationResponse & {
    fileNode?: IFileNodeLockUpdate
  }
}

export interface UnlockFileNodeDocumentNode {
  unlockFileNode?: ClientMutationResponse & {
    fileNode?: IFileNodeLockUpdate
  }
}

export interface LockFileNodeBatchDocumentNode {
  lockFileNodeBatch?: ClientMutationResponse & {
    responses?: Array<FileNodeBatchUpdateResponse>
    fileNodes?: Array<IFileNodeLockUpdate>
  }
}

export interface UnlockFileNodeBatchDocumentNode {
  unlockFileNodeBatch?: ClientMutationResponse & {
    responses?: Array<FileNodeBatchUpdateResponse>
    fileNodes?: Array<IFileNodeLockUpdate>
  }
}

export const lockFileNodeMutation = gql`
  mutation lockFileNode($input: LockFileNodeInput!) {
    lockFileNode(input: $input) {
      clientMutationId
      code
      success
      message
      fileNode {
        __typename
        id
        locked
        lockedBy {
          __typename
          id
          name
          parentId
          path
        }
      }
    }
  }
`;

export const unlockFileNodeMutation = gql`
  mutation unlockFileNode($input: UnlockFileNodeInput!) {
    unlockFileNode(input: $input) {
      clientMutationId
      code
      success
      message
      fileNode {
        __typename
        id
        locked
        lockedBy {
          __typename
          id
          name
          parentId
          path
        }
      }
    }
  }
`;

export const lockFileNodeBatchMutation = gql`
  mutation lockFileNodeBatch($input: LockFileNodeBatchInput!) {
    lockFileNodeBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        fileNodeId
      }
      fileNodes {
        __typename
        id
        locked
        lockedBy {
          __typename
          id
          name
          parentId
          path
        }
      }
    }
  }
`;

export const unlockFileNodeBatchMutation = gql`
  mutation unlockFileNodeBatch($input: UnlockFileNodeBatchInput!) {
    unlockFileNodeBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        fileNodeId
      }
      fileNodes {
        __typename
        id
        locked
        lockedBy {
          __typename
          id
          name
          parentId
          path
        }
      }
    }
  }
`;

export function getLockFileNodeResult(
  response: { data?: LockFileNodeDocumentNode | null },
): LockFileNodeDocumentNode["lockFileNode"] {
  return get(response, 'data.lockFileNode', null);
}

export function getUnlockFileNodeResult(
  response: { data?: UnlockFileNodeDocumentNode | null },
): UnlockFileNodeDocumentNode["unlockFileNode"] {
  return get(response, 'data.unlockFileNode', null);
}

export function getLockFileNodeBatchResult(
  response: { data?: LockFileNodeBatchDocumentNode | null },
): LockFileNodeBatchDocumentNode["lockFileNodeBatch"] {
  return get(response, 'data.lockFileNodeBatch', null);
}

export function getUnlockFileNodeBatchResult(
  response: { data?: UnlockFileNodeBatchDocumentNode | null },
): UnlockFileNodeBatchDocumentNode["unlockFileNodeBatch"] {
  return get(response, 'data.unlockFileNodeBatch', null);
}
