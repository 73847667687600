import {ApolloClient} from '@apollo/client';
import {RecycleFileNodeBatchInput} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getFileNodeBatchFailures,
  getFileNodeErrorConfig,
} from '../utils';
import {
  RecycleFileNodesActions,
  recycledFileNodeBatch,
  recycleFileNodeBatch,
  RecycleFileNodeBatchPayload,
} from './actions';
import {
  getRecycleFileNodeBatchResult,
  RecycleFileNodeBatchDocumentNode,
  recycleFileNodeBatchMutation,
} from './graphql';


export const epics = [
  onRecycleFileNodeBatch,
];

export function onRecycleFileNodeBatch(
  action$: Observable<RecycleFileNodesActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === recycleFileNodeBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof recycleFileNodeBatch>) => {
      try {
        return await performRecycleFileNodeBatch(dependencies.apolloClient, payload);
      } catch (e) {
        console.error(e);
        return enqueueNotification(getFileNodeErrorConfig(payload.models, 'recycle', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performRecycleFileNodeBatch(
  apolloClient: ApolloClient<any>,
  payload: RecycleFileNodeBatchPayload,
) {
  const result = await apolloClient
    .mutate<RecycleFileNodeBatchDocumentNode, { input: RecycleFileNodeBatchInput }>({
      mutation: recycleFileNodeBatchMutation,
      variables: {
        input: {targets: payload.models.map(it => it.id)},
      },
    });

  const {responses} = getRecycleFileNodeBatchResult(result) || {};

  const failureData = getFileNodeBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getFileNodeErrorConfig(failureData.models, 'recycle', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return recycledFileNodeBatch(payload);
  }
}
