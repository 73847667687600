import {fileAccessEpics} from '@app-features/file-access/redux/epics';
import {fileRecycleBinEpics} from '@app-features/file-recycle-bin/redux/epics';
import {fileTreeEpics} from '@app-features/file-tree/redux/epics';
import {jobsEpics} from '@app-features/jobs/redux/epics';
import {milestonesEpics} from '@app-features/milestones/redux/epics';
import {projectsEpics} from '@app-features/projects/redux/epics';
import {teamsEpics} from '@app-features/teams/redux/epics';
import {jobTemplatesEpics} from '@app-features/template-repositories/redux/epics';
import {usersEpics} from '@app-features/users/redux/epics';
import {epics as authEpics} from '@app-system/auth/redux/epic';
import {notificationsEpics} from '@app-system/notifications/redux/epic';
import {epics as preferencesEpics} from '@app-system/preferences/redux/epic';
import {epics as recentAccessEpics} from '@app-system/recent-access/redux/epics';
import {routerEpics} from '@app-system/router/redux/epics';
import {epics as tenantEpics} from '@app-system/tenant/redux/epic';
import {epics as uploadManagerEpics} from '@app-system/upload-manager/redux/epic';
import {combineEpics} from 'redux-observable';


export const rootEpic = combineEpics(
  ...fileAccessEpics,
  ...fileRecycleBinEpics,
  ...fileTreeEpics,
  ...jobTemplatesEpics,
  ...jobsEpics,
  ...milestonesEpics,
  ...projectsEpics,
  ...teamsEpics,
  ...usersEpics,
  ...authEpics,
  ...notificationsEpics,
  ...preferencesEpics,
  ...recentAccessEpics,
  ...routerEpics,
  ...tenantEpics,
  ...uploadManagerEpics,
);
