import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {buildErrorNotification} from '@app-system/notifications/components/ErrorNotification';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Sync as SyncIcon} from '@material-ui/icons';
import {
  defer,
  merge,
  Observable,
  timer,
} from 'rxjs';
import {
  filter,
  mapTo,
  mergeMap,
  take,
} from 'rxjs/operators';
import uuidv4 from 'uuid/v4';
import {
  pauseUploadQueue,
  resumeUploadQueue,
  saveFilesFromUploadQueue,
  startUploadQueue,
  UploadQueueActions,
} from './actions';


const TimeoutDuration = 60000;

export const epics = [
  onStartUploadQueue,
  onPauseUploadQueue,
  onResumeUploadQueue,
  onSaveFilesFromUploadQueue,
];

export function onStartUploadQueue(
  action$: Observable<UploadQueueActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === startUploadQueue.type),
    mergeMap(() =>
      merge(
        defer(() => handleDbError('start', async () =>
          dependencies.fileUploadQueueService.startSync()
            .then(started => started ? null : buildErrorAction('start'))
        )),
        // Timeout in case there is any issue with the service work registration
        timer(TimeoutDuration)
          .pipe(mapTo(buildErrorAction('sw'))),
      ).pipe(
        take(1),
      )
    ),
    filter(filterOnAction),
  );
}

export function onPauseUploadQueue(
  action$: Observable<UploadQueueActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === pauseUploadQueue.type),
    mergeMap(() =>
      merge(
        defer(() => handleDbError('pause', async () =>
          dependencies.fileUploadQueueService.queue.pause().then(() => null)
        )),
        // Timeout in case there is any issue with the service work registration
        timer(TimeoutDuration)
          .pipe(mapTo(buildErrorAction('sw'))),
      ).pipe(
        take(1),
      )
    ),
    filter(filterOnAction),
  );
}

export function onResumeUploadQueue(
  action$: Observable<UploadQueueActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === resumeUploadQueue.type),
    mergeMap(() =>
      merge(
        defer(() => handleDbError('resume', async () =>
          dependencies.fileUploadQueueService.queue.resume().then(() => null)
        )),
        // Timeout in case there is any issue with the service work registration
        timer(TimeoutDuration)
          .pipe(mapTo(buildErrorAction('sw'))),
      ).pipe(
        take(1),
      )
    ),
    filter(filterOnAction),
  );
}

export function onSaveFilesFromUploadQueue(
  action$: Observable<UploadQueueActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === saveFilesFromUploadQueue.type),
    mergeMap(async ({payload}: ReturnType<typeof saveFilesFromUploadQueue>) =>
      await handleDbError('download', async () => {
        await dependencies.fileUploadQueueService.downloadFiles(payload.options);
        return null;
      })
    ),
    filter(filterOnAction),
  );
}

type DbErrorAction =
  | 'start'
  | 'pause'
  | 'resume'
  | 'sw'
  | 'download'

export async function handleDbError<R>(
  action: DbErrorAction,
  cb: () => Promise<R>,
): Promise<R | ReturnType<typeof enqueueNotification>> {
  try {
    return await cb();
  } catch (e) {
    console.error(e);
    return buildErrorAction(action);
  }
}

function buildErrorAction(action: DbErrorAction) {
  let name, error;
  switch (action) {
    case 'pause':
    case 'resume':
    case 'start':
      name = 'Storage Error';
      error = 'There was an error connecting to the local database. Please ensure your browser permissions allow access to IndexedDB or Storage.';
      break;
    case 'download':
      name = 'Storage Error';
      error = 'Failed to retrieve and save files.';
      break;
    case 'sw':
      name = 'App Error';
      error = 'There was an error connecting to the queue service worker. Please close all tabs and reopen the app before trying again.';
      break;
  }

  return enqueueNotification({
    message: `Failed to ${action} Uploads`,
    options: {
      key: uuidv4(),
      persist: true,
      content: buildErrorNotification({
        icon: SyncIcon,
        error,
        name,
      }),
    },
  });
}
