import {AppServices} from '@app-lib/services';
import EventEmitter from 'eventemitter3';
import {Workbox} from 'workbox-window';


export enum ServiceWorkerEvents {
  Registered = 'Registered',
}

export interface ServiceWorkerRegisteredEvent {
  wb: Workbox;
  reg: ServiceWorkerRegistration;
}

export class ServiceWorkerService extends EventEmitter<{ [ServiceWorkerEvents.Registered]: (ev: ServiceWorkerRegisteredEvent) => void }> {
  protected readonly isServer: boolean;
  private wb?: Workbox;
  private reg?: ServiceWorkerRegistration;

  constructor(protected services: AppServices) {
    super();
    this.isServer = typeof window === 'undefined';

    if (!this.isServer) {
      this.initOnClient();
    }
  }

  onSWRegistered(cb: (e: ServiceWorkerRegisteredEvent) => void) {
    if (!this.isServer) {
      if (this.wb && this.reg) {
        cb({wb: this.wb, reg: this.reg});
      }
      this.on(ServiceWorkerEvents.Registered, cb);
    }
  }

  private initOnClient() {
    this.registerSW();

    if (typeof module !== 'undefined' && module.hot) {
      module.hot.accept('../../../lib/withApolloAndRedux', () => {
        this.registerSW();
      });
    }
  }

  private async registerSW() {
    console.info('ServiceWorker: registering');
    if ("serviceWorker" in navigator) {
      try {
        this.wb = new Workbox("/sw.js", {scope: "/"});

        this.wb.addEventListener('activated', async (event) => {
          console.info('sw actived and controls page?', {
            sw: navigator.serviceWorker.controller,
          });
          try {
            const reg = await navigator.serviceWorker.ready;
            this.reg = reg;
            if (this.wb) {
              this.emit(ServiceWorkerEvents.Registered, {wb: this.wb, reg});
            }
          } catch (e) {
          }
        });

        const reg = await this.wb.register();
        if (reg) {
          this.reg = reg;
          this.emit(ServiceWorkerEvents.Registered, {wb: this.wb, reg});
        }

      } catch (e) {
        console.warn('ServiceWorker: failed to register');
        console.error(e);
      }
    } else {
      console.warn('ServiceWorker: not supported');
    }
  }
}
