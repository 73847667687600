import {
  ApolloCache,
  ApolloError,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import {typePolicies} from './cache';
import introspectionQueryResultData from './fragmentTypes.json';


export interface QueryError {
  error: Error | ApolloError,
}

export function buildApolloClientCache(initialState?): ApolloCache<NormalizedCacheObject> {
  return new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
    dataIdFromObject,
    typePolicies,
  })
    .restore(initialState || {});
}

export function dataIdFromObject(o) {
  return o ? o.id || null : null;
}

/* Only use as a last resort. Don't rely on id format. */
export function getTypeFromGlobalId(id: string): string | undefined {
  try {
    return atob(id).split(':')[0];
  } catch (e) {
    // id not in supported format. return undefined
  }
}
