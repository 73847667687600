import {ProjectFragmentData} from '@app-features/projects/types';
import {ProjectStatus} from '@app-lib/apollo/apiTypes';
import {createAction} from 'redux-starter-kit';


export interface UpdateProjectStatusBatchPayload {
  models: Array<Pick<ProjectFragmentData, "__typename" | "id" | "name">>
  status: ProjectStatus
  skipIfDone?: boolean
  skipIfCancelled?: boolean
}

export type ProjectStatusUpdateActions =
  | ReturnType<typeof updateProjectStatusBatch>

export const updateProjectStatusBatch = createAction<UpdateProjectStatusBatchPayload, "projects/updateProjectStatusBatch">("projects/updateProjectStatusBatch");
