import {AppActions} from '@app-lib/redux/actions';
import {RootAppState} from '@app-lib/redux/reducers';
import {NextPageContext} from 'next';
import {Store} from 'redux';


export interface InitReduxArgs {
  initialState?,
  ctx?: NextPageContext,
  config,
  makeStore: (initialState?: Partial<RootAppState>, ctx?: any) => Store<RootAppState, AppActions>,
}

let reduxStore: Store<RootAppState, AppActions> | null = null;

export const initRedux = ({initialState, ctx, config, makeStore}: InitReduxArgs): Store<RootAppState, AppActions> => {
  if (typeof window === 'undefined') {
    const {deserializeState} = config;

    return makeStore(deserializeState(initialState), {
      ...ctx,
      ...config,
    });
  }

  if (!reduxStore) {
    return reduxStore = makeStore(initialState, {
      ...ctx,
      ...config,
    });
  } else {
    return reduxStore;
  }
};
