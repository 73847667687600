import {
  JobFragmentData,
  JobTemplateFragmentData,
} from '@app-features/jobs/types';
import {createAction} from 'redux-starter-kit';


export interface DeleteJobPayload {
  model: JobFragmentData
  recycleFolder: boolean
}

export interface DeleteJobBatchPayload {
  models: Array<JobFragmentData>
  recycleFolders: boolean
}

export interface DeleteJobTemplatePayload {
  model: JobTemplateFragmentData;
}

export interface DeleteJobTemplateBatchPayload {
  models: Array<JobTemplateFragmentData>;
}

export type DeleteJobActions =
  | ReturnType<typeof deleteJob>
  | ReturnType<typeof deletedJob>
  | ReturnType<typeof deleteJobBatch>
  | ReturnType<typeof deletedJobBatch>
  | ReturnType<typeof deleteJobTemplate>
  | ReturnType<typeof deletedJobTemplate>
  | ReturnType<typeof deleteJobTemplateBatch>
  | ReturnType<typeof deletedJobTemplateBatch>

export const deleteJob = createAction<DeleteJobPayload, "jobs/deleteJob">("jobs/deleteJob");
export const deletedJob = createAction<DeleteJobPayload, "jobs/deletedJob">("jobs/deletedJob");
export const deleteJobBatch = createAction<DeleteJobBatchPayload, "jobs/deleteJobBatch">("jobs/deleteJobBatch");
export const deletedJobBatch = createAction<DeleteJobBatchPayload, "jobs/deletedJobBatch">("jobs/deletedJobBatch");
export const deleteJobTemplate = createAction<DeleteJobTemplatePayload, "jobs/deleteJobTemplate">("jobs/deleteJobTemplate");
export const deletedJobTemplate = createAction<DeleteJobTemplatePayload, "jobs/deletedJobTemplate">("jobs/deletedJobTemplate");
export const deleteJobTemplateBatch = createAction<DeleteJobTemplateBatchPayload, "jobs/deleteJobTemplateBatch">("jobs/deleteJobTemplateBatch");
export const deletedJobTemplateBatch = createAction<DeleteJobTemplateBatchPayload, "jobs/deletedJobTemplateBatch">("jobs/deletedJobTemplateBatch");
