import {ApolloClient} from '@apollo/client';
import {AppActions} from '@app-lib/redux/actions';
import {RootAppState} from '@app-lib/redux/reducers';
import {FeatureDetectionService} from '@app-system/feature-detection/services/FeatureDetectionService';
import {
  AppMessengers,
  initMessengers,
} from '@app-system/messengers';
import {postInitOnClient as postInitOnClientPreferences} from '@app-system/preferences/redux/initServerState';
import {PreferencesService} from '@app-system/preferences/services/PreferencesService';
import {ServiceWorkerService} from '@app-system/sw/services/ServiceWorkerService';
import {TenantService} from '@app-system/tenant/services/TenantService';
import {FileUploadQueueService} from '@app-system/upload-manager/services/FileUploadQueueService';
import {NextPageContext} from 'next';
import {NextRouter} from 'next/router';
import {ProviderContext as NotistackProviderContext} from 'notistack';
import {Store} from 'redux';


export interface AppServices {
  ctx: NextPageContext | {};
  fileUploadQueueService: FileUploadQueueService;
  preferencesService: PreferencesService;
  serviceWorkerService: ServiceWorkerService;
  tenantService: TenantService;
  apolloClient: ApolloClient<any>;
  store: Store<RootAppState, AppActions>;
  messengers: AppMessengers;
  /** Lazily provided by a hook to get it from the notistack context provider. Client only. */
  notifier?: NotistackProviderContext;
  router?: NextRouter;
}

let services: AppServices | null = null;

export function initServices(props): AppServices {
  // Reuse client on the client-side
  if (!services) {
    services = buildServices({}, props);
  }

  return services;
}

export function buildServices(ctx: NextPageContext | {}, props?): AppServices {
  const services: any = {ctx};

  services.fileUploadQueueService = new FileUploadQueueService(services);
  services.preferencesService = new PreferencesService(services);
  services.serviceWorkerService = new ServiceWorkerService(services);
  services.tenantService = new TenantService(services, props);
  services.messengers = initMessengers(services);

  if (typeof window !== 'undefined') {
    services.fileUploadQueueService.initOnClient();
    new FeatureDetectionService(services).initOnClient();
  }

  return services as AppServices;
}

export function postInitOnClient(services: AppServices) {
  postInitOnClientPreferences(services);
}
