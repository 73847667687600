import {epics as archivalEpics} from './archival/epic';
import {epics as deletionEpics} from './deletion/epic';
import {epics as statusesEpics} from './statuses/epic';


export const jobsEpics = [
  ...archivalEpics,
  ...deletionEpics,
  ...statusesEpics,
];
