import {ApolloClient} from '@apollo/client';
import {
  DeleteFileNodeBatchInput,
  DeleteFileNodeInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getFileNodeBatchFailures,
  getFileNodeErrorConfig,
} from '../utils';
import {
  deletedFileNode,
  deletedFileNodeBatch,
  deleteFileNode,
  DeleteFileNodeActions,
  deleteFileNodeBatch,
  DeleteFileNodeBatchPayload,
  DeleteFileNodePayload,
} from './actions';
import {
  DeleteFileNodeBatchDocumentNode,
  deleteFileNodeBatchMutation,
  DeleteFileNodeDocumentNode,
  deleteFileNodeMutation,
  getDeleteFileNodeBatchResult,
  getDeleteFileNodeResult,
} from './graphql';


export const epics = [
  onDeleteFileNode,
  onDeleteFileNodeBatch,
];

export function onDeleteFileNode(
  action$: Observable<DeleteFileNodeActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteFileNode.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteFileNode>) => {
      try {
        return await performDeleteFileNode(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeErrorConfig([payload.model], 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteFileNodeBatch(
  action$: Observable<DeleteFileNodeActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteFileNodeBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteFileNodeBatch>) => {
      try {
        return await performDeleteFileNodeBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getFileNodeErrorConfig(payload.models, 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performDeleteFileNode(
  apolloClient: ApolloClient<any>,
  payload: DeleteFileNodePayload,
) {
  const result = await apolloClient
    .mutate<DeleteFileNodeDocumentNode, { input: DeleteFileNodeInput }>({
      mutation: deleteFileNodeMutation,
      variables: {
        input: {
          id: payload.model.id,
        },
      },
    });

  const {success, message} = getDeleteFileNodeResult(result) || {};

  if (!success) {
    return enqueueNotification(getFileNodeErrorConfig([payload.model], 'delete', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return deletedFileNode(payload);
  }
}

async function performDeleteFileNodeBatch(
  apolloClient: ApolloClient<any>,
  payload: DeleteFileNodeBatchPayload,
) {
  const result = await apolloClient
    .mutate<DeleteFileNodeBatchDocumentNode, { input: DeleteFileNodeBatchInput }>({
      mutation: deleteFileNodeBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => it.id),
        },
      },
    });

  const {responses} = getDeleteFileNodeBatchResult(result) || {};

  const failureData = getFileNodeBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getFileNodeErrorConfig(failureData.models, 'delete', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return deletedFileNodeBatch(payload);
  }
}
