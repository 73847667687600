import {getFileNodeErrorConfig} from '@app-features/file-tree/redux/utils';
import {
  BatchFailureData,
  FileNodeActorData,
  FileNodeMembershipData,
  TeamData,
  UserData,
} from '@app-lib/apollo/localTypes';
import {buildErrorNotification} from '@app-system/notifications/components/ErrorNotification';
import {Group as GroupIcon} from '@material-ui/icons';
import {find} from 'lodash';


export interface FileNodeMemberBatchUpdateResponse {
  code: string;
  success: boolean;
  message: string;
  memberId: string;
  fileNodeId?: string;
  actorId?: string;
}

export function getActorBatchFailures(
  models: Array<UserData | TeamData>,
  responses: Array<FileNodeMemberBatchUpdateResponse> | null | undefined,
): BatchFailureData<UserData | TeamData> | null {
  const successes = new Set<string>();
  responses
    ?.forEach(response => response.success && response.actorId && successes.add(response.actorId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getFileNodeMemberBatchFailures(
  models: Array<FileNodeActorData | FileNodeMembershipData>,
  responses: Array<FileNodeMemberBatchUpdateResponse> | null | undefined,
): BatchFailureData<FileNodeActorData | FileNodeMembershipData> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.memberId)
    ?.forEach(response => successes.add(response.memberId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getActorErrorConfig(
  models: Array<UserData | TeamData>,
  action: "block",
  message?: string | null,
) {
  const remain = models.length - 1;
  const first = models[0];
  return {
    message: `Failed to ${action} ${first.__typename}${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${models[0].id}`,
      persist: true,
      content: buildErrorNotification({
        icon: GroupIcon,
        error: message || `Something went wrong.`,
        name: first.name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}

export function getFileNodeMemberErrorConfig(
  models: Array<FileNodeActorData | FileNodeMembershipData>,
  action:
    | "update"
    | "remove",
  message?: string | null,
) {
  const remain = models.length - 1;
  const first = models[0];
  if ("actor" in first) {
    return {
      message: `Failed to ${action} ${first.actor.__typename}${remain > 0 ? 's' : ''}`,
      options: {
        key: `${action}:${models[0].id}`,
        persist: true,
        content: buildErrorNotification({
          icon: GroupIcon,
          error: message || `Something went wrong.`,
          name: first.actor.name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
        }),
      },
    };
  } else {
    return getFileNodeErrorConfig([first.fileNode], action);
  }
}
