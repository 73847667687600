import {gql} from '@apollo/client';
import {
  IFileNodeFragment,
  IFileNodeFragmentData,
} from '@app-features/file-tree/types';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';


export interface MoveFolderDocumentNode {
  moveFolder?: ClientMutationResponse & {
    folder?: IFileNodeFragmentData;
  }
}

export interface MoveFileContainerDocumentNode {
  moveFileContainer?: ClientMutationResponse & {
    fileContainer?: IFileNodeFragmentData;
  }
}

export interface MoveFileDocumentNode {
  moveFile?: ClientMutationResponse & {
    file?: IFileNodeFragmentData;
  }
}

export interface ConvertFileToSubmissionDocumentNode {
  convertFileToSubmission?: ClientMutationResponse & {
    fileSubmission?: IFileNodeFragmentData;
  }
}

export interface ConvertFileToSubmissionDocumentNode {
  convertFileToSubmission?: ClientMutationResponse & {
    fileSubmission?: IFileNodeFragmentData;
  }
}

export interface MoveFileSubmissionDocumentNode {
  moveFileSubmission?: ClientMutationResponse & {
    fileSubmission?: IFileNodeFragmentData;
  }
}

export interface ConvertSubmissionToFileDocumentNode {
  convertSubmissionToFile?: ClientMutationResponse & {
    file?: IFileNodeFragmentData;
  }
}

export const moveFolder = gql`
  mutation moveFolder(
    $input: MoveFolderInput!
    $dateFormat: DateFormatInput
  ) {
    moveFolder(input: $input) {
      clientMutationId
      code
      success
      message
      folder {
        ...IFileNodeFragment
      }
    }
  }
  ${IFileNodeFragment}
`;

export const moveFileContainer = gql`
  mutation moveFileContainer(
    $input: MoveFileContainerInput!
    $dateFormat: DateFormatInput
  ) {
    moveFileContainer(input: $input) {
      clientMutationId
      code
      success
      message
      fileContainer {
        ...IFileNodeFragment
      }
    }
  }
  ${IFileNodeFragment}
`;

export const moveFile = gql`
  mutation moveFile(
    $input: MoveFileInput!
    $dateFormat: DateFormatInput
  ) {
    moveFile(input: $input) {
      clientMutationId
      code
      success
      message
      file {
        ...IFileNodeFragment
      }
    }
  }
  ${IFileNodeFragment}
`;

export const convertFileToSubmission = gql`
  mutation convertFileToSubmission(
    $input: ConvertFileToSubmissionInput!
    $dateFormat: DateFormatInput
  ) {
    convertFileToSubmission(input: $input) {
      clientMutationId
      code
      success
      message
      fileSubmission {
        ...IFileNodeFragment
      }
    }
  }
  ${IFileNodeFragment}
`;

export const moveFileSubmission = gql`
  mutation moveFileSubmission(
    $input: MoveFileSubmissionInput!
    $dateFormat: DateFormatInput
  ) {
    moveFileSubmission(input: $input) {
      clientMutationId
      code
      success
      message
      fileSubmission {
        ...IFileNodeFragment
      }
    }
  }
  ${IFileNodeFragment}
`;

export const convertSubmissionToFile = gql`
  mutation convertSubmissionToFile(
    $input: ConvertSubmissionToFileInput!
    $dateFormat: DateFormatInput
  ) {
    convertSubmissionToFile(input: $input) {
      clientMutationId
      code
      success
      message
      file {
        ...IFileNodeFragment
      }
    }
  }
  ${IFileNodeFragment}
`;

export function moveFolderResult(
  response: { data?: MoveFolderDocumentNode | null },
) {
  return response?.data?.moveFolder;
}

export function moveFileContainerResult(
  response: { data?: MoveFileContainerDocumentNode | null },
) {
  return response?.data?.moveFileContainer;
}

export function moveFileResult(
  response: { data?: MoveFileDocumentNode | null },
) {
  return response?.data?.moveFile;
}

export function convertFileToSubmissionResult(
  response: { data?: ConvertFileToSubmissionDocumentNode | null },
) {
  return response?.data?.convertFileToSubmission;
}

export function moveFileSubmissionResult(
  response: { data?: MoveFileSubmissionDocumentNode | null },
) {
  return response?.data?.moveFileSubmission;
}

export function convertSubmissionToFileResult(
  response: { data?: ConvertSubmissionToFileDocumentNode | null },
) {
  return response?.data?.convertSubmissionToFile;
}
