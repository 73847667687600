import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';


export interface RemoveFileNodeMemberResponse {
  code: string
  success: boolean
  message: string
  memberId: string
}

export interface FileNodeRemoveMemberDocumentNode {
  fileNodeRemoveMember?: ClientMutationResponse
}

export interface FileNodeRemoveMemberBatchDocumentNode {
  fileNodeRemoveMemberBatch?: ClientMutationResponse & {
    responses?: Array<RemoveFileNodeMemberResponse>
  }
}

export const fileNodeRemoveMemberMutation = gql`
  mutation fileNodeRemoveMember($input: FileNodeRemoveMemberInput!) {
    fileNodeRemoveMember(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const fileNodeRemoveMemberBatchMutation = gql`
  mutation fileNodeRemoveMemberBatch($input: FileNodeRemoveMemberBatchInput!) {
    fileNodeRemoveMemberBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        memberId
      }
    }
  }
`;

export function getFileNodeRemoveMemberResult(
  response: { data?: FileNodeRemoveMemberDocumentNode | null },
): FileNodeRemoveMemberDocumentNode["fileNodeRemoveMember"] {
  return response?.data?.fileNodeRemoveMember;
}

export function getFileNodeRemoveMemberBatchResult(
  response: { data?: FileNodeRemoveMemberBatchDocumentNode | null },
): FileNodeRemoveMemberBatchDocumentNode["fileNodeRemoveMemberBatch"] {
  return response?.data?.fileNodeRemoveMemberBatch;
}
