import {MilestoneFragmentData} from '@app-features/milestones/types';
import {MilestoneStatus} from '@app-lib/apollo/apiTypes';
import {createAction} from 'redux-starter-kit';


export interface UpdateMilestoneStatusBatchPayload {
  models: Array<Pick<MilestoneFragmentData, "__typename" | "id" | "name">>
  status: MilestoneStatus
  skipApproved?: boolean
  updateFileLocks?: boolean
}

export type MilestoneStatusUpdateActions =
  | ReturnType<typeof updateMilestoneStatusBatch>

export const updateMilestoneStatusBatch = createAction<UpdateMilestoneStatusBatchPayload, "milestones/updateMilestoneStatusBatch">("milestones/updateMilestoneStatusBatch");
