/* eslint-disable max-len */
import {createAction} from 'redux-starter-kit';
import {
  JobFragmentData,
  JobTemplateFragmentData,
} from '../types';


export type JobFormActions =
  | ReturnType<typeof createdJob>
  | ReturnType<typeof importedJobData>
  | ReturnType<typeof createdJobTemplate>

export const createdJob = createAction<JobFragmentData, "jobs/createdJob">("jobs/createdJob");
export const importedJobData = createAction<JobFragmentData, "jobs/importedJobData">("jobs/importedJobData");
export const createdJobTemplate = createAction<JobTemplateFragmentData, "jobs/createdJobTemplate">("jobs/createdJobTemplate");
