import {TypePolicy} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';


export const ProjectPolicy: TypePolicy = {
  fields: {
    jobs: relayStylePagination([
      'searchText',
      'filter',
    ]),
  },
};
