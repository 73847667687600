import {OrganizationFragmentData} from '@app-features/organizations/types';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {
  closeNotification,
  enqueueNotification,
} from '@app-system/notifications/redux/actions';
import {noop} from 'lodash';
import {Observable} from 'rxjs';
import {
  filter,
  map,
  tap,
} from 'rxjs/operators';
import uuidv4 from 'uuid/v4';
import {
  loadedTenant,
  loadingTenant,
  setTenant,
  TenantActions,
} from './actions';


export const epics = [
  onSetTenant,
];

export function onSetTenant(
  action$: Observable<TenantActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === setTenant.type),
    tap(async (action: ReturnType<typeof setTenant>) => {
      const {
        apolloClient,
        tenantService,
        store,
      } = dependencies;
      const noteKey = uuidv4();
      const {tenant} = action.payload;
      try {
        if (tenantService.getTenantId() !== tenant.id) {
          store.dispatch(loadingTenant());
          store.dispatch(enqueueNotification({
            message: `Loading "${tenant.name}"...`,
            options: {
              key: noteKey,
              persist: true,
              variant: 'info',
            },
          }));

          tenantService.setTenantId(tenant.id);
          const rootPage = getRootPage(tenant);
          if (rootPage) {
            // Until we gracefully handle a user loading a page they do not have access to,
            //  just force to current root page.
            setTimeout(() => window.location.assign(rootPage), 1);
          } else {
            // Reload all queries on the page.
            await apolloClient.resetStore();

            store.dispatch(loadedTenant());
            store.dispatch(closeNotification({
              key: noteKey,
            }));
            store.dispatch(enqueueNotification({
              message: `Loaded "${tenant.name}"`,
              options: {
                key: `${noteKey}-success`,
                variant: 'success',
              },
            }));
          }
        }
      } catch (e) {
        console.error(e);
        store.dispatch(loadedTenant());
        store.dispatch(closeNotification({
          key: noteKey,
        }));
        store.dispatch(enqueueNotification({
          message: `Failed to load "${tenant.name}"`,
          options: {
            key: `${noteKey}-error`,
            variant: 'error',
          },
        }));
      }
    }),
    map(noop),
    filter(filterOnAction),
  );
}

/**
 * Return the root page of the current page if it is distinct from the current page.
 * Returns false if the current page is already the root page.
 */
function getRootPage(tenant: OrganizationFragmentData): string | false {
  const currentPage = window.location.pathname;
  if (currentPage) {
    const rootPage = currentPage.split('/').filter(it => !!it)[0];

    // special case: just navigate to the selected organization's root folder.
    if (rootPage === 'folder') {
      return `/folder/${tenant.folderId}`;
    }

    const target = rootPage && inWhitelist(rootPage) ? `/${rootPage}` : '/dashboard';
    return target === currentPage ? false : target;
  }
  return '/dashboard';
}

function inWhitelist(rootPage: string): boolean {
  switch (rootPage) {
    case 'dashboard':
    case 'project':
    case 'folder':
    case 'recycle-bin':
    case 'team':
    case 'templates':
      return true;
    default:
      return false;
  }
}
