import {MilestoneFragmentData} from '@app-features/milestones/types';
import {createAction} from 'redux-starter-kit';


export interface DeleteMilestonePayload {
  model: MilestoneFragmentData
}

export interface DeleteMilestoneBatchPayload {
  models: Array<MilestoneFragmentData>
}

export type DeleteMilestoneActions =
  | ReturnType<typeof deleteMilestone>
  | ReturnType<typeof deletedMilestone>
  | ReturnType<typeof deleteMilestoneBatch>
  | ReturnType<typeof deletedMilestoneBatch>

export const deleteMilestone = createAction<DeleteMilestonePayload, "milestones/deleteMilestone">("milestones/deleteMilestone");
export const deletedMilestone = createAction<DeleteMilestonePayload, "milestones/deletedMilestone">("milestones/deletedMilestone");
export const deleteMilestoneBatch = createAction<DeleteMilestoneBatchPayload, "milestones/deleteMilestoneBatch">("milestones/deleteMilestoneBatch");
export const deletedMilestoneBatch = createAction<DeleteMilestoneBatchPayload, "milestones/deletedMilestoneBatch">("milestones/deletedMilestoneBatch");
