import {
  FileAccessFormActions,
  updatedFileMembers,
} from '@app-features/file-access/forms/actions';
import {
  blockedActorFromFileNode,
  blockedActorsFromFileNodeBatch,
  BlockFileNodeMembershipActions,
} from '@app-features/file-access/redux/block/actions';
import {
  removedFileNodeMember,
  removedFileNodeMemberBatch,
  RemoveFileNodeMembersActions,
} from '@app-features/file-access/redux/removal/actions';
import {RecycleBinDownloadActions} from '@app-features/file-recycle-bin/redux/downloads/actions';
import {
  recoveredFileNode,
  recoveredFileNodeBatch,
  RecoverFileNodeActions,
} from '@app-features/file-recycle-bin/redux/recovery/actions';
import {
  createdFileContainer,
  createdFolder,
  FileTreeFormActions,
} from '@app-features/file-tree/forms/actions';
import {
  deletedFileNode,
  DeleteFileNodeActions,
} from '@app-features/file-tree/redux/deletion/actions';
import {FileNodeDownloadActions} from '@app-features/file-tree/redux/downloads/actions';
import {FileNodeLocksActions} from '@app-features/file-tree/redux/locks/actions';
import {
  FileNodeMovementActions,
  movedFileNodes,
} from '@app-features/file-tree/redux/movement/actions';
import {
  RecycleFileNodesActions,
  recycledFileNodeBatch,
} from '@app-features/file-tree/redux/recycle-bin/actions';
import {FileNodeStatusUpdateActions} from '@app-features/file-tree/redux/statuses/actions';
import {UploadManagerActions} from '@app-features/file-tree/redux/upload-manager/actions';
import {
  createdJob,
  createdJobTemplate,
  importedJobData,
  JobFormActions,
} from '@app-features/jobs/forms/actions';
import {JobArchivalActions} from '@app-features/jobs/redux/archival/actions';
import {
  deletedJob,
  deletedJobBatch,
  deletedJobTemplate,
  deletedJobTemplateBatch,
  DeleteJobActions,
} from '@app-features/jobs/redux/deletion/actions';
import {JobStatusUpdateActions} from '@app-features/jobs/redux/statuses/actions';
import {
  createdMilestone,
  MilestoneFormActions,
  updatedMilestone,
} from '@app-features/milestones/forms/actions';
import {
  deletedMilestone,
  deletedMilestoneBatch,
  DeleteMilestoneActions,
} from '@app-features/milestones/redux/deletion/actions';
import {MilestoneStatusUpdateActions} from '@app-features/milestones/redux/statuses/actions';
import {
  OrganizationFormActions,
  updatedOrganization,
} from '@app-features/organizations/forms/actions';
import {
  createdProject,
  createdProjectTemplate,
  ProjectFormActions,
} from '@app-features/projects/forms/actions';
import {ProjectArchivalActions} from '@app-features/projects/redux/archival/actions';
import {
  deletedProject,
  deletedProjectBatch,
  deletedProjectTemplate,
  deletedProjectTemplateBatch,
  DeleteProjectActions,
} from '@app-features/projects/redux/deletion/actions';
import {ProjectStatusUpdateActions} from '@app-features/projects/redux/statuses/actions';
import {
  createdTeam,
  TeamFormActions,
  updatedTeam,
} from '@app-features/teams/forms/actions';
import {
  deletedTeam,
  deletedTeamBatch,
  DeleteTeamActions,
} from '@app-features/teams/redux/deletion/actions';
import {
  removedUserFromTeam,
  removedUserFromTeams,
  removedUsersFromTeam,
  RemoveTeamMembersActions,
} from '@app-features/teams/redux/removal/actions';
import {
  createdTemplateCommit,
  createdTemplateRepository,
  TemplateFormActions,
} from '@app-features/template-repositories/forms/actions';
import {
  deletedTemplateCommit,
  deletedTemplateCommitBatch,
  deletedTemplateRepository,
  deletedTemplateRepositoryBatch,
  DeleteTemplateActions,
} from '@app-features/template-repositories/redux/deletion/actions';
import {
  UpdateTemplateActions,
  updateTemplateRepository,
} from '@app-features/template-repositories/redux/update/actions';
import {
  createdUserBatch,
  UserFormActions,
} from '@app-features/users/forms/actions';
import {
  removedUser,
  removedUserBatch,
  UserDeletionActions,
} from '@app-features/users/redux/deletion/actions';
import {AuthActions} from '@app-system/auth/redux/actions';
import {CameraActions} from '@app-system/camera/redux/actions';
import {NotificationsActions} from '@app-system/notifications/redux/actions';
import {PreferencesActions} from '@app-system/preferences/redux/actions';
import {
  hasAccessedFile,
  hasAccessedFolder,
  hasAccessedJob,
  hasAccessedProject,
  hasAccessedTenant,
  RecentAccessActions,
} from '@app-system/recent-access/redux/actions';
import {RouterActions} from '@app-system/router/redux/actions';
import {TenantActions} from '@app-system/tenant/redux/actions';
import {UploadQueueActions} from '@app-system/upload-manager/redux/actions';


export type AppActions =
  | FileAccessFormActions
  | BlockFileNodeMembershipActions
  | RemoveFileNodeMembersActions
  | RecycleBinDownloadActions
  | RecoverFileNodeActions
  | FileTreeFormActions
  | DeleteFileNodeActions
  | FileNodeDownloadActions
  | FileNodeLocksActions
  | FileNodeMovementActions
  | RecycleFileNodesActions
  | FileNodeStatusUpdateActions
  | UploadManagerActions
  | JobFormActions
  | JobArchivalActions
  | DeleteJobActions
  | JobStatusUpdateActions
  | MilestoneFormActions
  | DeleteMilestoneActions
  | MilestoneStatusUpdateActions
  | OrganizationFormActions
  | ProjectFormActions
  | ProjectArchivalActions
  | DeleteProjectActions
  | ProjectStatusUpdateActions
  | TeamFormActions
  | DeleteTeamActions
  | RemoveTeamMembersActions
  | TemplateFormActions
  | DeleteTemplateActions
  | UpdateTemplateActions
  | UserFormActions
  | UserDeletionActions
  | AuthActions
  | CameraActions
  | NotificationsActions
  | PreferencesActions
  | RecentAccessActions
  | RouterActions
  | TenantActions
  | UploadQueueActions

const whitelist = new Set<string>([
  updatedFileMembers.type,

  blockedActorFromFileNode.type,
  blockedActorsFromFileNodeBatch.type,

  removedFileNodeMember.type,
  removedFileNodeMemberBatch.type,

  recoveredFileNode.type,
  recoveredFileNodeBatch.type,

  createdFolder.type,
  createdFileContainer.type,

  deletedFileNode.type,

  movedFileNodes.type,

  recycledFileNodeBatch.type,

  createdJob.type,
  importedJobData.type,
  createdJobTemplate.type,

  deletedJob.type,
  deletedJobBatch.type,
  deletedJobTemplate.type,
  deletedJobTemplateBatch.type,

  createdMilestone.type,
  updatedMilestone.type,

  deletedMilestone.type,
  deletedMilestoneBatch.type,

  updatedOrganization.type,

  createdProject.type,
  createdProjectTemplate.type,

  deletedProject.type,
  deletedProjectBatch.type,
  deletedProjectTemplate.type,
  deletedProjectTemplateBatch.type,

  createdTeam.type,
  updatedTeam.type,

  deletedTeam.type,
  deletedTeamBatch.type,

  removedUserFromTeam.type,
  removedUsersFromTeam.type,
  removedUserFromTeams.type,

  createdTemplateCommit.type,
  createdTemplateRepository.type,

  deletedTemplateCommit.type,
  deletedTemplateCommitBatch.type,
  deletedTemplateRepository.type,
  deletedTemplateRepositoryBatch.type,

  updateTemplateRepository.type,

  createdUserBatch.type,

  removedUser.type,
  removedUserBatch.type,

  hasAccessedTenant.type,
  hasAccessedProject.type,
  hasAccessedJob.type,
  hasAccessedFolder.type,
  hasAccessedFile.type,
]);

export function isWhitelistedMessageAction(action: AppActions) {
  return whitelist.has(action.type);
}
