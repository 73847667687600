import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';


export interface RemoveMemberFromTeamResponse {
  code: string
  success: boolean
  message: string
  teamId: string
  userId: string
}

export interface RemoveMemberFromTeamDocumentNode {
  removeMemberFromTeam?: ClientMutationResponse
}

export interface RemoveMemberFromTeamBatchDocumentNode {
  removeMemberFromTeamBatch?: ClientMutationResponse & {
    responses?: Array<RemoveMemberFromTeamResponse>
  }
}

export const removeMemberFromTeamMutation = gql`
  mutation removeMemberFromTeam($input: RemoveMemberFromTeamInput!) {
    removeMemberFromTeam(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const removeMemberFromTeamBatchMutation = gql`
  mutation removeMemberFromTeamBatch($input: RemoveMemberFromTeamBatchInput!) {
    removeMemberFromTeamBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        teamId
        userId
      }
    }
  }
`;

export function getRemoveMemberFromTeamResult(
  response: { data?: RemoveMemberFromTeamDocumentNode | null },
): RemoveMemberFromTeamDocumentNode["removeMemberFromTeam"] {
  return get(response, 'data.removeMemberFromTeam', null);
}

export function getRemoveMemberFromTeamBatchResult(
  response: { data?: RemoveMemberFromTeamBatchDocumentNode | null },
): RemoveMemberFromTeamBatchDocumentNode["removeMemberFromTeamBatch"] {
  return get(response, 'data.removeMemberFromTeamBatch', null);
}
