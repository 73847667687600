import {ApolloClient} from '@apollo/client';
import {UpdateFileContainerStatusBatchInput} from '@app-lib/apollo/apiTypes';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getActionNameForFileNodeStatus,
  getFileNodeBatchFailures,
  getFileNodeErrorConfig,
} from '../utils';
import {
  FileNodeStatusUpdateActions,
  updateFileContainerStatusBatch,
  UpdateFileContainerStatusBatchPayload,
} from './actions';
import {
  getUpdateFileContainerStatusBatchResult,
  UpdateFileContainerStatusBatchDocumentNode,
  updateFileContainerStatusBatchMutation,
} from './graphql';


export const epics = [
  onUpdateFileContainerStatusBatch,
];

export function onUpdateFileContainerStatusBatch(action$: Observable<FileNodeStatusUpdateActions>, state$,
  dependencies) {
  return action$.pipe(
    filter(action => action.type === updateFileContainerStatusBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof updateFileContainerStatusBatch>) => {
      try {
        return await performUpdateFileContainerStatusBatch(dependencies.apolloClient, payload);
      } catch (e) {
        const action = getActionNameForFileNodeStatus(payload.status);
        return enqueueNotification(getFileNodeErrorConfig(payload.models, action, DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performUpdateFileContainerStatusBatch(
  apolloClient: ApolloClient<any>,
  payload: UpdateFileContainerStatusBatchPayload,
) {
  const result = await apolloClient
    .mutate<UpdateFileContainerStatusBatchDocumentNode, { input: UpdateFileContainerStatusBatchInput }>({
      mutation: updateFileContainerStatusBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
            status: payload.status,
            skipApproved: payload.skipApproved,
          })),
        },
      },
    });

  const {responses} = getUpdateFileContainerStatusBatchResult(result) || {};

  const failureData = getFileNodeBatchFailures(payload.models, responses);

  if (failureData) {
    const action = getActionNameForFileNodeStatus(payload.status);
    return enqueueNotification(getFileNodeErrorConfig(failureData.models, action, failureData.message));
  }

  return null;
}
