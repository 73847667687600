import {AppServices} from '@app-lib/services';
import LogRocket from 'logrocket';
import {
  Middleware,
  Store,
} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import {configureStore} from 'redux-starter-kit';
import {
  AppActions,
  isWhitelistedMessageAction,
} from './actions';
import {createBroadcastChannelMiddleware} from './BroadcastChannelMiddleware';
import {rootEpic} from './epics';
import {
  RootAppState,
  rootReducer,
} from './reducers';


export function initStore(initialState, options: { dependencies: AppServices }): Store<RootAppState, AppActions> {
  const epicMiddleware = createEpicMiddleware<AppActions>({
    dependencies: options.dependencies,
  });

  const middleware: Array<Middleware<{}, RootAppState>> = [
    epicMiddleware,
  ];

  if (typeof window !== 'undefined') {
    middleware.push(createBroadcastChannelMiddleware<RootAppState, AppActions>(
      options.dependencies.messengers.actionsChannel,
      isWhitelistedMessageAction,
    ));

    if (process.env.LOG_ROCKET_ID) {
      middleware.push(LogRocket.reduxMiddleware());
    }
  }

  const store = configureStore<RootAppState, AppActions>({
    reducer: rootReducer,
    middleware,
    devTools: process.env.STAGE !== 'production'
      && process.env.STAGE !== 'staging',
    preloadedState: initialState,
  });

  epicMiddleware.run(rootEpic);

  return store;
}
