import {TypePolicy} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';


export const TeamPolicy: TypePolicy = {
  fields: {
    avatars: {
      merge(existing: any[], incoming: any[]) {
        return incoming;
      },
    },
    fileRoles: relayStylePagination(['searchText']),
    members: relayStylePagination(['searchText']),
  },
};

export const TeamAvatarPolicy: TypePolicy = {
  keyFields: false,
};
