import {createAction} from 'redux-starter-kit';
import {
  IRecycledFileNodeFragmentData,
  RecycledFileFragmentData,
  RecycledFileSubmissionFragmentData,
} from '../../types';


export interface RecycledDownloadFilePayload {
  file:
    | Pick<RecycledFileFragmentData, "__typename" | "id" | "name">
    | Pick<RecycledFileSubmissionFragmentData, "__typename" | "id" | "name">
}

export interface DownloadRecycledFileListPayload {
  mutationId?: string;
  /** Remote files to download */
  files: Array<Pick<IRecycledFileNodeFragmentData, "__typename" | "id" | "name">>;
  relativeTo?: string | null;
  excludePath?: boolean | null;
}

export type RecycleBinDownloadActions =
  | ReturnType<typeof downloadRecycledFile>
  | ReturnType<typeof downloadRecycledFileList>

export const downloadRecycledFile = createAction<RecycledDownloadFilePayload, "recycle-bin/downloadRecycledFile">("recycle-bin/downloadRecycledFile");
export const downloadRecycledFileList = createAction<DownloadRecycledFileListPayload, "recycle-bin/downloadRecycledFileList">("recycle-bin/downloadRecycledFileList");
