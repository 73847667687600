import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {
  TemplateCommitBatchUpdateResponse,
  TemplateRepositoryBatchUpdateResponse,
} from '../utils';


export interface DeleteTemplateCommitDocumentNode {
  deleteTemplateCommit?: ClientMutationResponse
}

export interface DeleteTemplateCommitBatchDocumentNode {
  deleteTemplateCommitBatch?: ClientMutationResponse & {
    responses?: Array<TemplateCommitBatchUpdateResponse>
  }
}

export interface DeleteTemplateRepositoryDocumentNode {
  deleteTemplateRepository?: ClientMutationResponse
}

export interface DeleteTemplateRepositoryBatchDocumentNode {
  deleteTemplateRepositoryBatch?: ClientMutationResponse & {
    responses?: Array<TemplateRepositoryBatchUpdateResponse>
  }
}

export const deleteTemplateRepositoryMutation = gql`
  mutation deleteTemplateRepository($input: DeleteTemplateRepositoryInput!) {
    deleteTemplateRepository(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const deleteTemplateRepositoryBatchMutation = gql`
  mutation deleteTemplateRepositoryBatch($input: DeleteTemplateRepositoryBatchInput!) {
    deleteTemplateRepositoryBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        repositoryId
      }
    }
  }
`;


export const deleteTemplateCommitMutation = gql`
  mutation deleteTemplateCommit($input: DeleteTemplateCommitInput!) {
    deleteTemplateCommit(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const deleteTemplateCommitBatchMutation = gql`
  mutation deleteTemplateCommitBatch($input: DeleteTemplateCommitBatchInput!) {
    deleteTemplateCommitBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        commitId
      }
    }
  }
`;

export function getDeleteTemplateRepositoryResult(
  response: { data?: DeleteTemplateRepositoryDocumentNode | null },
): DeleteTemplateRepositoryDocumentNode["deleteTemplateRepository"] {
  return get(response, 'data.deleteTemplateRepository', null);
}

export function getDeleteTemplateRepositoryBatchResult(
  response: { data?: DeleteTemplateRepositoryBatchDocumentNode | null },
): DeleteTemplateRepositoryBatchDocumentNode["deleteTemplateRepositoryBatch"] {
  return get(response, 'data.deleteTemplateRepositoryBatch', null);
}

export function getDeleteTemplateCommitResult(
  response: { data?: DeleteTemplateCommitDocumentNode | null },
): DeleteTemplateCommitDocumentNode["deleteTemplateCommit"] {
  return get(response, 'data.deleteTemplateCommit', null);
}

export function getDeleteTemplateCommitBatchResult(
  response: { data?: DeleteTemplateCommitBatchDocumentNode | null },
): DeleteTemplateCommitBatchDocumentNode["deleteTemplateCommitBatch"] {
  return get(response, 'data.deleteTemplateCommitBatch', null);
}

