import {IFileNodeFragmentData} from '@app-features/file-tree/types';
import {
  TeamData,
  UserData,
} from '@app-lib/apollo/localTypes';
import {createAction} from 'redux-starter-kit';


export interface BlockActorFromFileNodePayload {
  fileNode: Pick<IFileNodeFragmentData, "__typename" | "id" | "name">;
  actor: UserData | TeamData;
}

export interface BlockActorsFromFileNodeBatchPayload {
  fileNode: Pick<IFileNodeFragmentData, "__typename" | "id" | "name">;
  actors: Array<UserData | TeamData>;
}

export type BlockFileNodeMembershipActions =
  | ReturnType<typeof blockActorFromFileNode>
  | ReturnType<typeof blockedActorFromFileNode>
  | ReturnType<typeof blockActorsFromFileNodeBatch>
  | ReturnType<typeof blockedActorsFromFileNodeBatch>

export const blockActorFromFileNode = createAction<BlockActorFromFileNodePayload, "files/blockActorFromFileNode">("files/blockActorFromFileNode");
export const blockedActorFromFileNode = createAction<BlockActorFromFileNodePayload, "files/blockedActorFromFileNode">("files/blockedActorFromFileNode");
export const blockActorsFromFileNodeBatch = createAction<BlockActorsFromFileNodeBatchPayload, "files/blockActorsFromFileNodeBatch">("files/blockActorsFromFileNodeBatch");
export const blockedActorsFromFileNodeBatch = createAction<BlockActorsFromFileNodeBatchPayload, "files/blockedActorsFromFileNodeBatch">("files/blockedActorsFromFileNodeBatch");
