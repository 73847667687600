import {ApolloClient} from '@apollo/client';
import {
  getUserBatchFailures,
  getUserErrorConfig,
} from '@app-features/users/redux/utils';
import {
  RemoveMemberFromTeamBatchInput,
  RemoveMemberFromTeamInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getTeamBatchFailures,
  getTeamErrorConfig,
} from '../utils';
import {
  removedUserFromTeam,
  removedUserFromTeams,
  removedUsersFromTeam,
  RemoveTeamMembersActions,
  removeUserFromTeam,
  RemoveUserFromTeamPayload,
  removeUserFromTeams,
  RemoveUserFromTeamsPayload,
  removeUsersFromTeam,
  RemoveUsersFromTeamPayload,
} from './actions';
import {
  getRemoveMemberFromTeamBatchResult,
  getRemoveMemberFromTeamResult,
  RemoveMemberFromTeamBatchDocumentNode,
  removeMemberFromTeamBatchMutation,
  RemoveMemberFromTeamDocumentNode,
  removeMemberFromTeamMutation,
} from './graphql';


export const epics = [
  onRemoveUserFromTeam,
  onRemoveUserFromTeams,
  onRemoveUsersFromTeam,
];

export function onRemoveUserFromTeam(
  action$: Observable<RemoveTeamMembersActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === removeUserFromTeam.type),
    mergeMap(async ({payload}: ReturnType<typeof removeUserFromTeam>) => {
      try {
        return await performRemoveUserFromTeam(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTeamErrorConfig([payload.team], 'remove from', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onRemoveUserFromTeams(
  action$: Observable<RemoveTeamMembersActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === removeUserFromTeams.type),
    mergeMap(async ({payload}: ReturnType<typeof removeUserFromTeams>) => {
      try {
        return await performRemoveUserFromTeams(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTeamErrorConfig(payload.teams, 'remove from', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onRemoveUsersFromTeam(
  action$: Observable<RemoveTeamMembersActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === removeUsersFromTeam.type),
    mergeMap(async ({payload}: ReturnType<typeof removeUsersFromTeam>) => {
      try {
        return await performRemoveUsersFromTeam(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getUserErrorConfig(payload.users, 'remove', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performRemoveUserFromTeam(
  apolloClient: ApolloClient<any>,
  payload: RemoveUserFromTeamPayload,
) {
  const result = await apolloClient
    .mutate<RemoveMemberFromTeamDocumentNode, { input: RemoveMemberFromTeamInput }>({
      mutation: removeMemberFromTeamMutation,
      variables: {
        input: {
          id: payload.team.id,
          userId: payload.user.id,
        },
      },
    });

  const {success, message} = getRemoveMemberFromTeamResult(result) || {};

  if (!success) {
    return enqueueNotification(getTeamErrorConfig([payload.team], 'remove from', message));
  } else {
    return removedUserFromTeam(payload);
  }
}

async function performRemoveUserFromTeams(
  apolloClient: ApolloClient<any>,
  payload: RemoveUserFromTeamsPayload,
) {
  const result = await apolloClient
    .mutate<RemoveMemberFromTeamBatchDocumentNode, { input: RemoveMemberFromTeamBatchInput }>({
      mutation: removeMemberFromTeamBatchMutation,
      variables: {
        input: {
          targets: payload.teams.map(it => ({
            teamId: it.id,
            userId: payload.user.id,
          })),
        },
      },
    });

  const {responses} = getRemoveMemberFromTeamBatchResult(result) || {};

  const failureData = getTeamBatchFailures(payload.teams, responses);

  if (failureData) {
    return enqueueNotification(getTeamErrorConfig(failureData.models, 'remove from', failureData.message));
  } else {
    return removedUserFromTeams(payload);
  }
}

async function performRemoveUsersFromTeam(
  apolloClient: ApolloClient<any>,
  payload: RemoveUsersFromTeamPayload,
) {
  const result = await apolloClient
    .mutate<RemoveMemberFromTeamBatchDocumentNode, { input: RemoveMemberFromTeamBatchInput }>({
      mutation: removeMemberFromTeamBatchMutation,
      variables: {
        input: {
          targets: payload.users.map(it => ({
            teamId: payload.team.id,
            userId: it.id,
          })),
        },
      },
    });

  const {responses} = getRemoveMemberFromTeamBatchResult(result) || {};

  const failureData = getUserBatchFailures(payload.users, responses);

  if (failureData) {
    return enqueueNotification(getUserErrorConfig(failureData.models, 'remove', failureData.message));
  } else {
    return removedUsersFromTeam(payload);
  }
}
