import {
  FileNodeActorData,
  FileNodeMembershipData,
} from '@app-lib/apollo/localTypes';
import {createAction} from 'redux-starter-kit';


export interface RemoveFileNodeMemberPayload {
  model: FileNodeActorData | FileNodeMembershipData
}

export interface RemoveFileNodeMemberBatchPayload {
  models: Array<FileNodeActorData | FileNodeMembershipData>
}

export type RemoveFileNodeMembersActions =
  | ReturnType<typeof removeFileNodeMember>
  | ReturnType<typeof removedFileNodeMember>
  | ReturnType<typeof removeFileNodeMemberBatch>
  | ReturnType<typeof removedFileNodeMemberBatch>

export const removeFileNodeMember = createAction<RemoveFileNodeMemberPayload, "files/removeFileNodeMember">("files/removeFileNodeMember");
export const removedFileNodeMember = createAction<RemoveFileNodeMemberPayload, "files/removedFileNodeMember">("files/removedFileNodeMember");
export const removeFileNodeMemberBatch = createAction<RemoveFileNodeMemberBatchPayload, "files/removeFileNodeMemberBatch">("files/removeFileNodeMemberBatch");
export const removedFileNodeMemberBatch = createAction<RemoveFileNodeMemberBatchPayload, "files/removedFileNodeMemberBatch">("files/removedFileNodeMemberBatch");
