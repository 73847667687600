import {ApolloClient} from '@apollo/client';
import {
  DeleteTeamBatchInput,
  DeleteTeamInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getTeamBatchFailures,
  getTeamErrorConfig,
} from '../utils';
import {
  deletedTeam,
  deletedTeamBatch,
  deleteTeam,
  DeleteTeamActions,
  deleteTeamBatch,
  DeleteTeamBatchPayload,
  DeleteTeamPayload,
} from './actions';
import {
  DeleteTeamBatchDocumentNode,
  deleteTeamBatchMutation,
  DeleteTeamDocumentNode,
  deleteTeamMutation,
  getDeleteTeamBatchResult,
  getDeleteTeamResult,
} from './graphql';


export const epics = [
  onDeleteTeam,
  onDeleteTeamBatch,
];

export function onDeleteTeam(
  action$: Observable<DeleteTeamActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteTeam.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteTeam>) => {
      try {
        return await performDeleteTeam(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTeamErrorConfig([payload.model], 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteTeamBatch(
  action$: Observable<DeleteTeamActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteTeamBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteTeamBatch>) => {
      try {
        return await performDeleteTeamBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTeamErrorConfig(payload.models, 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performDeleteTeam(
  apolloClient: ApolloClient<any>,
  payload: DeleteTeamPayload,
) {
  const result = await apolloClient
    .mutate<DeleteTeamDocumentNode, { input: DeleteTeamInput }>({
      mutation: deleteTeamMutation,
      variables: {
        input: {id: payload.model.id},
      },
    });

  const {success, message} = getDeleteTeamResult(result) || {};

  if (!success) {
    return enqueueNotification(getTeamErrorConfig([payload.model], 'delete', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return deletedTeam(payload);
  }
}

async function performDeleteTeamBatch(
  apolloClient: ApolloClient<any>,
  payload: DeleteTeamBatchPayload,
) {
  const result = await apolloClient
    .mutate<DeleteTeamBatchDocumentNode, { input: DeleteTeamBatchInput }>({
      mutation: deleteTeamBatchMutation,
      variables: {
        input: {targets: payload.models.map(it => ({id: it.id}))},
      },
    });

  const {responses} = getDeleteTeamBatchResult(result) || {};

  const failureData = getTeamBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getTeamErrorConfig(failureData.models, 'delete', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return deletedTeamBatch(payload);
  }
}
