import {
  TemplateCommitFragmentData,
  TemplateRepositoryFragmentData,
} from '@app-features/template-repositories/types';
import {createAction} from 'redux-starter-kit';


export interface DeleteTemplateCommitPayload {
  model: TemplateCommitFragmentData
}

export interface DeleteTemplateCommitBatchPayload {
  models: Array<TemplateCommitFragmentData>
}

export interface DeleteTemplateRepositoryPayload {
  model: TemplateRepositoryFragmentData
}

export interface DeleteTemplateRepositoryBatchPayload {
  models: Array<TemplateRepositoryFragmentData>
}

export type DeleteTemplateActions =
  | ReturnType<typeof deleteTemplateCommit>
  | ReturnType<typeof deletedTemplateCommit>
  | ReturnType<typeof deleteTemplateCommitBatch>
  | ReturnType<typeof deletedTemplateCommitBatch>
  | ReturnType<typeof deleteTemplateRepository>
  | ReturnType<typeof deletedTemplateRepository>
  | ReturnType<typeof deleteTemplateRepositoryBatch>
  | ReturnType<typeof deletedTemplateRepositoryBatch>

export const deleteTemplateCommit = createAction<DeleteTemplateCommitPayload, "templates/deleteTemplateCommit">("templates/deleteTemplateCommit");
export const deletedTemplateCommit = createAction<DeleteTemplateCommitPayload, "templates/deletedTemplateCommit">("templates/deletedTemplateCommit");
export const deleteTemplateCommitBatch = createAction<DeleteTemplateCommitBatchPayload, "templates/deleteTemplateCommitBatch">("templates/deleteTemplateCommitBatch");
export const deletedTemplateCommitBatch = createAction<DeleteTemplateCommitBatchPayload, "templates/deletedTemplateCommitBatch">("templates/deletedTemplateCommitBatch");
export const deleteTemplateRepository = createAction<DeleteTemplateRepositoryPayload, "templates/deleteTemplateRepository">("templates/deleteTemplateRepository");
export const deletedTemplateRepository = createAction<DeleteTemplateRepositoryPayload, "templates/deletedTemplateRepository">("templates/deletedTemplateRepository");
export const deleteTemplateRepositoryBatch = createAction<DeleteTemplateRepositoryBatchPayload, "templates/deleteTemplateRepositoryBatch">("templates/deleteTemplateRepositoryBatch");
export const deletedTemplateRepositoryBatch = createAction<DeleteTemplateRepositoryBatchPayload, "templates/deletedTemplateRepositoryBatch">("templates/deletedTemplateRepositoryBatch");
