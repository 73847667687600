import {AppActions} from '@app-lib/redux/actions';
import {AppServices} from '@app-lib/services';
import {BroadcastChannel} from 'broadcast-channel';
import {LocalBroadcastChannel} from './LocalBroadcastChannel';


export interface AppMessengers {
  /** Dispatch actions to all tabs, including the current one. */
  actionsChannel: LocalBroadcastChannel<AppActions>;
  /** Dispatch actions to other tabs. */
  reduxChannel: BroadcastChannel;
}

export function initMessengers(services: AppServices) {
  if (typeof window === 'undefined') {
    return null;
  }

  const actionsChannel = new LocalBroadcastChannel<AppActions>('AppActions');

  const reduxChannel = new BroadcastChannel('redux');
  reduxChannel.addEventListener('message', function (data) {
    if (data?.charAt(0) === '{') {
      try {
        const action = JSON.parse(data);
        if (action?.type) {
          services.store.dispatch(action);
        }
      } catch (e) {
      }
    }
  });

  return {
    actionsChannel,
    reduxChannel,
  };
}
