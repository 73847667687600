import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';


export interface DeleteTeamResponse {
  code: string
  success: boolean
  message: string
  teamId: string
}

export interface DeleteTeamDocumentNode {
  deleteTeam?: ClientMutationResponse
}

export interface DeleteTeamBatchDocumentNode {
  deleteTeamBatch?: ClientMutationResponse & {
    responses?: Array<DeleteTeamResponse>
  }
}

export const deleteTeamMutation = gql`
  mutation deleteTeam($input: DeleteTeamInput!) {
    deleteTeam(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const deleteTeamBatchMutation = gql`
  mutation deleteTeamBatch($input: DeleteTeamBatchInput!) {
    deleteTeamBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        teamId
      }
    }
  }
`;

export function getDeleteTeamResult(
  response: { data?: DeleteTeamDocumentNode | null },
): DeleteTeamDocumentNode["deleteTeam"] {
  return get(response, 'data.deleteTeam', null);
}

export function getDeleteTeamBatchResult(
  response: { data?: DeleteTeamBatchDocumentNode | null },
): DeleteTeamBatchDocumentNode["deleteTeamBatch"] {
  return get(response, 'data.deleteTeamBatch', null);
}
