import {BatchFailureData} from '@app-lib/apollo/localTypes';
import {getListId} from '@app-lib/collections/utils';
import {
  buildErrorNotification,
  ErrorNotificationProps,
} from '@app-system/notifications/components/ErrorNotification';
import {
  Collections as CollectionsIcon,
  Folder as FolderIcon,
  InsertDriveFile as InsertDriveFileIcon,
} from '@material-ui/icons';
import {find} from 'lodash';
import {IRecycledFileNodeFragmentData} from '../types';


export interface RecycledFileNodeBatchUpdateResponse {
  code: string;
  success: boolean;
  message: string;
  fileNodeId: string;
}

export function getRecycledFileNodeBatchFailures(
  models: Array<Pick<IRecycledFileNodeFragmentData, "__typename" | "id" | "name">>,
  responses: Array<RecycledFileNodeBatchUpdateResponse> | null | undefined,
): BatchFailureData<Pick<IRecycledFileNodeFragmentData, "__typename" | "id" | "name">> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.fileNodeId)
    ?.forEach(response => successes.add(response.fileNodeId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getRecycledFileNodeErrorConfig(
  models: Array<Pick<IRecycledFileNodeFragmentData, "__typename" | "id" | "name">>,
  action:
    | "download"
    | "recover"
    | "delete",
  message?: string | null,
) {
  const {type, icon} = getErrorConfig(models[0]);
  const remain = models.length - 1;
  return {
    message: `Failed to ${action} ${type}${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${getListId(models[0])}`,
      persist: true,
      content: buildErrorNotification({
        icon,
        error: message || `Something went wrong.`,
        name: models[0].name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}

function getErrorConfig(
  model: Pick<IRecycledFileNodeFragmentData, "__typename" | "id" | "name">,
): { type: string, icon: ErrorNotificationProps["icon"] } {
  let type;
  let icon;
  switch (model.__typename) {
    case 'RecycledFolder':
      type = 'Folder';
      icon = FolderIcon;
      break;
    case 'RecycledFileContainer':
      type = 'File Container';
      icon = CollectionsIcon;
      break;
    case 'RecycledFileSubmission':
    case 'RecycledFile':
      type = 'File';
      icon = InsertDriveFileIcon;
      break;
  }
  return {type, icon};
}
