import {TypePolicy} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';


const ISharableFileNodesPolicy: TypePolicy = {
  fields: {
    assignableActors: relayStylePagination(['searchText']),
    blocked: relayStylePagination(['searchText']),
    members: relayStylePagination(['searchText']),
  },
};

export const FolderPolicy: TypePolicy = {
  fields: {
    ...ISharableFileNodesPolicy.fields,
    children: relayStylePagination([
      'searchText',
      'filter',
      'depthMode',
      'onlyTypes',
    ]),
  },
};

export const FileContainerPolicy: TypePolicy = {
  fields: {
    ...ISharableFileNodesPolicy.fields,
  },
};

export const FilePolicy: TypePolicy = {
  fields: {
    ...ISharableFileNodesPolicy.fields,
  },
};

export const FilePreviewPolicy: TypePolicy = {
  keyFields: false,
};
