import {gql} from '@apollo/client';
import {ProjectFragmentData} from '@app-features/projects/types';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {ProjectBatchUpdateResponse} from '../utils';


export type ProjectArchivalUpdate = Pick<ProjectFragmentData,
  | "__typename"
  | "id"
  | "archived">

export interface ArchiveProjectDocumentNode {
  archiveProject?: ClientMutationResponse & {
    project?: ProjectArchivalUpdate
  }
}

export interface UnarchiveProjectDocumentNode {
  unarchiveProject?: ClientMutationResponse & {
    project?: ProjectArchivalUpdate
  }
}

export interface ArchiveProjectBatchDocumentNode {
  archiveProjectBatch?: ClientMutationResponse & {
    responses?: Array<ProjectBatchUpdateResponse>
    projects?: Array<ProjectArchivalUpdate>
  }
}

export interface UnarchiveProjectBatchDocumentNode {
  unarchiveProjectBatch?: ClientMutationResponse & {
    responses?: Array<ProjectBatchUpdateResponse>
    projects?: Array<ProjectArchivalUpdate>
  }
}

export const archiveProjectMutation = gql`
  mutation archiveProject($input: ArchiveProjectInput!) {
    archiveProject(input: $input) {
      clientMutationId
      code
      success
      message
      project {
        __typename
        id
        archived
      }
    }
  }
`;

export const unarchiveProjectMutation = gql`
  mutation unarchiveProject($input: UnarchiveProjectInput!) {
    unarchiveProject(input: $input) {
      clientMutationId
      code
      success
      message
      project {
        __typename
        id
        archived
      }
    }
  }
`;

export const archiveProjectBatchMutation = gql`
  mutation archiveProjectBatch($input: ArchiveProjectBatchInput!) {
    archiveProjectBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        projectId
      }
      projects {
        __typename
        id
        archived
      }
    }
  }
`;

export const unarchiveProjectBatchMutation = gql`
  mutation unarchiveProjectBatch($input: UnarchiveProjectBatchInput!) {
    unarchiveProjectBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        projectId
      }
      projects {
        __typename
        id
        archived
      }
    }
  }
`;

export function getArchiveProjectResult(
  response: { data?: ArchiveProjectDocumentNode | null },
): ArchiveProjectDocumentNode["archiveProject"] {
  return get(response, 'data.archiveProject', null);
}

export function getUnarchiveProjectResult(
  response: { data?: UnarchiveProjectDocumentNode | null },
): UnarchiveProjectDocumentNode["unarchiveProject"] {
  return get(response, 'data.unarchiveProject', null);
}

export function getArchiveProjectBatchResult(
  response: { data?: ArchiveProjectBatchDocumentNode | null },
): ArchiveProjectBatchDocumentNode["archiveProjectBatch"] {
  return get(response, 'data.archiveProjectBatch', null);
}

export function getUnarchiveProjectBatchResult(
  response: { data?: UnarchiveProjectBatchDocumentNode | null },
): UnarchiveProjectBatchDocumentNode["unarchiveProjectBatch"] {
  return get(response, 'data.unarchiveProjectBatch', null);
}
