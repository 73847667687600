/* eslint-disable max-len */
import {createAction} from 'redux-starter-kit';
import {
  TemplateCommitFragmentData,
  TemplateRepositoryFragmentData,
} from '../types';


export type TemplateFormActions =
  | ReturnType<typeof createdTemplateCommit>
  | ReturnType<typeof createdTemplateRepository>

export const createdTemplateCommit = createAction<TemplateCommitFragmentData, "templates/createdTemplateCommit">("templates/createdTemplateCommit");
export const createdTemplateRepository = createAction<TemplateRepositoryFragmentData, "templates/createdTemplateRepository">("templates/createdTemplateRepository");
