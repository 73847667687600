import {BatchFailureData} from '@app-lib/apollo/localTypes';
import {buildErrorNotification} from '@app-system/notifications/components/ErrorNotification';
import {find} from 'lodash';
import {
  ITemplateFragmentData,
  TemplateCommitFragmentData,
  TemplateRepositoryFragmentData,
} from '../types';


export interface TemplateRepositoryBatchUpdateResponse {
  code: string;
  success: boolean;
  message: string;
  repositoryId: string;
}

export interface TemplateCommitBatchUpdateResponse {
  code: string;
  success: boolean;
  message: string;
  commitId: string;
}

export interface TemplateBatchUpdateResponse {
  code: string;
  success: boolean;
  message: string;
  templateId: string;
}

export function getTemplateRepositoryBatchFailures(
  models: Array<Pick<TemplateRepositoryFragmentData, "__typename" | "id" | "displayName">>,
  responses: Array<TemplateRepositoryBatchUpdateResponse> | null | undefined,
): BatchFailureData<Pick<TemplateRepositoryFragmentData, "__typename" | "id" | "displayName">> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.repositoryId)
    ?.forEach(response => successes.add(response.repositoryId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getTemplateCommitBatchFailures(
  models: Array<Pick<TemplateCommitFragmentData, "__typename" | "id" | "checksum" | "version">>,
  responses: Array<TemplateCommitBatchUpdateResponse> | null | undefined,
): BatchFailureData<Pick<TemplateCommitFragmentData, "__typename" | "id" | "checksum" | "version">> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.commitId)
    ?.forEach(response => successes.add(response.commitId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getTemplateBatchFailures(
  models: Array<Pick<ITemplateFragmentData, "__typename" | "id" | "name">>,
  responses: Array<TemplateBatchUpdateResponse> | null | undefined,
): BatchFailureData<Pick<ITemplateFragmentData, "__typename" | "id" | "name">> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.templateId)
    ?.forEach(response => successes.add(response.templateId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getTemplateRepositoryErrorConfig(
  models: Array<Pick<TemplateRepositoryFragmentData, "__typename" | "id" | "displayName">>,
  action:
    | "update"
    | "delete",
  message?: string | null,
) {
  const remain = models.length - 1;
  return {
    message: `Failed to ${action} Repositor${remain > 0 ? 'ies' : 'y'}`,
    options: {
      key: `${action}:${models[0].id}`,
      persist: true,
      content: buildErrorNotification({
        error: message || `Something went wrong.`,
        name: models[0].displayName + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}

export function getTemplateCommitErrorConfig(
  models: Array<Pick<TemplateCommitFragmentData, "__typename" | "id" | "checksum" | "version">>,
  action:
    | "update"
    | "delete",
  message?: string | null,
) {
  const remain = models.length - 1;
  const name = models[0].version || models[0].checksum;
  return {
    message: `Failed to ${action} Commit${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${models[0].id}`,
      persist: true,
      content: buildErrorNotification({
        error: message || `Something went wrong.`,
        name: name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}

export function getTemplateErrorConfig(
  models: Array<Pick<ITemplateFragmentData, "__typename" | "id" | "name">>,
  action:
    | "update"
    | "delete",
  message?: string | null,
) {
  const remain = models.length - 1;
  const name = models[0].name;
  return {
    message: `Failed to ${action} Template${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${models[0].id}`,
      persist: true,
      content: buildErrorNotification({
        error: message || `Something went wrong.`,
        name: name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}
