import {
  ApolloLink,
  FetchResult,
  GraphQLRequest,
  NextLink,
  Observable,
  Operation,
} from '@apollo/client';


/**
 * Use to make changes to the request in flight.
 */
export type AroundLinkBefore = (request: GraphQLRequest, operation: Operation) => Promise<void>

/**
 * Use read the response.
 */
export type AroundLinkAfter = (response: FetchResult, operation: Operation) => void

export function createAroundLink(before: AroundLinkBefore, after: AroundLinkAfter) {
  return new ApolloLink((operation: Operation, forward: NextLink) => {
    const {...request} = operation;

    return new Observable(observer => {
      let handle;
      Promise.resolve(request)
        .then(req => before(req, operation))
        .then(() => {
          handle = forward(operation)
            .map(response => {
              after(response, operation);
              return response;
            })
            .subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    });
  });
}
