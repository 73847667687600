import {useApolloClient} from '@apollo/client';
import {useRouter} from 'next/router';
import {useSnackbar} from 'notistack';


export interface NotifierProps {
  services
}

export const SnackBarOptions = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
} as const;

export function Notifier({
  services,
}: NotifierProps) {
  const apolloClient = useApolloClient();
  const notifier = useSnackbar();
  const router = typeof window === 'undefined' ? null : useRouter();
  if (services) {
    // Apollo Client context seems to change on each page. Pass the updated version to the global services.
    services.apolloClient = apolloClient;
    services.notifier = notifier;
    services.router = router;
  }
  return null;
}
