import {gql} from '@apollo/client';
import {IRecycledFileFragmentData} from '../../types';


export interface RecycledFileByIdDocumentNode {
  recycledFileById?: {
    __typename: IRecycledFileFragmentData["__typename"];
    id: string;
    name: string;
    url: string;
  }
}

export const recycledFileByIdQuery = gql`
  query recycledFileById($id: ID!) {
    recycledFileById(id: $id) {
      __typename
      id
      name
      url
    }
  }
`;

export interface RecycledFolderContentsDocumentNode {
  recycledFolderContents?: {
    files?: Array<{
      name: string;
      path?: string;
      url: string;
    }>;
  }
}

export const recycledFolderContents = gql`
  query recycledFolderContents($input: GetContentsDownloadRequestInput!) {
    recycledFolderContents(input: $input) {
      files {
        name
        path
        url
      }
    }
  }
`;

export interface RecycledFilesBatchDocumentNode {
  recycledFilesBatch?: {
    files?: Array<{
      name: string;
      path?: string;
      url: string;
    }>;
  }
}

export const recycledFilesBatchQuery = gql`
  query recycledFilesBatch($input: GetBatchFileDownloadRequestInput!) {
    recycledFilesBatch(input: $input) {
      files {
        name
        path
        url
      }
    }
  }
`;
