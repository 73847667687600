import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  closeAllNotifications,
  closeNotification,
  enqueueNotification,
  NotificationsActions,
} from './actions';


export const notificationsEpics = [
  onCloseAllNotifications,
  onCloseNotification,
  onEnqueueNotification,
];

export function onCloseAllNotifications(
  action$: Observable<NotificationsActions>,
  state$,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === closeAllNotifications.type),
    mergeMap(async ({payload}: ReturnType<typeof closeAllNotifications>) => {
      try {
        if (dependencies.notifier) {
          dependencies.notifier.closeSnackbar();
        }
      } catch (e) {
      }
    }),
    filter(filterOnAction),
  );
}

export function onCloseNotification(
  action$: Observable<NotificationsActions>,
  state$,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === closeNotification.type),
    mergeMap(async ({payload}: ReturnType<typeof closeNotification>) => {
      try {
        if (dependencies.notifier) {
          dependencies.notifier.closeSnackbar(payload.key);
        }
      } catch (e) {
      }
    }),
    filter(filterOnAction),
  );
}

export function onEnqueueNotification(
  action$: Observable<NotificationsActions>,
  state$,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === enqueueNotification.type),
    mergeMap(async ({payload}: ReturnType<typeof enqueueNotification>) => {
      try {
        if (dependencies.notifier) {
          dependencies.notifier.enqueueSnackbar(payload.message, payload.options);
        }
      } catch (e) {
      }
    }),
    filter(filterOnAction),
  );
}
