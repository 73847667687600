import {MilestoneStatus} from '@app-lib/apollo/apiTypes';
import {BatchFailureData} from '@app-lib/apollo/localTypes';
import {buildErrorNotification} from '@app-system/notifications/components/ErrorNotification';
import {find} from 'lodash';
import {MilestoneFragmentData} from '../types';


export interface MilestoneBatchUpdateResponse {
  code: string
  success: boolean
  message: string
  milestoneId: string
}

export type ActionNamesForMilestoneStatus =
  | "cancel"
  | "reset to to do"
  | "submit"
  | "start review"
  | "approve"

export function getActionNameForMilestoneStatus(status: MilestoneStatus): ActionNamesForMilestoneStatus {
  switch (status) {
    case MilestoneStatus.Na:
      return 'cancel';
    case MilestoneStatus.Todo:
      return 'reset to to do';
    case MilestoneStatus.AwaitingReview:
      return 'submit';
    case MilestoneStatus.UnderReview:
      return 'start review';
    case MilestoneStatus.Approved:
      return 'approve';
  }
}

export function getMilestoneBatchFailures(
  models: Array<Pick<MilestoneFragmentData, "__typename" | "id" | "name">>,
  responses: Array<MilestoneBatchUpdateResponse> | null | undefined,
): BatchFailureData<Pick<MilestoneFragmentData, "__typename" | "id" | "name">> | null {
  const successes = new Set<string>();
  responses
    ?.filter(response => response.success && response.milestoneId)
    ?.forEach(response => successes.add(response.milestoneId));

  if (successes.size < models.length) {
    const failedModels = models.filter(it => !successes.has(it.id));
    const message = find(responses, response => !response.success)?.message;
    return {
      models: failedModels,
      message,
    };
  }

  return null;
}

export function getMilestoneErrorConfig(
  models: Array<Pick<MilestoneFragmentData, "__typename" | "id" | "name">>,
  action:
    | ActionNamesForMilestoneStatus
    | "update"
    | "delete",
  message?: string | null,
) {
  const remain = models.length - 1;
  return {
    message: `Failed to ${action} Milestone${remain > 0 ? 's' : ''}`,
    options: {
      key: `${action}:${models[0].id}`,
      persist: true,
      content: buildErrorNotification({
        error: message || `Something went wrong.`,
        name: models[0].name + (remain > 0 ? ` and ${remain} other${remain === 1 ? '' : 's'}` : ''),
      }),
    },
  };
}
