import {AppServices} from '@app-lib/services';
import {getPreferences} from '@app-system/preferences/redux/state';
import {
  dateFormatVar,
  dateTimeFormatVar,
} from '@app-system/time';
import {get} from 'lodash';
import {PreferencesService} from '../services/PreferencesService';


export function buildServerInitialState(ctx) {
  const cookies = get(ctx, 'req.headers.cookie');

  return PreferencesService.getStateFromCookies(cookies);
}

export function buildClientInitialState() {
  return PreferencesService.getStateFromCookiesClient();
}

export function postInitOnClient(services: AppServices) {
  const prefs = getPreferences(services.store.getState());
  if (prefs?.dateFormat) {
    dateFormatVar({
      formatString: prefs.dateFormat,
      timezone: prefs.timezone,
    });
    dateTimeFormatVar({
      formatString: prefs.dateTimeFormat,
      timezone: prefs.timezone,
    });
  }
}
