import {gql} from '@apollo/client';
import {TemplateBatchUpdateResponse} from '@app-features/template-repositories/redux/utils';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {JobBatchUpdateResponse} from '../utils';


export interface DeleteJobResponse {
  code: string
  success: boolean
  message: string
  jobId: string
}

export interface DeleteJobDocumentNode {
  deleteJob?: ClientMutationResponse
}

export interface DeleteJobBatchDocumentNode {
  deleteJobBatch?: ClientMutationResponse & {
    responses?: Array<JobBatchUpdateResponse>
  }
}

export interface DeleteJobTemplateDocumentNode {
  deleteJobTemplate?: ClientMutationResponse
}

export interface DeleteJobTemplateBatchDocumentNode {
  deleteJobTemplateBatch?: ClientMutationResponse & {
    responses?: Array<TemplateBatchUpdateResponse>
  }
}

export const deleteJobMutation = gql`
  mutation deleteJob($input: DeleteJobInput!) {
    deleteJob(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const deleteJobBatchMutation = gql`
  mutation deleteJobBatch($input: DeleteJobBatchInput!) {
    deleteJobBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        jobId
      }
    }
  }
`;

export const deleteJobTemplateMutation = gql`
  mutation deleteJobTemplate($input: DeleteJobTemplateInput!) {
    deleteJobTemplate(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const deleteJobTemplateBatchMutation = gql`
  mutation deleteJobTemplateBatch($input: DeleteJobTemplateBatchInput!) {
    deleteJobTemplateBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        templateId
      }
    }
  }
`;

export function getDeleteJobResult(
  response: { data?: DeleteJobDocumentNode | null },
): DeleteJobDocumentNode["deleteJob"] {
  return get(response, 'data.deleteJob', null);
}

export function getDeleteJobBatchResult(
  response: { data?: DeleteJobBatchDocumentNode | null },
): DeleteJobBatchDocumentNode["deleteJobBatch"] {
  return get(response, 'data.deleteJobBatch', null);
}

export function getDeleteJobTemplateResult(
  response: { data?: DeleteJobTemplateDocumentNode | null },
): DeleteJobTemplateDocumentNode["deleteJobTemplate"] {
  return get(response, 'data.deleteJobTemplate', null);
}

export function getDeleteJobTemplateBatchResult(
  response: { data?: DeleteJobTemplateBatchDocumentNode | null },
): DeleteJobTemplateBatchDocumentNode["deleteJobTemplateBatch"] {
  return get(response, 'data.deleteJobTemplateBatch', null);
}
