import {TypePolicy} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';


export const OrganizationPolicy: TypePolicy = {
  fields: {
    members: relayStylePagination(['searchText']),
  },
};

export const OrganizationMembershipPolicy: TypePolicy = {
  fields: {
    fileRoles: relayStylePagination(['searchText']),
    teams: relayStylePagination(['searchText']),
  },
};
