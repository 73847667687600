import {ApolloClient} from '@apollo/client';
import {FileNodeTypes} from '@app-features/file-tree/enums/FileNodeTypes';
import {getFileNodeErrorConfig} from '@app-features/file-tree/redux/utils';
import {DateFormatInput} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {getDateFormatOptions} from '@app-system/preferences/redux/state';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import uuidv4 from 'uuid/v4';
import {
  FileNodeMovementActions,
  movedFileNodes,
  moveFileNode,
  MoveFileNodePayload,
} from './actions';
import {
  convertFileToSubmission,
  convertFileToSubmissionResult,
  convertSubmissionToFile,
  convertSubmissionToFileResult,
  moveFile,
  moveFileContainer,
  moveFileContainerResult,
  moveFileResult,
  moveFileSubmission,
  moveFileSubmissionResult,
  moveFolder,
  moveFolderResult,
} from './graphql';


export const epics = [
  onMoveFileNode,
];

export function onMoveFileNode(
  action$: Observable<FileNodeMovementActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === moveFileNode.type),
    withLatestFrom(state$),
    mergeMap(async ([{payload}, rootState]: [ReturnType<typeof moveFileNode>, RootAppState]) => {
      try {
        const prefs = getDateFormatOptions(rootState);
        const dateFormat = {
          formatString: prefs.dateTimeFormat,
          timezone: prefs.timezone,
        };
        return await performMoveFileNode(dependencies.apolloClient, payload, dateFormat);
      } catch (e) {
        return enqueueNotification(getFileNodeErrorConfig([payload.model], 'move', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

function getMoveInput(payload) {
  if (payload.model.parentId === payload.target.id) {
    return false;
  }
  return {
    clientMutationId: payload.mutationId || uuidv4(),
    id: payload.model.id,
    // The current id at the time of the request.
    fromParentId: payload.model.parentId,
    toParentId: payload.target.id,
  };
}

function getConvertFileToSubmissionInput(payload) {
  let fileContainerId;
  switch (payload.target.__typename) {
    case FileNodeTypes.FileContainer:
      fileContainerId = payload.target.id;
      break;
    case FileNodeTypes.FileSubmission:
      fileContainerId = payload.target.fileContainerId;
      break;
    case FileNodeTypes.FilePlaceholder:
      fileContainerId = payload.target.fileContainer.id;
      break;
  }
  if (fileContainerId === (payload.model.fileContainerId || payload.model.fileContainer?.id)) {
    return false;
  }
  return {
    clientMutationId: payload.mutationId || uuidv4(),
    id: payload.model.id,
    fileContainerId: fileContainerId,
  };
}

async function performMoveFileNode(
  apolloClient: ApolloClient<any>,
  payload: MoveFileNodePayload,
  dateFormat: DateFormatInput,
) {
  const {model, target} = payload;
  const mutationConfig = getMutation(model.__typename, target.__typename);

  if (!mutationConfig) {
    // operation not supported.
    return null;
  }
  const {
    mutation,
    getInput,
    getResult,
  } = mutationConfig;
  const input = getInput(payload);
  if (!input) {
    // file is already in the desired location.
    return null;
  }

  const result = await apolloClient
    .mutate({
      mutation,
      variables: {
        input,
        dateFormat,
      },
    });

  const {message, success} = getResult(result) || {};

  if (!success) {
    return enqueueNotification(getFileNodeErrorConfig([payload.model], 'move', message));
  } else {
    switch (payload.model.__typename) {
      case 'Folder':
      case 'File':
      case 'FileContainer':
      case 'FileSubmission':
        return movedFileNodes({
          models: [payload.model.id],
        });
    }

  }

  return null;
}

function getMutation(nodeType, targetType) {
  switch (targetType) {
    case FileNodeTypes.FileContainer:
    case FileNodeTypes.FileSubmission:
    case FileNodeTypes.FilePlaceholder:
      switch (nodeType) {
        case FileNodeTypes.File:
          return {
            mutation: convertFileToSubmission,
            getInput: getConvertFileToSubmissionInput,
            getResult: convertFileToSubmissionResult,
          };
        case FileNodeTypes.FileSubmission:
          return {
            mutation: moveFileSubmission,
            getInput: getMoveInput,
            getResult: moveFileSubmissionResult,
          };
      }
      break;

    case FileNodeTypes.Folder:
      switch (nodeType) {
        case FileNodeTypes.Folder:
          return {
            mutation: moveFolder,
            getInput: getMoveInput,
            getResult: moveFolderResult,
          };
        case FileNodeTypes.File:
          return {
            mutation: moveFile,
            getInput: getMoveInput,
            getResult: moveFileResult,
          };
        case FileNodeTypes.FileContainer:
          return {
            mutation: moveFileContainer,
            getInput: getMoveInput,
            getResult: moveFileContainerResult,
          };
        case FileNodeTypes.FileSubmission:
          return {
            mutation: convertSubmissionToFile,
            getInput: getMoveInput,
            getResult: convertSubmissionToFileResult,
          };
      }
  }
  // unsupported operation.
}
