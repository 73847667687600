import {gql} from '@apollo/client';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {FileNodeBatchUpdateResponse} from '../utils';


export interface RecycleFileNodeBatchDocumentNode {
  recycleFileNodeBatch?: ClientMutationResponse & {
    responses?: Array<FileNodeBatchUpdateResponse>;
  }
}

export const recycleFileNodeBatchMutation = gql`
  mutation recycleFileNodeBatch($input: RecycleFileNodeBatchInput!) {
    recycleFileNodeBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        fileNodeId
      }
    }
  }
`;

export function getRecycleFileNodeBatchResult(
  response: { data?: RecycleFileNodeBatchDocumentNode | null },
): RecycleFileNodeBatchDocumentNode["recycleFileNodeBatch"] {
  return response?.data?.recycleFileNodeBatch;
}
