import {ApolloClient} from '@apollo/client';
import {FileNodeTypes} from '@app-features/file-tree/enums/FileNodeTypes';
import {UploadManagerStartUploadPayload} from '@app-features/file-tree/redux/upload-manager/actions';
import {
  FileUploadRequestField,
  GetFileSubmissionUploadRequestInput,
  GetFileUploadRequestInput,
  UpdateUploadProgressInput,
} from '@app-lib/apollo/apiTypes';
import {UploadState} from '@app-lib/apollo/localTypes';
import {LocalFile} from '@app-system/camera/types';
import {addLegacyRequestHandler} from './file-upload-utils';
import getBatchFileSubmissionUploadsRequest from './getBatchFileSubmissionUploadsRequest.graphql';
import getBatchFileUploadsRequest from './getBatchFileUploadsRequest.graphql';
import getFileSubmissionUploadRequest from './getFileSubmissionUploadRequest.graphql';
import getFileUploadRequest from './getFileUploadRequest.graphql';
import {
  ErrorIFileUploadRequest,
  getBatchFileSubmissionUploadsResult,
  getBatchFileUploadsResult,
  GetBatchIFileUploadsMutation,
  getFileSubmissionUploadRequestResult,
  getFileUploadRequestResult,
  getUpdateBatchUploadProgressResult,
  getUpdateUploadProgressResult,
  SuccessIFileUploadRequest,
} from './graphql';
import updateBatchUploadProgress from './updateBatchUploadProgress.graphql';
import updateUploadProgress from './updateUploadProgress.graphql';


export async function getUploadPermission(
  client: ApolloClient<any>,
  fileData: UploadManagerStartUploadPayload,
): Promise<ErrorIFileUploadRequest | SuccessIFileUploadRequest | null> {
  const {
    mutationId: clientMutationId,
    file,
    parentType,
    parentId,
  } = fileData;

  const isFileContainer = parentType === FileNodeTypes.FileContainer;
  const input = {
    clientMutationId,
    fileName: file.name || fileData.name,
  };

  if (fileData.parentType === 'FileContainer') {
    (input as GetFileSubmissionUploadRequestInput).fileContainerId = fileData.fileContainerId;
  } else {
    (input as GetFileUploadRequestInput).parentId = parentId;
  }

  try {
    const result = await client
      .mutate({
        mutation: isFileContainer ? getFileSubmissionUploadRequest : getFileUploadRequest,
        variables: {
          input,
          // TODO: retrieve from action, and from file-tree state
          previewOptions: {
            width: 150,
            height: 120,
          },
        },
      });

    const accessorFn = isFileContainer ? getFileSubmissionUploadRequestResult : getFileUploadRequestResult;

    return accessorFn(result);
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getBatchUploadsPermission(
  client: ApolloClient<any>,
  isFileContainer: boolean,
  requests: Array<GetFileSubmissionUploadRequestInput> | Array<GetFileUploadRequestInput>,
): Promise<GetBatchIFileUploadsMutation | null> {
  const input = {
    requests,
  };

  const result = await client
    .mutate({
      mutation: isFileContainer ? getBatchFileSubmissionUploadsRequest : getBatchFileUploadsRequest,
      variables: {
        input,
        // TODO: retrieve from action, and from file-tree state
        previewOptions: {
          width: 150,
          height: 120,
        },
      },
    });

  const accessorFn = isFileContainer ? getBatchFileSubmissionUploadsResult : getBatchFileUploadsResult;

  return accessorFn(result);
}

export async function performUpdateBatchUploadProgress(
  client: ApolloClient<any>,
  requests: Array<UpdateUploadProgressInput>
) {
  const input = {
    requests,
  };

  const result = await client
    .mutate({
      mutation: updateBatchUploadProgress,
      variables: {
        input,
      },
    });

  return getUpdateBatchUploadProgressResult(result);
}

export async function performUpdateUploadProgress(
  client: ApolloClient<any>,
  input: UpdateUploadProgressInput,
) {
  const result = await client
    .mutate({
      mutation: updateUploadProgress,
      variables: {
        input,
      },
    });

  return getUpdateUploadProgressResult(result);
}

export async function performUpload({
  file,
  url,
  fields = [],
  setProgress,
}: {
  file: File | LocalFile
  url: string
  fields: Array<FileUploadRequestField>
  setProgress: (e: { status: UploadState, percent: number | null }) => void
}) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    fields.forEach(field => {
      formData.append(field.key, field.value);
    });

    // Actual file has to be appended last.
    formData.append('file', file);

    const req = new XMLHttpRequest();

    if (setProgress) {
      setProgress({
        status: UploadState.Uploading,
        percent: null,
      });

      req.upload.addEventListener('progress', event => {
        if (event.lengthComputable) {
          setProgress({
            status: UploadState.Uploading,
            percent: (event.loaded / event.total) * 100,
          });
        } else {
          setProgress({
            status: UploadState.Uploading,
            percent: null,
          });
        }
      });
    }

    req.upload.addEventListener("load", event => {
      if (setProgress) {
        setProgress({
          status: UploadState.Success,
          percent: 100,
        });
      }
    });

    req.upload.addEventListener("error", event => {
      if (setProgress) {
        setProgress({
          status: UploadState.Error,
          percent: 100,
        });
      }
    });

    addLegacyRequestHandler(req, resolve, reject);
    req.addEventListener('error', reject);
    // Force CORs
    req.open('POST', url, true);
    req.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    req.send(formData);
  });
}
