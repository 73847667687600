import {gql} from '@apollo/client';
import {
  FileContainerFragmentData,
  FileSubmissionFragmentData,
} from '@app-features/file-tree/types';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';
import {get} from 'lodash';
import {FileNodeBatchUpdateResponse} from '../utils';


export type FileNodeStatusUpdate = Pick<FileContainerFragmentData | FileSubmissionFragmentData,
  | "__typename"
  | "id"
  | "status">

export interface UpdateFileContainerStatusBatchDocumentNode {
  updateFileContainerStatusBatch?: ClientMutationResponse & {
    responses?: Array<FileNodeBatchUpdateResponse>
    fileNodes?: Array<FileNodeStatusUpdate>
  }
}

export const updateFileContainerStatusBatchMutation = gql`
  mutation updateFileContainerStatusBatch($input: UpdateFileContainerStatusBatchInput!) {
    updateFileContainerStatusBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        fileNodeId
      }
      fileNodes {
        __typename
        id
        status
      }
    }
  }
`;

export function getUpdateFileContainerStatusBatchResult(
  response: { data?: UpdateFileContainerStatusBatchDocumentNode | null },
): UpdateFileContainerStatusBatchDocumentNode["updateFileContainerStatusBatch"] {
  return get(response, 'data.updateFileContainerStatusBatch', null);
}
