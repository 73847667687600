import {TypePolicy} from '@apollo/client';
import {relayStylePagination} from '@apollo/client/utilities';
import {dateFormatVar} from '@app-system/time';
import moment from 'moment';


export const JobPolicy: TypePolicy = {
  fields: {
    startDateFormatted: {
      read(_, {readField}) {
        return formatDate(readField('startDate'));
      },
    },
    endDateFormatted: {
      read(_, {readField}) {
        return formatDate(readField('endDate'));
      },
    },
    milestones: relayStylePagination([
      'searchText',
      'filter',
    ]),
  },
};

function formatDate(date: string | null | undefined) {
  if (date) {
    const formatString = dateFormatVar()?.formatString;
    return formatString ? moment(date).format(formatString) : date;
  } else {
    return null;
  }
}
