import {ApolloClient} from '@apollo/client';
import {getFileUploadErrorConfig} from '@app-features/file-tree/redux/utils';
import {
  getBatchUploadsPermission,
  getUploadPermission,
  performUpdateBatchUploadProgress,
  performUpdateUploadProgress,
  performUpload,
} from '@app-features/file-tree/services/file-uploads';
import {
  BatchFileSubmissionUploadRequestData,
  BatchFileUploadRequestData,
  buildFileUploadInput,
  SuccessIFileUploadRequest,
} from '@app-features/file-tree/services/graphql';
import {
  UpdateUploadProgressInput,
  UploadProgress,
} from '@app-lib/apollo/apiTypes';
import {
  FileUpload,
  UploadState,
} from '@app-lib/apollo/localTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {LocalFile} from '@app-system/camera/types';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {FileUploadQueueService} from '@app-system/upload-manager/services/FileUploadQueueService';
import {get} from 'lodash';
import {Observable} from 'rxjs';
import {
  bufferTime,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import uuidv4 from 'uuid/v4';
import {
  loadImageData,
  retryBatchUploads,
  retryUpload,
  startBatchUpload,
  startUpload,
  uploadError,
  UploadErrorPayload,
  UploadManagerActions,
  UploadManagerStartUploadPayload,
  uploadProgress,
  UploadProgressPayload,
  uploadValidationError,
} from './actions';
import {getUploadManager} from './state';
import {epics as swmqEpics} from './swmq/epic';
import {
  isUploadRetryable,
  loadThumbnail,
} from './utils';


export const epics = FileUploadQueueService.isSupported() ? swmqEpics : [
  readThumbnailEpic,
  readBatchThumbnailEpic,
  onStartUpload,
  onUploadProgress,
  onRetryUpload,
  onStartBatchUpload,
  onRetryBatchUploads,
  onUploadError,
  onUploadValidationError,
];

export function readThumbnailEpic(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === startUpload.type
      && action.payload.file.type.includes('image/')
    ),
    mergeMap(async ({payload}: ReturnType<typeof startUpload>) => {
      const dataUri = await loadThumbnail(payload);

      if (dataUri) {
        return loadImageData({
          mutationId: payload.mutationId,
          dataUri: dataUri,
        });
      }

      return null;
    }),
    filter(filterOnAction),
  );
}

export function readBatchThumbnailEpic(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === startBatchUpload.type),
    mergeMap(async ({payload}: ReturnType<typeof startBatchUpload>) => {
      const {
        files,
      } = payload;
      const {store} = dependencies;

      try {
        await Promise.all(files.map(async (fileData) => {
          const {mutationId, file} = fileData;
          if (!file.type.includes('image/')) {
            return;
          }
          const dataUri = await loadThumbnail(fileData);
          if (dataUri) {
            store.dispatch(loadImageData({
              mutationId,
              dataUri,
            }));
          }
        }));
      } catch (e) {
        console.error(e);
      }

      return null;
    }),
    filter(filterOnAction),
  );
}

export function onStartUpload(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === startUpload.type),
    mergeMap(async ({payload}: ReturnType<typeof startUpload>) => {
      const {apolloClient, store} = dependencies;
      try {
        await performSingleUpload(apolloClient, store, payload);
      } catch (e) {
        console.error(e);
      }
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onUploadProgress(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === uploadProgress.type),
    bufferTime(0.5 * 60 * 1000), // per minute
    filter(actions => actions.length > 0),
    // find the latest progress event for each id
    // build request input with the latest status for each
    // send a batch update.
    mergeMap(async (actions: Array<ReturnType<typeof uploadProgress>>) => {
      const {apolloClient, store} = dependencies;

      // get most recent action for each upload id
      const mutations: { [key: string]: UploadProgressPayload | null } = {};
      const errors: { [key: string]: boolean } = {};
      for (let i = 0, len = actions.length; i < len; i++) {
        const payload = actions[i].payload;
        if (errors[payload.id]) continue;

        switch (payload.status) {
          case UploadState.Error:
            errors[payload.id] = true;
          /* fall-through */
          case UploadState.Uploading:
            mutations[payload.id] = payload;
            break;

          case UploadState.Pending:
          case UploadState.Requesting:
          case UploadState.Success:
            mutations[payload.id] = null;
            break;
        }
      }

      // build request
      const requests: Array<UpdateUploadProgressInput> = [];
      for (const payload of Object.values(mutations)) {
        if (payload) {
          const id = payload.id;
          switch (payload.status) {
            case UploadState.Uploading:
              requests.push({
                id,
                status: UploadProgress.InProgress,
                progress: payload.percent,
              });
              break;

            case UploadState.Error:
              requests.push({
                id,
                status: UploadProgress.Failed,
              });
              break;
          }
        }
      }

      if (!requests.length) return null;

      try {
        await performUpdateBatchUploadProgress(apolloClient, requests);
      } catch (e) {
        console.error(e);
      }
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onRetryUpload(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === retryUpload.type),
    withLatestFrom(state$),
    mergeMap(async ([{payload}, rootState]: [ReturnType<typeof retryUpload>, RootAppState]) => {
      const {apolloClient, store} = dependencies;
      const state = getUploadManager(rootState);
      const {mutationId} = payload;
      const fileData = state.files[mutationId];
      if (!fileData.file) {
        return enqueueNotification(getFileUploadErrorConfig([fileData], 'upload', 'File is no longer available. Cache may be full.'));
      }
      try {
        return await performSingleUpload(apolloClient, store, fileData as UploadManagerStartUploadPayload);
      } catch (e) {
        console.error(e);
      }
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onStartBatchUpload(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === startBatchUpload.type),
    mergeMap(async ({payload}: ReturnType<typeof startBatchUpload>) => {
      const {apolloClient, store} = dependencies;
      const {files} = payload;
      try {
        await handleBatchUploads(apolloClient, store, files);
      } catch (e) {
        console.error(e);
      }
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onRetryBatchUploads(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === retryBatchUploads.type),
    withLatestFrom(state$),
    mergeMap(async ([{payload}, rootState]: [ReturnType<typeof retryBatchUploads>, RootAppState]) => {
      const {apolloClient, store} = dependencies;
      const state = getUploadManager(rootState);
      const {mutationIds} = payload;
      const fileList: Array<UploadManagerStartUploadPayload> = mutationIds
        .map(mutationId => state.files[mutationId] as UploadManagerStartUploadPayload)
        .filter((fileData) => !!fileData.file);
      try {
        await handleBatchUploads(apolloClient, store, fileList);
      } catch (e) {
        console.error(e);
      }
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onUploadError(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    // TODO: We may want to batch these.
    // Only report the error to the server if we already have an id.
    filter(action => action.type === uploadError.type && !!(action.payload as UploadErrorPayload).id),
    mergeMap(async ({payload}: ReturnType<typeof uploadError>) => {
      const {apolloClient} = dependencies;
      const {id, error} = payload;
      if (!id) {
        // TODO: we need to make sure we are capturing and have access to the id to perform cleanup proactively.
        return null;
      }
      // TODO: we may only need to do this if the error is not recoverable.
      try {
        await performUpdateUploadProgress(apolloClient, {
          clientMutationId: uuidv4(),
          id,
          status: UploadProgress.Failed,
        });
      } catch (e) {
        console.error(e);
      }
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onUploadValidationError(action$: Observable<UploadManagerActions>) {
  return action$.pipe(
    filter(action => action.type === uploadValidationError.type),
    map(({payload}: ReturnType<typeof uploadValidationError>) =>
      enqueueNotification(getFileUploadErrorConfig([payload], 'upload', payload.error))
    ),
    filter(filterOnAction),
  );
}

async function handleBatchUploads(
  apolloClient: ApolloClient<any>,
  store,
  fileList: Array<UploadManagerStartUploadPayload>,
) {
  const fileRequests: BatchFileUploadRequestData = {
    isFileContainer: false,
    requests: [],
    fileList: [],
  };
  const submissionRequests: BatchFileSubmissionUploadRequestData = {
    isFileContainer: true,
    requests: [],
    fileList: [],
  };
  const promises: Array<any> = [];

  fileList.forEach(data => {
    const inputData = buildFileUploadInput(data);

    if (inputData.isFileContainer) {
      submissionRequests.requests.push(inputData.input);
      submissionRequests.fileList.push(inputData.fileData);
    } else {
      fileRequests.requests.push(inputData.input);
      fileRequests.fileList.push(inputData.fileData);
    }
  });

  if (submissionRequests.requests.length > 0) {
    promises.push(performBatchUploads(apolloClient, store, submissionRequests));
  }

  if (fileRequests.requests.length > 0) {
    promises.push(performBatchUploads(apolloClient, store, fileRequests));
  }

  await Promise.all(promises);
}

async function performSingleUpload(
  apolloClient: ApolloClient<any>,
  store,
  fileData: UploadManagerStartUploadPayload,
) {
  const uploadRequest = await getUploadPermission(
    apolloClient,
    fileData,
  );
  const {
    mutationId,
    file,
  } = fileData;

  if (!uploadRequest?.success) {
    const message = uploadRequest ? uploadRequest.message : 'Upload request rejected by server.';
    store.dispatch(uploadError({
      mutationId,
      error: message,
      retryable: isUploadRetryable(uploadRequest),
    }));
    store.dispatch(enqueueNotification(getFileUploadErrorConfig([fileData], 'upload', message)));
    return;
  }

  await handleSingleUpload(fileData, mutationId, file, uploadRequest, store);
}

async function performBatchUploads(
  apolloClient: ApolloClient<any>,
  store,
  requestData:
    | BatchFileUploadRequestData
    | BatchFileSubmissionUploadRequestData,
) {
  const uploadRequests = await getBatchUploadsPermission(apolloClient, requestData.isFileContainer, requestData.requests);
  const uploadPermissions = uploadRequests?.requests;
  const files = requestData.fileList as Array<UploadManagerStartUploadPayload>;

  if (!uploadPermissions) {
    // TODO: handle failure. can potentially auto-retry with backoff
    await Promise.all(files.map(async (data, i) => {
      const message = uploadRequests?.message || 'Permission denied.';
      store.dispatch(uploadError({
        mutationId: data.mutationId,
        error: message,
        retryable: isUploadRetryable(data),
      }));
      store.dispatch(enqueueNotification(getFileUploadErrorConfig([data], 'upload', message)));
    })).catch(e => console.error(e));
    return;
  }

  await Promise.all(files.map(async (data, i) => {
    const {
      mutationId,
      file,
    } = data;
    const uploadRequest = uploadPermissions[i];

    if (!uploadRequest?.success) {
      const message = uploadRequest?.message || 'Something went wrong.';
      store.dispatch(uploadError({
        mutationId,
        error: message,
        retryable: isUploadRetryable(uploadRequest),
      }));
      store.dispatch(enqueueNotification(getFileUploadErrorConfig([data], 'upload', message)));
      return;
    }

    await handleSingleUpload(data, mutationId, file, uploadRequest, store);
  })).catch(e => console.error(e));
}

async function handleSingleUpload(
  fileData: UploadManagerStartUploadPayload | FileUpload,
  mutationId: string,
  file: File | LocalFile,
  uploadRequest: SuccessIFileUploadRequest,
  store,
) {
  const id = get(uploadRequest, 'fileId');
  const startedAt = Date.now();
  try {
    const uploadResult = await performUpload({
      file,
      url: uploadRequest.signedRequestUrl,
      fields: uploadRequest.fields,
      setProgress: ({status, percent}) => {
        store.dispatch(uploadProgress({
          mutationId,
          id,
          status,
          percent,
          startedAt,
        }));
      },
    });
  } catch (error) {
    console.error(error);
    const message = DefaultNetworkErrorMessage;
    store.dispatch(uploadError({
      mutationId,
      id,
      error: message,
      // TODO: may need to investigate if this could be not-retryable
      retryable: true,
    }));
    store.dispatch(enqueueNotification(getFileUploadErrorConfig([fileData], 'upload', message)));
  }
}
