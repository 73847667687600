import {TeamData} from '@app-lib/apollo/localTypes';
import {createAction} from 'redux-starter-kit';


export interface DeleteTeamPayload {
  model: TeamData
}

export interface DeleteTeamBatchPayload {
  models: Array<TeamData>
}

export type DeleteTeamActions =
  | ReturnType<typeof deleteTeam>
  | ReturnType<typeof deletedTeam>
  | ReturnType<typeof deleteTeamBatch>
  | ReturnType<typeof deletedTeamBatch>

export const deleteTeam = createAction<DeleteTeamPayload, "teams/deleteTeam">("teams/deleteTeam");
export const deletedTeam = createAction<DeleteTeamPayload, "teams/deletedTeam">("teams/deletedTeam");
export const deleteTeamBatch = createAction<DeleteTeamBatchPayload, "teams/deleteTeamBatch">("teams/deleteTeamBatch");
export const deletedTeamBatch = createAction<DeleteTeamBatchPayload, "teams/deletedTeamBatch">("teams/deletedTeamBatch");
