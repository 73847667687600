import {epics as deletionEpics} from './deletion/epic';
import {epics as downloadsEpics} from './downloads/epic';
import {epics as locksEpics} from './locks/epic';
import {epics as movementEpics} from './movement/epic';
import {epics as recycleBinEpics} from './recycle-bin/epic';
import {epics as statusesEpics} from './statuses/epic';
import {epics as uploadManagerEpics} from './upload-manager/epic';


export const fileTreeEpics = [
  ...deletionEpics,
  ...downloadsEpics,
  ...locksEpics,
  ...movementEpics,
  ...recycleBinEpics,
  ...statusesEpics,
  ...uploadManagerEpics,
];
