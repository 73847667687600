import {ApolloClient} from '@apollo/client';
import {
  ArchiveJobBatchInput,
  ArchiveJobInput,
  UnarchiveJobBatchInput,
  UnarchiveJobInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getJobBatchFailures,
  getJobErrorConfig,
} from '../utils';
import {
  archiveJob,
  archiveJobBatch,
  ArchiveJobBatchPayload,
  ArchiveJobPayload,
  JobArchivalActions,
  unarchiveJob,
  unarchiveJobBatch,
  UnarchiveJobBatchPayload,
  UnarchiveJobPayload,
} from './actions';
import {
  ArchiveJobBatchDocumentNode,
  archiveJobBatchMutation,
  ArchiveJobDocumentNode,
  archiveJobMutation,
  getArchiveJobBatchResult,
  getArchiveJobResult,
  getUnarchiveJobBatchResult,
  getUnarchiveJobResult,
  UnarchiveJobBatchDocumentNode,
  unarchiveJobBatchMutation,
  UnarchiveJobDocumentNode,
  unarchiveJobMutation,
} from './graphql';


export const epics = [
  onArchiveJob,
  onUnarchiveJob,
  onArchiveJobBatch,
  onUnarchiveJobBatch,
];

export function onArchiveJob(
  action$: Observable<JobArchivalActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === archiveJob.type),
    mergeMap(async ({payload}: ReturnType<typeof archiveJob>) => {
      try {
        return await performArchiveJob(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getJobErrorConfig([payload.model], 'archive', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onUnarchiveJob(
  action$: Observable<JobArchivalActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === unarchiveJob.type),
    mergeMap(async ({payload}: ReturnType<typeof unarchiveJob>) => {
      try {
        return await performUnarchiveJob(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getJobErrorConfig([payload.model], 'restore', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onArchiveJobBatch(
  action$: Observable<JobArchivalActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === archiveJobBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof archiveJobBatch>) => {
      try {
        return await performArchiveJobBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getJobErrorConfig(payload.models, 'archive', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onUnarchiveJobBatch(
  action$: Observable<JobArchivalActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === unarchiveJobBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof unarchiveJobBatch>) => {
      try {
        return await performUnarchiveJobBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getJobErrorConfig(payload.models, 'restore', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performArchiveJob(
  apolloClient: ApolloClient<any>,
  payload: ArchiveJobPayload,
) {
  const result = await apolloClient
    .mutate<ArchiveJobDocumentNode, { input: ArchiveJobInput }>({
      mutation: archiveJobMutation,
      variables: {
        input: {id: payload.model.id},
      },
    });

  const {success, message} = getArchiveJobResult(result) || {};

  if (!success) {
    return enqueueNotification(getJobErrorConfig([payload.model], 'archive', message));
  }

  return null;
}

async function performUnarchiveJob(
  apolloClient: ApolloClient<any>,
  payload: UnarchiveJobPayload,
) {
  const result = await apolloClient
    .mutate<UnarchiveJobDocumentNode, { input: UnarchiveJobInput }>({
      mutation: unarchiveJobMutation,
      variables: {
        input: {id: payload.model.id},
      },
    });

  const {success, message} = getUnarchiveJobResult(result) || {};

  if (!success) {
    return enqueueNotification(getJobErrorConfig([payload.model], 'restore', message));
  }

  return null;
}

async function performArchiveJobBatch(
  apolloClient: ApolloClient<any>,
  payload: ArchiveJobBatchPayload,
) {
  const result = await apolloClient
    .mutate<ArchiveJobBatchDocumentNode, { input: ArchiveJobBatchInput }>({
      mutation: archiveJobBatchMutation,
      variables: {
        input: {targets: payload.models.map(it => it.id)},
      },
    });

  const {responses} = getArchiveJobBatchResult(result) || {};

  const failureData = getJobBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getJobErrorConfig(failureData.models, 'archive', failureData.message));
  }

  return null;
}

async function performUnarchiveJobBatch(
  apolloClient: ApolloClient<any>,
  payload: UnarchiveJobBatchPayload,
) {
  const result = await apolloClient
    .mutate<UnarchiveJobBatchDocumentNode, { input: UnarchiveJobBatchInput }>({
      mutation: unarchiveJobBatchMutation,
      variables: {
        input: {targets: payload.models.map(it => it.id)},
      },
    });

  const {responses} = getUnarchiveJobBatchResult(result) || {};

  const failureData = getJobBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getJobErrorConfig(failureData.models, 'restore', failureData.message));
  }

  return null;
}
