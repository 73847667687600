import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  GraphQLRequest,
  HttpLink,
  Operation,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {AppServices} from '@app-lib/services';
import {pick} from 'lodash';
import {NextPageContext} from 'next';
import {createAroundLink} from './links/AroundLink';
import {buildApolloClientCache} from './utils';


export function createApolloClient(initialState, ctx: NextPageContext | null, services: AppServices) {
  const isServer = typeof window === 'undefined';
  return new ApolloClient({
    connectToDevTools: !isServer,
    ssrMode: isServer,
    link: buildApolloLinks(ctx, services),
    cache: buildApolloClientCache(initialState),
  });
}

function buildApolloLinks(ctx: NextPageContext | null, services: AppServices) {
  const links = [
    buildErrorLoggingLink(),
  ];

  if (typeof window === 'undefined') {
    links.push(buildAuthLink(ctx!));
  }

  links.push(buildTenantLink(services));
  links.push(buildHttpLink());

  return ApolloLink.from(links);
}

function buildAuthLink(ctx: NextPageContext) {
  const {getCachedToken} = require('../auth0');
  const {res, req} = ctx;
  const accessTokenPromise = getCachedToken(req, res);

  return setContext(async (req, {headers, ...context}) => {
    const accessToken = await accessTokenPromise;

    return {
      ...context,
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });
}

function buildTenantLink(services: AppServices) {
  const before = async (request: GraphQLRequest, operation: Operation) => {
    const context = operation.getContext();
    if (context?.skipTenant) {
      return;
    }
    const tenantId = services.tenantService.getTenantId();
    const nextHeaders = {};
    if (context.newTenant) {
      nextHeaders['X-New-Tenant'] = 'TRUE';
    } else if (tenantId) {
      nextHeaders['X-Tenant-ID'] = tenantId;
    } else {
      nextHeaders['X-Default-Tenant'] = 'TRUE';
    }

    operation.setContext(({headers = {}}, ...context) => ({
      ...context,
      headers: {
        ...headers,
        ...nextHeaders,
      },
    }));
  };

  const after = async (response: FetchResult, operation: Operation) => {
    const context = operation.getContext();
    const {response: {headers}} = context;

    if (headers) {
      const newTenantId = headers.get('X-Tenant-ID') || null;
      services.tenantService.setTenantId(newTenantId);
    }
  };

  return createAroundLink(before, after);
}

function buildErrorLoggingLink() {
  return onError(({graphQLErrors, networkError, operation}) => {
    if (graphQLErrors)
      graphQLErrors.map(({message, locations, path}) =>
        console.warn(
          // eslint-disable-next-line max-len
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}, Vars: ${JSON.stringify(operation.variables)}`,
        ),
      );
    if (networkError) console.warn(`[Network error]: ${networkError}`);
  });
}

function buildHttpLink() {
  if (typeof window === 'undefined') {
    return new HttpLink({
      uri: process.env.API_URL,
      fetch,
    });
  } else {
    return new HttpLink({
      // local api proxy url
      uri: `/api/graphql`,
      credentials: 'same-origin',
      fetch: window.fetch,
    });
  }
}
