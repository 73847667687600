import {getFileUploadErrorConfig} from '@app-features/file-tree/redux/utils';
import {FileUpload} from '@app-lib/apollo/localTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {
  FileUploadJobData,
  FileUploadJobResponse,
} from '@app-system/upload-manager/services/FileUploadQueueWorker';
import {
  JobRequest,
  JobState,
} from '@forks/swmq';
import produce from 'immer';
import {isNil} from 'lodash';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  clearComplete,
  clearFailed,
  hideComplete,
  removeFile,
  removeFiles,
  startBatchUpload,
  startUpload,
  UploadManagerActions,
  UploadManagerStartUploadPayload,
  uploadValidationError,
} from '../actions';
import {loadThumbnail} from '../utils';


export const epics = [
  onStartUpload,
  onStartBatchUpload,
  onUploadValidationError,
  onRemoveFile,
  onRemoveFiles,
  onHideComplete,
  onClearComplete,
  onClearFailed,
];

export function onStartUpload(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === startUpload.type),
    mergeMap(async (action: ReturnType<typeof startUpload>) => {
      await handleAddFileUploadJob(action, dependencies);
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onStartBatchUpload(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === startBatchUpload.type),
    mergeMap(async (action: ReturnType<typeof startBatchUpload>) => {
      await handleBatchAddFileUploadJob(action, dependencies);
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onUploadValidationError(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === uploadValidationError.type),
    mergeMap(async ({payload}: ReturnType<typeof uploadValidationError>) => {
      enqueueNotification(getFileUploadErrorConfig([payload], 'upload', payload.error));
      await handleDbError(dependencies.store, [payload], async () => {
        return dependencies.fileUploadQueueService.queue.addJobError({
          name: payload.name,
          data: {
            tenantId: '(none)',
            fileData: payload,
          },
        }, payload.error);
      });
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onRemoveFile(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === removeFile.type),
    mergeMap(async ({payload}: ReturnType<typeof removeFile>) => {
      const {jobId} = payload;
      if (jobId) {
        await handleDbError(dependencies.store, [], async () => {
          let cancelled = false;
          const job = await dependencies.fileUploadQueueService.queue.updateJob(jobId, (job) => {
            if (job) {
              switch (job?.status) {
                case JobState.Pending:
                case JobState.FailedPermanently:
                  cancelled = true;
                  return produce(job, (draft) => {
                    // reset attempts since the type of job has changed.
                    draft.attemptsMade = 0;
                    draft.data.cancel = true;
                    draft.data.fileData.hide = true;
                  });
              }
            }
            return null;
          });
          if (cancelled) {
            await dependencies.fileUploadQueueService.startSync();
            const fileId = job?.data?.fileId;
            if (fileId) {
              // HACK: we need to remove this from all open tabs, not jus the current one...
              // TODO: will need to remove from offline cache as well when applicable
              dependencies.apolloClient.cache.evict({id: fileId});
            }
          }
        });
      }
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onRemoveFiles(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === removeFiles.type),
    mergeMap(async ({payload}: ReturnType<typeof removeFiles>) => {
      const ids = payload.uploads
        .map(it => it.jobId)
        .filter((it): it is number => !isNil(it));
      await handleDbError(dependencies.store, [], async () => {
        return dependencies.fileUploadQueueService.queue.cancelJobs(ids);
      });
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onHideComplete(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === hideComplete.type),
    mergeMap(async ({payload}: ReturnType<typeof hideComplete>) => {
      const {jobId} = payload;
      if (jobId) {
        await handleDbError(dependencies.store, [], async () => {
          return dependencies.fileUploadQueueService.queue.updateJob(jobId, job => {
            if (job?.status === JobState.Complete && !job.data.fileData.hide) {
              return produce(job, (draft) => {
                draft.data.fileData.hide = true;
              });
            }
            return null;
          });
        });
      }
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onClearComplete(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === clearComplete.type),
    mergeMap(async ({payload}: ReturnType<typeof clearComplete>) => {
      await handleDbError(dependencies.store, [], async () =>
        dependencies.fileUploadQueueService.hideCompleted()
      );
      return null;
    }),
    filter(filterOnAction),
  );
}

export function onClearFailed(
  action$: Observable<UploadManagerActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === clearFailed.type),
    mergeMap(async ({payload}: ReturnType<typeof clearFailed>) => {
      await handleDbError(dependencies.store, [], async () =>
        dependencies.fileUploadQueueService.queue.clearFailedJobs()
      );
      return null;
    }),
    filter(filterOnAction),
  );
}

async function handleAddFileUploadJob(
  action: ReturnType<typeof startUpload | typeof uploadValidationError>,
  dependencies: AppServices,
) {
  const {
    fileUploadQueueService,
    store,
    tenantService,
  } = dependencies;
  const {payload} = action;
  const tenantId = tenantService.getTenantId();
  if (!tenantId) {
    const {mutationId} = action.payload;
    const message = 'Failed to lookup current tenant. Unable to upload files.';
    store.dispatch(enqueueNotification(getFileUploadErrorConfig([action.payload], 'upload', message)));
    await handleDbError(store, [action.payload], async () => {
      return fileUploadQueueService.queue.addJobError({
        name: payload.name,
        data: {
          tenantId: 'missing',
          fileData: payload,
        },
      }, message);
    });
    return null;
  }

  await handleDbError(store, [action.payload], async () => {
    let jobId;
    if ('error' in payload && payload.error) {
      jobId = await fileUploadQueueService.queue.addJobError({
        name: payload.name,
        data: {
          tenantId,
          fileData: payload,
        },
      }, payload.error);
    } else {
      jobId = await fileUploadQueueService.queue.addJob({
        name: payload.name,
        data: {
          tenantId,
          fileData: payload,
        },
      });
    }

    if (action.payload.file.type.includes('image/')) {
      const dataUri = await loadThumbnail(action.payload);
      fileUploadQueueService.queue.updateJob(jobId, (job) =>
        job ? produce(job, (draft) => {
          draft.data.fileData.dataUri = dataUri;
        }) : null
      );
    }
  });

  return null;
}

async function handleBatchAddFileUploadJob(
  action: ReturnType<typeof startBatchUpload>,
  dependencies: AppServices,
) {
  const {
    fileUploadQueueService,
    store,
    tenantService,
  } = dependencies;
  const tenantId = tenantService.getTenantId();
  if (!tenantId) {
    const message = 'Failed to lookup current tenant. Unable to upload files.';
    store.dispatch(enqueueNotification(getFileUploadErrorConfig(action.payload.files, 'upload', message)));
    await handleDbError(store, action.payload.files, async () => {
      return Promise.all(action.payload.files.map((fileData) => {
        const message = 'Failed to lookup current tenant. Unable to upload files.';
        return fileUploadQueueService.queue.addJobError({
          name: fileData.name,
          data: {
            tenantId: 'missing',
            fileData,
          },
        }, message);
      }));
    });
    return null;
  }

  await handleDbError(store, action.payload.files, async () => {
    const {payload} = action;
    const jobs: Array<JobRequest<FileUploadJobData, FileUploadJobResponse>> = [];
    const images: Array<number> = [];

    for (let index = 0, len = payload.files.length; index < len; index++) {
      const data = payload.files[index];
      jobs.push({
        name: data.name,
        data: {
          tenantId,
          fileData: data,
        },
      });

      if (data.file.type.includes('image/')) {
        images.push(index);
      }
    }

    const jobIds = await fileUploadQueueService.queue.addJobBatch(jobs);

    if (images.length) {
      images.map(async (index) => {
        const jobId = jobIds[index];
        const data = payload.files[index];
        const dataUri = await loadThumbnail(data);
        fileUploadQueueService.queue.updateJob(jobId, (job) =>
          job ? produce(job, (draft) => {
            draft.data.fileData.dataUri = dataUri;
          }) : null
        );
      });
    }

    return null;
  });
}

export async function handleDbError<R>(
  store: AppServices["store"],
  models: Array<FileUpload | UploadManagerStartUploadPayload>,
  cb: () => Promise<R>,
): Promise<R | undefined> {
  try {
    return await cb();
  } catch (e) {
    const message = 'There was an error connecting to the local database. Please ensure your browser permissions allow access to IndexedDB or Storage.';

    try {
      store.dispatch(enqueueNotification(getFileUploadErrorConfig(models, 'upload', message)));
    } catch (e) {
      console.error(e);
    }
  }
}
