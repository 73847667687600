import {gql} from '@apollo/client';
import {FileNodeBatchUpdateResponse} from '@app-features/file-tree/redux/utils';
import {ClientMutationResponse} from '@app-lib/apollo/localTypes';


export interface RecoverFileNodeDocumentNode {
  recoverFileNode?: ClientMutationResponse;
}

export interface RecoverFileNodeBatchDocumentNode {
  recoverFileNodeBatch?: ClientMutationResponse & {
    responses?: Array<FileNodeBatchUpdateResponse>;
  }
}

export const recoverFileNodeMutation = gql`
  mutation recoverFileNode($input: RecoverFileNodeInput!) {
    recoverFileNode(input: $input) {
      clientMutationId
      code
      success
      message
    }
  }
`;

export const recoverFileNodeBatchMutation = gql`
  mutation recoverFileNodeBatch($input: RecoverFileNodeBatchInput!) {
    recoverFileNodeBatch(input: $input) {
      clientMutationId
      code
      success
      message
      responses {
        code
        success
        message
        fileNodeId
      }
    }
  }
`;

export function getRecoverFileNodeResult(
  response: { data?: RecoverFileNodeDocumentNode | null },
): RecoverFileNodeDocumentNode["recoverFileNode"] {
  return response?.data?.recoverFileNode;
}

export function getRecoverFileNodeBatchResult(
  response: { data?: RecoverFileNodeBatchDocumentNode | null },
): RecoverFileNodeBatchDocumentNode["recoverFileNodeBatch"] {
  return response?.data?.recoverFileNodeBatch;
}
