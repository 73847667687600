import {gql} from '@apollo/client';
import {
  FilesBatch,
  IFile,
} from '@app-lib/apollo/apiTypes';


export interface FileByIdDocumentNode {
  fileById?: Pick<IFile, "id" | "name" | "url"> & {
    __typename: "File" | "FileSubmission";
  };
}

export const fileByIdQuery = gql`
  query fileById($id: ID!) {
    fileById(id: $id) {
      __typename
      id
      name
      url
    }
  }
`;

export interface GetFilesBatchDocumentNode {
  filesBatch?: FilesBatch;
}

export const getFilesBatchQuery = gql`
  query getFilesBatch($input: GetBatchFileDownloadRequestInput!) {
    filesBatch(input: $input) {
      files {
        name
        path
        url
      }
    }
  }
`;
