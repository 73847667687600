import {ApolloClient} from '@apollo/client';
import {
  getTemplateBatchFailures,
  getTemplateErrorConfig,
} from '@app-features/template-repositories/redux/utils';
import {
  DeleteJobBatchInput,
  DeleteJobInput,
  DeleteJobTemplateBatchInput,
  DeleteJobTemplateInput,
} from '@app-lib/apollo/apiTypes';
import {RootAppState} from '@app-lib/redux/reducers';
import {filterOnAction} from '@app-lib/rxjs/utils';
import {AppServices} from '@app-lib/services';
import {DefaultNetworkErrorMessage} from '@app-system/notifications/messages';
import {enqueueNotification} from '@app-system/notifications/redux/actions';
import {Observable} from 'rxjs';
import {
  filter,
  mergeMap,
} from 'rxjs/operators';
import {
  getJobBatchFailures,
  getJobErrorConfig,
} from '../utils';
import {
  deletedJob,
  deletedJobBatch,
  deletedJobTemplate,
  deletedJobTemplateBatch,
  deleteJob,
  DeleteJobActions,
  deleteJobBatch,
  DeleteJobBatchPayload,
  DeleteJobPayload,
  deleteJobTemplate,
  deleteJobTemplateBatch,
  DeleteJobTemplateBatchPayload,
  DeleteJobTemplatePayload,
} from './actions';
import {
  DeleteJobBatchDocumentNode,
  deleteJobBatchMutation,
  DeleteJobDocumentNode,
  deleteJobMutation,
  DeleteJobTemplateBatchDocumentNode,
  deleteJobTemplateBatchMutation,
  DeleteJobTemplateDocumentNode,
  deleteJobTemplateMutation,
  getDeleteJobBatchResult,
  getDeleteJobResult,
  getDeleteJobTemplateBatchResult,
  getDeleteJobTemplateResult,
} from './graphql';


export const epics = [
  onDeleteJob,
  onDeleteJobBatch,
  onDeleteJobTemplate,
  onDeleteJobTemplateBatch,
];

export function onDeleteJob(
  action$: Observable<DeleteJobActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteJob.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteJob>) => {
      try {
        return await performDeleteJob(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getJobErrorConfig([payload.model], 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteJobBatch(
  action$: Observable<DeleteJobActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteJobBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteJobBatch>) => {
      try {
        return await performDeleteJobBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getJobErrorConfig(payload.models, 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteJobTemplate(
  action$: Observable<DeleteJobActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteJobTemplate.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteJobTemplate>) => {
      try {
        return await performDeleteJobTemplate(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTemplateErrorConfig([payload.model], 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

export function onDeleteJobTemplateBatch(
  action$: Observable<DeleteJobActions>,
  state$: Observable<RootAppState>,
  dependencies: AppServices,
) {
  return action$.pipe(
    filter(action => action.type === deleteJobTemplateBatch.type),
    mergeMap(async ({payload}: ReturnType<typeof deleteJobTemplateBatch>) => {
      try {
        return await performDeleteJobTemplateBatch(dependencies.apolloClient, payload);
      } catch (e) {
        return enqueueNotification(getTemplateErrorConfig(payload.models, 'delete', DefaultNetworkErrorMessage));
      }
    }),
    filter(filterOnAction),
  );
}

async function performDeleteJob(
  apolloClient: ApolloClient<any>,
  payload: DeleteJobPayload,
) {
  const result = await apolloClient
    .mutate<DeleteJobDocumentNode, { input: DeleteJobInput }>({
      mutation: deleteJobMutation,
      variables: {
        input: {
          id: payload.model.id,
          recycleFolder: payload.recycleFolder,
        },
      },
    });

  const {success, message} = getDeleteJobResult(result) || {};

  if (!success) {
    return enqueueNotification(getJobErrorConfig([payload.model], 'delete', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return deletedJob(payload);
  }
}

async function performDeleteJobBatch(
  apolloClient: ApolloClient<any>,
  payload: DeleteJobBatchPayload,
) {
  const result = await apolloClient
    .mutate<DeleteJobBatchDocumentNode, { input: DeleteJobBatchInput }>({
      mutation: deleteJobBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
            recycleFolder: payload.recycleFolders,
          })),
        },
      },
    });

  const {responses} = getDeleteJobBatchResult(result) || {};

  const failureData = getJobBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getJobErrorConfig(failureData.models, 'delete', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return deletedJobBatch(payload);
  }
}

async function performDeleteJobTemplate(
  apolloClient: ApolloClient<any>,
  payload: DeleteJobTemplatePayload,
) {
  const result = await apolloClient
    .mutate<DeleteJobTemplateDocumentNode, { input: DeleteJobTemplateInput }>({
      mutation: deleteJobTemplateMutation,
      variables: {
        input: {
          id: payload.model.id,
        },
      },
    });

  const {success, message} = getDeleteJobTemplateResult(result) || {};

  if (!success) {
    return enqueueNotification(getTemplateErrorConfig([payload.model], 'delete', message));
  } else {
    apolloClient.cache.evict({id: payload.model.id});
    return deletedJobTemplate(payload);
  }
}

async function performDeleteJobTemplateBatch(
  apolloClient: ApolloClient<any>,
  payload: DeleteJobTemplateBatchPayload,
) {
  const result = await apolloClient
    .mutate<DeleteJobTemplateBatchDocumentNode, { input: DeleteJobTemplateBatchInput }>({
      mutation: deleteJobTemplateBatchMutation,
      variables: {
        input: {
          targets: payload.models.map(it => ({
            id: it.id,
          })),
        },
      },
    });

  const {responses} = getDeleteJobTemplateBatchResult(result) || {};

  const failureData = getTemplateBatchFailures(payload.models, responses);

  if (failureData) {
    return enqueueNotification(getTemplateErrorConfig(failureData.models, 'delete', failureData.message));
  } else {
    payload.models.forEach(({id}) => apolloClient.cache.evict({id}));
    return deletedJobTemplateBatch(payload);
  }
}
