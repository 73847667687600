export enum FileNodeTypes {
  Folder = 'Folder',
  File = 'File',
  FileContainer = 'FileContainer',
  FileSubmission = 'FileSubmission',

  // client only
  FilePlaceholder = 'FilePlaceholder',
  Upload = 'FileUpload',
}
