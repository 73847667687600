import {OrgMemberData} from '@app-lib/apollo/localTypes';
import {createAction} from 'redux-starter-kit';


export interface RemoveUserPayload {
  model: OrgMemberData
}

export interface RemoveUserBatchPayload {
  models: Array<OrgMemberData>
}

export type UserDeletionActions =
  | ReturnType<typeof removeUser>
  | ReturnType<typeof removedUser>
  | ReturnType<typeof removeUserBatch>
  | ReturnType<typeof removedUserBatch>

export const removeUser = createAction<RemoveUserPayload, "users/removeUser">("users/removeUser");
export const removedUser = createAction<RemoveUserPayload, "users/removedUser">("users/removedUser");
export const removeUserBatch = createAction<RemoveUserBatchPayload, "users/removeUserBatch">("users/removeUserBatch");
export const removedUserBatch = createAction<RemoveUserBatchPayload, "users/removedUserBatch">("users/removedUserBatch");
