import {createReducer} from 'redux-starter-kit';
import {LocalFile} from '../types';
import {
  closeCamera,
  openCamera,
} from './actions';


export interface CameraState {
  show: boolean;
  onAcceptPhoto: ((file?: File | LocalFile | null) => void) | null;
}

export const initialState: CameraState = {
  show: false,
  onAcceptPhoto: null,
};

export const reducer = createReducer(initialState, {
  [closeCamera.type]: handleCloseCamera,
  [openCamera.type]: handleOpenCamera,
});

// TODO: get camera settings to determine if accept prompt should be used.
//  Use this at the location dispatching the action?
function handleOpenCamera(draft, {payload}: ReturnType<typeof openCamera>) {
  draft.show = true;
  draft.onAcceptPhoto = payload.onAcceptPhoto;

  return draft;
}

function handleCloseCamera() {
  return initialState;
}
